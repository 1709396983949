import { apiRequest } from "./config";

const USER_ENDPOINT = '/users/'

export const get = () => {
  return new Promise((resolve, reject) => {
    apiRequest("get", USER_ENDPOINT)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const create = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", USER_ENDPOINT, { data: values }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const update = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest("put", USER_ENDPOINT, { data: values }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}


//for reference:
// const employerObject = {
// 	id : "str",
// 	name : "str",
// 	description : "str",
// 	contact : "str",
// 	email : "email@domain.com",
// 	fein : "str",
// 	soft_skills : "str",
// 	zip_code : "str",
// 	primary_industry_code : "str",
// 	secondary_industry_code : "str",
// 	verified : boolean,
// 	verification_date : 2022-01-01,
// 	last_updated : 2022-01-01
// }