import { useState } from 'react';
import { Formik } from 'formik';
import useFormValidation from 'lib/useValidation';
import * as yup from 'yup';

/* Material UI and other UI Dependencies */
import { Typography, Box, Button, Modal, Fade, CircularProgress, Backdrop } from '@material-ui/core';

/* Styles */
import styles from 'styles/login.module.scss';
import modalStyles from 'styles/modal.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cssVars from 'styles/vars.module.scss';
import { css } from '@emotion/css';

/* API */
import { postProfilePublic, optOutContact } from 'lib/API';

/* i18n */
import { useIntl } from 'react-intl';

/* Components */
import { InputTextField, PhoneNumberInput } from 'components/inputs';
import { CandidateJobChecklist, CandidateEducation, CandidateExperience } from '.';
import { useSnackbar } from 'notistack';

const localStyles = {
  optOut__container: css({
    backgroundColor: cssVars.lightGray,
    marginTop: 20,
    padding: '10px 16px',
    borderRadius: 4,
    position: 'relative',
    top: 3,
  }),
};

/* Form Helpers */
export const candidateConsentFormValues = {
  id: undefined,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  jobsSelect: [],
  experience: [{ title: '', start_date: '', end_date: '' }],
  degree: '',
  field: '',
  school_name: '',
};

const CandidateConsentForm = ({ candidateData, jobList }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [optOutSuccess, setOptOutSuccess] = useState(false);
  const intl = useIntl();
  const { firstNameValidation, lastNameValidation, phoneNumberValidation, emailValidation } = useFormValidation();

  const jobsSelectValidation = yup
    .array()
    .of(yup.string())
    .required(intl.formatMessage({ id: 'consentForm.minJobsValidation' }))
    .min(1, intl.formatMessage({ id: 'consentForm.minJobsValidation' }));

  const experienceValidation = yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(intl.formatMessage({ id: 'forms.onboarding.required' })),
        start_date: yup.string().required(intl.formatMessage({ id: 'forms.onboarding.required' })),
        end_date: yup.string().required(intl.formatMessage({ id: 'forms.onboarding.required' })),
      })
    )
    .min(1);

  const formSubmit = ({ jobsSelect, experience, ...values }) => {
    setSubmitting(true);
    const newExperience = experience.map(({ title, start_date, end_date }) => ({
      title,
      start_date,
      ...(end_date !== 'currentJob' ? { end_date } : null),
    }));

    if ('id' in values) {
      return postProfilePublic(values.id, {
        ...values,
        experience: newExperience,
        selected_jobs: jobsSelect,
      })
        .then(() => {
          setSubmitting(false);
          if (jobsSelect.length > 0) {
            enqueueSnackbar(intl.formatMessage({ id: 'consentForm.saveSuccess' }), { variant: 'success' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(intl.formatMessage({ id: 'consentForm.saveError' }), {
            variant: 'error',
          });
          setSubmitting(false);
        });
    }
    return Promise.resolve();
  };

  const handleOptOut = async () => {
    setModalOpen(true);
    await optOutContact(candidateData.id);
    setOptOutSuccess(true);
  };

  return (
    <Formik
      initialValues={{
        ...candidateConsentFormValues,
        ...candidateData,
        jobsSelect: [],
      }}
      onSubmit={formSubmit}
      validationSchema={yup.object({
        first_name: firstNameValidation,
        last_name: lastNameValidation,
        phone_number: phoneNumberValidation,
        email: emailValidation,
        jobsSelect: jobsSelectValidation,
        experience: experienceValidation,
        degree: yup.string('forms.onboarding.educationValidation').required('forms.onboarding.educationRequired'),
      })}
    >
      {({ errors, handleSubmit }) => {
        const hasJobsSelectError = Object.keys(errors).includes('jobsSelect');

        return (
          <>
            <div className={styles.loginFlow}>
              <div className={styles.loginFlow__formContainer}>
                <div className={styles.loginFlow__form__onboarding}>
                  <Typography variant="h1" align="center" gutterBottom>
                    {intl.formatMessage({ id: 'consentForm.title' })}
                  </Typography>
                  <Box p={2}>
                    <Typography variant="body2" component="p" gutterBottom>
                      {intl.formatMessage({ id: 'consentForm.description1' })}
                    </Typography>
                    <Typography variant="body2" component="p" gutterBottom>
                      {intl.formatMessage({ id: 'consentForm.description2' })}
                    </Typography>
                  </Box>
                  <hr className={layoutStyles.separatorBold} />
                  <div className={styles.loadingScreen__cardsContainer}>
                    <div className={onboardingStyles.formContainer}>
                      <form onSubmit={handleSubmit}>
                        <CandidateJobChecklist
                          jobList={jobList}
                          hasError={hasJobsSelectError}
                          formSubmit={formSubmit}
                          candidateData={candidateData}
                        />
                        <CandidateContact sectionDisabled={hasJobsSelectError} />
                        <CandidateExperience sectionDisabled={hasJobsSelectError} />
                        <CandidateEducation sectionDisabled={hasJobsSelectError} />
                        <Box className={styles.submitBtnSecond__container}>
                          <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={submitting || hasJobsSelectError}
                            color={hasJobsSelectError ? 'default' : 'primary'}
                          >
                            {submitting ? (
                              <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">
                                  {intl.formatMessage({
                                    id: 'jobs.modal.saving',
                                  })}
                                </span>
                              </div>
                            ) : (
                              intl.formatMessage({
                                id: 'forms.resetPassword.submit',
                              })
                            )}
                          </Button>
                        </Box>
                      </form>
                      <OptOut handleOptOut={handleOptOut} />
                      <OptOutModal
                        open={modalOpen}
                        success={optOutSuccess}
                        successMessage={intl.formatMessage({ id: 'consentForm.optOutSuccessGlobal' })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CandidateConsentForm;

const OptOut = ({ handleOptOut }) => {
  const intl = useIntl();
  let rawText = intl.formatMessage({ id: 'consentForm.optOut' }).split('[');
  const preLink = rawText[0];
  rawText = rawText[1].split(']');
  const link = rawText[0];
  const afterLink = rawText[1];
  return (
    <div className={localStyles.optOut__container}>
      <Typography variant="caption">
        {preLink}{' '}
        <span className={layoutStyles.link} onClick={handleOptOut}>
          {link}
        </span>{' '}
        {afterLink}
      </Typography>
    </div>
  );
};

const CandidateContact = ({ sectionDisabled }) => {
  const intl = useIntl();

  return (
    <Box mt={4}>
      <Typography variant="h2" gutterBottom>
        {intl.formatMessage({ id: 'consentForm.contactInformation' })}
      </Typography>
      <Box mt={1} mb={2}>
        <Typography variant="caption">
          {intl.formatMessage({ id: 'consentForm.contactInformationSubHeader' })}
        </Typography>
      </Box>
      <Box display="flex">
        <InputTextField
          formikId={'first_name'}
          formattedLabelText={intl.formatMessage({
            id: 'consentForm.firstName',
          })}
          muiProps={{ disabled: sectionDisabled }}
        />
        <InputTextField
          formikId={'last_name'}
          formattedLabelText={intl.formatMessage({
            id: 'consentForm.lastName',
          })}
          muiProps={{ disabled: sectionDisabled }}
        />
      </Box>
      <InputTextField
        formikId={'email'}
        formattedLabelText={intl.formatMessage({ id: 'consentForm.email' })}
        muiProps={{ disabled: sectionDisabled }}
      />
      <InputTextField
        formikId={'phone_number'}
        formattedLabelText={intl.formatMessage({
          id: 'consentForm.phoneNumber',
        })}
        muiProps={{
          disabled: sectionDisabled,
          InputProps: {
            inputComponent: PhoneNumberInput,
          },
        }}
      />
    </Box>
  );
};

export const OptOutModal = ({ open, success, successMessage }) => {
  let rawText = successMessage.split('careers@hican.works');
  const preLink = rawText[0];
  const afterLink = rawText[1];
  const link = 'careers@hican.works';
  return (
    <Modal
      className={modalStyles.modal}
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div style={{ outline: 'none' }}>
        <Fade in={open}>
          {success ? (
            <div className={modalStyles.modalPaper} style={{ maxWidth: 400, padding: 40 }}>
              <Typography variant="h5" align="center">
                {afterLink ? (
                  <div>
                    {preLink}{' '}
                    <a className={layoutStyles.link} href={`mailto:${link}`}>
                      {link}
                    </a>{' '}
                    {afterLink}
                  </div>
                ) : (
                  successMessage
                )}
              </Typography>
            </div>
          ) : (
            <div className={modalStyles.modalPaper} style={{ maxWidth: 400, minWidth: 300, padding: 40 }}>
              <Box paddingY={'2em'} marginBottom={'.5em'} display={'flex'} justifyContent="center">
                <CircularProgress />
              </Box>
            </div>
          )}
        </Fade>
      </div>
    </Modal>
  );
};
