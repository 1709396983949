import axios from 'axios';

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = async (method, path, params = {}, publicUrl = false) => {
  const config = {
    method: method,
    url: `${publicUrl ? 'api/vp1' : process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//still used in JobSubmitted & JobRemoved
export const getJobs = (params) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", '/jobs', { params })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const getIndustrySuggestions = (value, language, group) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", '/lookup', {
      params: {
        type: 'naics',
        keywords: value,
        language: language,
        group: group
      }
    }
    )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

//used in skillsModal & CandidateConsent (incorrectly)
export const getProfile = (id) => {
  // return Promise.resolve(dummyProfile);
  return new Promise((resolve, reject) => {
    apiRequest("get", `/profiles/${id}`
    ).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

//used in skillsModal & CandidateConsent (incorrectly)
export const postProfile = (id, values) => {
  // return Promise.resolve(dummyProfile);
  return new Promise((resolve, reject) => {
    apiRequest("post", `/profiles/${id}`, { data: values }
    ).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export const postProfilePublic = (id, values) => {
  // return Promise.resolve(dummyProfile);
  return new Promise((resolve, reject) => {
    apiRequest("post", `/profiles/${id}`, { data: values }, true
    ).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

export const optOutContact = (id) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", `/profiles/${id}/unsubscribe`, {}, true
    ).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

//JobMatches, JobRemoved, JobSaved
export const submitCandidates = (candidates) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve({ message: 'success' });
    }, 1500)
  });
}

//CompanyProfile
export const postId = (value) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", "/id", { data: value }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

//JobMatches, JobRemoved, JobSaved
export const postJobs = (value) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", "/jobs", { data: value }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

//SkillsModal
export const updateSkills = (jobId, selectedSkills) => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve({ message: 'success' });
    }, 500)
  });
}