export const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const emailRegExp = /^\S+@\S+\.\w\w+$/;

export const dateRegExp = /(0[1-9]|10|11|12)\/(19|20)[0-9]{2}$/;

export function preventSubmitOnEnter(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}