import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

/* Styles */
import styles from 'styles/layout.module.scss';
import { isIE } from 'lib/browserDetect';

/* i18n */
import { useIntl } from 'react-intl';

const Layout = ({ children, locale, handleDrawerOpen, loggedIn }) => {
  const intl = useIntl();
  const productName = process.env.REACT_APP_PRODUCT_NAME;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className={styles.layout}>
      <div>
        {isIE && <div className={styles.browserBanner}>{intl.formatMessage({ id: 'browsers.ie.banner' })}</div>}
        {environment !== 'production' && !pathName.includes('candidate-consent-form') ? (
          <div className={styles.envBanner}>
            {intl.formatMessage({ id: 'app.envBanner' }, { productName, env: environment })}
          </div>
        ) : null}
        <Header
          locale={locale}
          handleDrawerOpen={handleDrawerOpen}
          loggedIn={loggedIn}
          consentForm={pathName.includes('candidate-consent-form')}
        />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
