import { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { clearCurrentJob } from 'app/slices/currentJobSlice';

/* Forms */
import UpdateJobForm from 'components/forms/job/UpdateJobForm';

/* Material UI and other UI Dependencies */
import { Box, Typography, Container, Divider } from '@material-ui/core';
import JobNav from 'components/JobNav';
import Loading from 'components/Loading';

/* Analytics */
import TimeMe from 'timeme.js';

const JobDescription = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const { id } = useParams();
  // const { job: formInitialValues } = useSelector((state) => state.currentJob);

  // const refreshMatches = apiHooks.match.useRefreshAllMatches(id);

  const { matches, filteredMatches, jobsById } = useSelector((state) => state.jobs);
  const hasMatches = matches.hasOwnProperty(id);
  const jobInfo = jobsById[id] || {};

  //this should move to a common Job parent
  const submitted_candidates_count = hasMatches ? filteredMatches.contacted[id].length : 0;
  const matching_candidates_count = hasMatches ? filteredMatches.active[id].length : 0;
  const saved_candidates_count = hasMatches ? filteredMatches.saved[id].length : 0;
  const archived_candidates_count = hasMatches ? filteredMatches.archived[id].length : 0;

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOB_DESCRIPTION');
    TimeMe.startTimer();
    return () => {
      // Leaving Job Description
      dispatch(clearCurrentJob());
    };
  }, []);

  return (
    <div className={styles.jobsMatchesWrapper}>
      <div className={dashboardStyles.content}>
        {/* this class isn't rendering  */}
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="flex-start">
            <Link
              to={`${urlPrefix}/jobs`}
              underline="always"
              className={cn(layoutStyles.link, layoutStyles.headingSize, layoutStyles.sourceSerif)}
            >
              {intl.formatMessage({ id: 'jobs.jobs' })}
            </Link>
            <Typography variant="h1">
              &nbsp; &gt; {jobInfo.hasOwnProperty('title') ? jobInfo.title : <Loading.Inline />}
            </Typography>
          </Box>
          <Box mb={3} className={styles.matchesHeaderContent}>
            {hasMatches ? (
              <JobNav
                active="description"
                matchesLength={matching_candidates_count}
                submittedLength={submitted_candidates_count}
                savedLength={saved_candidates_count}
                archivedLength={archived_candidates_count}
                jobId={id}
              />
            ) : null}
          </Box>
          <Divider />
          <Box marginTop={3} marginBottom={5}>
            <UpdateJobForm jobId={id} />
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default JobDescription;
