import * as matchMethods from '../matchMethods';

import { useDispatch } from 'react-redux';
import { setJobMatches } from 'app/slices/jobsSlice';

import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

export const useRefreshAllMatches = (jobId = '', callbacks = {}) => {
  const dispatch = useDispatch();

  const refreshMatches = () => {
    if (!jobId) return;
    matchMethods
      .getAll(jobId)
      .then((response) => {
        dispatch(
          setJobMatches({
            job_id: jobId,
            matches: response.matches,
          })
        );
        if ('onSuccess' in callbacks) {
          callbacks.onSuccess(response);
        }
      })
      .catch((error) => {
        if ('onError' in callbacks) {
          callbacks.onError(error);
        } else {
          console.error(error);
        }
      });
  };

  return refreshMatches;
};

export const useUpdateMatchStatus = (jobId, matchId, callbacks = {}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const refreshMatches = useRefreshAllMatches(jobId, callbacks);

  //"contact" the candidate - "post"
  const contact = () => {
    matchMethods
      .contact(jobId, matchId)
      .then((response) => {
        console.log('success', response);
        refreshMatches();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(intl.formatMessage({ id: 'jobs.matches.submitCandidatesError' }), { variant: 'error' });
        if ('onError' in callbacks) {
          callbacks.onError(error);
        }
      });
  };

  //change status to "ARCHIVE" - "delete"
  const archive = () => {
    console.log('i did not remove this candidate');
    // if (active_route === 'matches') {
    //   trackEvent('JOBS_MATCHES_VIEW_DETAILS_REMOVE');
    // }
    // setRemovingCandidate(true);
    // let jobValues = {
    //   action: 'candidate_archive',
    //   job_id: jobId,
    //   candidates: [{ id: matchId }]
    // }

    // postJobs(jobValues).then(function(response) {
    //   dispatch(setJobMatches({
    //     job_id: jobId,
    //     matches: response.value.matches
    //   }));
    //   setRemovingCandidate(false);
    //   history.push(`${urlPrefix}/jobs/${jobId}/${active_route}`);
    // }).catch(function() {
    //   enqueueSnackbar(intl.formatMessage({id:'jobs.matches.removeCandidateError'}), {variant: 'error'});
    //   setRemovingCandidate(false);
    // })
  };

  //change status to "SAVED" > put with status: saved
  const save = () => {
    // if (active_route === 'matches') {
    //   trackEvent('JOBS_MATCHES_VIEW_DETAILS_SAVE');
    // }
    // setSavingCandidate(true);
    // let jobValues = {
    //   action: 'candidate_save',
    //   job_id: jobId,
    //   candidates: [{ id: `${matchId}` }],
    //   // notes
    // };
    console.log('i did not save this candidate');
    // postJobs(jobValues).then(function(response) {
    //   dispatch(setJobMatches({
    //     job_id: jobId,
    //     matches: response.value.matches
    //   }));
    //   setSavingCandidate(false);
    //   history.push(`${urlPrefix}/jobs/${jobId}/${active_route}`);
    // }).catch(function() {
    //   enqueueSnackbar(intl.formatMessage({id:'jobs.matches.saveCandidateError'}), {variant: 'error'});
    //   setSavingCandidate(false);
    // })
  };

  return { contact, archive, save };
};
