import { useLocation } from "react-router-dom";

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/layout.module.scss';
import cn from 'classnames';

/* Assets */
import { stateFooter } from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import { Link } from "react-router-dom";

const Footer =({children}) => {

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const handleEmailHereClick = () => {
    const menuItems = ['matches', 'submitted', 'saved', 'removed'];
    if (menuItems.indexOf(activePath) > -1) {
      const eventName = `JOBS_${activePath.toUpperCase()}_FOOTER_EMAIL_HERE`;
      trackEvent(eventName);
    }
  }

  const handlePrivacyClick = () => {
    const menuItems = ['matches', 'submitted', 'saved', 'removed'];
    if (menuItems.indexOf(activePath) > -1) {
      const eventName = `JOBS_${activePath.toUpperCase()}_FOOTER_PRIVACY`;
      trackEvent(eventName);
    }
  }

  const handleCreditsClick = () => {
    const menuItems = ['matches', 'submitted', 'saved', 'removed'];
    if (menuItems.indexOf(activePath) > -1) {
      const eventName = `JOBS_${activePath.toUpperCase()}_FOOTER_CREDITS_CITATION`;
      trackEvent(eventName);
    }
  }

  return(
      <div className={styles.footer}>
        <div className={styles.colorsBar}>
          <div className={cn(styles.colorsBar__red, styles.colorsBar__color)}></div>
          <div className={cn(styles.colorsBar__green, styles.colorsBar__color)}></div>
          <div className={cn(styles.colorsBar__orange, styles.colorsBar__color)}></div>
          <div className={cn(styles.colorsBar__blue, styles.colorsBar__color)}></div>
        </div>
        <Container maxWidth="lg">
          <Grid container className={styles.footerContent} spacing={0}>
            <Grid item xs={12} sm={6}>
              <div className={styles.footerImages}>
                <img alt={intl.formatMessage({id: 'layout.menu.stateImage'})} className={styles.stateFooter} src={stateFooter.slice(1, -1)} />
              </div>
              <div className={cn(styles.footerText, styles.officialText)}>
                <FormattedMessage id="layout.footer.officialWebsite" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.footerText}>
                <FormattedMessage id="layout.footer.feedbackText" />
                <a
                  href="mailto:connect@ripl.org"
                  className={styles.footerLink}
                >
                  <FormattedMessage id="layout.footer.feedbackLink" />
                </a>
              </div>
              <div className={styles.footerText}>
                <Link
                  to={'/terms-of-use'}
                  className={styles.footerLink}
                >
                  <FormattedMessage id="layout.footer.privacy" />
                </Link>
              </div>
              <div className={styles.footerText}>
                <Link
                  to={'/credits-and-citations'}
                  className={styles.footerLink}
                >
                  <FormattedMessage id="layout.footer.credits" />
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
        <hr className={styles.separator} />
        <Container maxWidth="lg">
          <div className={styles.footer__copy}>
            <FormattedMessage id="layout.footer.copy" />
          </div>
        </Container>
      </div>
  )
}

export default Footer;