/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';

/* Material UI and other UI Dependencies */
import { Box } from "@material-ui/core";

const SkillsExplanation = (props) => {

  const {GreenCheck, WhiteCheck, YellowStop} = props
  const intl = useIntl();

  return (
    <>
      <Box mt={2} className={styles.skillsExplanation}>
        <div className={styles.skillExplanation__skill}>
          <img src={GreenCheck} alt={intl.formatMessage({id: 'layout.alt.checkmark'})} />
          <span className={styles.skillExplanation__text}>{intl.formatMessage({id: 'jobs.matches.skillsConfirmed'})}</span>
        </div>
        <div className={styles.skillExplanation__skill}>
          <img src={WhiteCheck} alt={intl.formatMessage({id: 'layout.alt.checkmark'})} />
          <span className={styles.skillExplanation__text}>{intl.formatMessage({id: 'jobs.matches.skillsMayHave'})}</span>
        </div>
        <div className={styles.skillExplanation__skill}>
          <img src={YellowStop} alt={intl.formatMessage({id: 'layout.alt.checkmark'})} />
          <span className={styles.skillExplanation__text}>{intl.formatMessage({id: 'jobs.matches.skillsNotConfirmed'})}</span>
        </div>
      </Box>
    </>
  )
}

export default SkillsExplanation