import { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Styles */
import styles from 'styles/login.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { jobMethods } from 'lib/api/index';
import { apiHooks } from 'lib/api/index';

/* Forms */
import { Formik } from 'formik';
import { useJobValidation } from './validation';
import { ZIPCodeInput, InputTextField } from 'components/inputs';
import JobRequirementsSection from 'components/forms/job/sections/JobRequirementsSection';
import JobDescriptionSection from 'components/forms/job/sections/JobDescriptionSection/JobDescriptionSection';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setUnrecoverableError } from 'app/slices/appSlice';
import {
  setLoadingStepMessage,
  setOnboardingMaxStep,
  setNewJobId,
  setContactUpdate,
  setNeedsUserOnboarding,
} from 'app/slices/onboardingSlice';
import { setCurrentJob } from 'app/slices/currentJobSlice';

/* Material UI and other UI Dependencies */
import Typography from '@material-ui/core/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import { Button, Box, FormControl, FormControlLabel, Checkbox, Divider } from '@material-ui/core';

export default function OnboardingJob() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { newJobId } = useSelector((state) => state.onboarding);
  const { job: formInitialValues } = useSelector((state) => state.currentJob);
  const { zip_code: employerZip } = useSelector((state) => state.employer);

  const [submitting, setSubmitting] = useState(false);

  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const handleGoBack = () => {
    dispatch(setOnboardingMaxStep(0));
    dispatch(setNeedsUserOnboarding(true));
    dispatch(setContactUpdate(true));
    history.push(`${urlPrefix}/onboarding/signup`);
  };

  const handleContinueToNextStep = () => {
    dispatch(setOnboardingMaxStep(2));
    history.push(`${urlPrefix}/onboarding/skills`);
    dispatch(setLoadingStepMessage(''));
  };

  const handleSaveJob = (formValues) => {
    const postBody = {
      ...formValues,
      certifications: formValues.certifications.filter((cert) => cert.length > 0),
    };
    dispatch(setCurrentJob(postBody));
    const actionFunc = newJobId ? jobMethods.update : jobMethods.create;
    const actionFuncParams = newJobId ? [newJobId, postBody] : [postBody];
    actionFunc(...actionFuncParams)
      .then((response) => {
        if (!newJobId) {
          dispatch(setNewJobId(response));
        }
        handleContinueToNextStep();
      })
      .catch(() => {
        dispatch(setUnrecoverableError(true));
      });
  };

  const handleParsingDuringSubmitError = (error) => {
    console.log(error);
    dispatch(setLoading(false));
    dispatch(setLoadingStepMessage(''));
  };

  const handleRecreateMissingSkillList = apiHooks.upload.useParseString({
    onSuccess: handleSaveJob,
    onError: handleParsingDuringSubmitError,
  });

  const validationSchema = useJobValidation({
    withSelectedSkills: false,
  });

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <OnboardingSteps activeStep={1} />
        <Box px={3} mt={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.job.title' })}
          </Typography>
        </Box>
        <Box px={3} mt={1}>
          <Typography align="center" variant="h3" component="p">
            {intl.formatMessage({ id: 'onboarding.job.subtitle1' })}
          </Typography>
          <Typography align="center" variant="h3" component="p">
            {intl.formatMessage({ id: 'onboarding.job.subtitle2' })}
          </Typography>
        </Box>
      </div>
      <Divider />
      <div className={styles.loadingScreen__cardsContainer}>
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={{
            ...formInitialValues,
            zip_code: employerZip,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            dispatch(setLoadingStepMessage('onboarding.job.submit'));
            dispatch(setCurrentJob(values));
            setSubmitting(true);
            if (values.skills.length === 0 || values.soc_code === '') {
              handleRecreateMissingSkillList(values);
            } else {
              handleSaveJob(values);
            }
          }}
        >
          {({ handleChange, handleSubmit, values, isValid, errors }) => {
            const hasTitle = values.title && values.title.length > 0;
            return (
              <form
                onSubmit={handleSubmit}
                style={{ paddingBottom: '0px' }}
                className={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
              >
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({
                      id: 'forms.onboarding.sections.jobDetails',
                    })}
                  </Typography>
                </Box>
                <InputTextField
                  formikId={'title'}
                  formattedLabelText={`${intl.formatMessage({
                    id: 'forms.onboarding.sections.jobTitle',
                  })}*`}
                />
                <InputTextField
                  formikId={'zip_code'}
                  formattedLabelText={intl.formatMessage({
                    id: 'forms.onboarding.jobZipcode',
                  })}
                  muiProps={{
                    InputProps: {
                      inputComponent: ZIPCodeInput,
                    },
                  }}
                />
                <Box>
                  <FormControl
                    classes={{
                      root: onboardingStyles.formControlRoot,
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        root: onboardingStyles.formControlLabelRoot,
                        label: onboardingStyles.formControlLabeLabel,
                      }}
                      label={intl.formatMessage({
                        id: 'forms.onboarding.remote',
                        description: 'string',
                      })}
                      control={
                        <Checkbox
                          value={values.remote}
                          checked={values.remote}
                          color="primary"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'remote',
                                value: e.currentTarget.checked,
                              },
                            })
                          }
                          name="remote"
                        />
                      }
                      labelPlacement="end"
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl
                    classes={{
                      root: onboardingStyles.formControlRoot,
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        root: onboardingStyles.formControlLabelRoot,
                        label: onboardingStyles.formControlLabeLabel,
                      }}
                      label={intl.formatMessage({
                        id: 'forms.onboarding.partTime',
                        description: 'string',
                      })}
                      control={
                        <Checkbox
                          value={values.part_time}
                          checked={values.part_time}
                          color="primary"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: 'part_time',
                                value: e.currentTarget.checked,
                              },
                            })
                          }
                          name="part_time"
                        />
                      }
                      labelPlacement="end"
                    />
                  </FormControl>
                </Box>
                <InputTextField
                  formikId={'job_posting_url'}
                  formattedLabelText={`${intl.formatMessage({
                    id: 'forms.onboarding.sections.jobPostingURL',
                  })}*`}
                />
                {hasTitle ? (
                  <Box pb={2}>
                    {/* giving it a little space from the buttons while Requirements is disabled  */}
                    <JobDescriptionSection />
                    <JobRequirementsSection />
                  </Box>
                ) : null}
                <Box className={styles.submitBtn__container} mt={4}>
                  <Button disabled={submitting} size="large" variant="contained" onClick={handleGoBack}>
                    {intl.formatMessage({ id: 'forms.onboarding.back' })}
                  </Button>

                  <Button disabled={submitting} size="large" type="submit" variant="contained" color="primary">
                    {intl.formatMessage({ id: 'forms.onboarding.next' })}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
