/* Authentication */
import { useAuth0 } from "@auth0/auth0-react";

/* API */
import { apiHooks, employerMethods } from "lib/api/index";

/* Redux */
import { useDispatch } from 'react-redux';
import {
  setLoading,
  setUnrecoverableError,
} from 'app/slices/appSlice';
import {
  setNeedsUserOnboarding,
  setNeedsJobsOnboarding,
} from 'app/slices/onboardingSlice';
import { setEmployer } from 'app/slices/employerSlice';


export const useGetUser = (callbacks = {}) => {

  const dispatch = useDispatch();
  const {
    user: auth0_user
  } = useAuth0();

  //getUser success: authenticated user
  const refreshJobs = apiHooks.job.useRefreshAllJobs({
    onSuccess: (response) => {
      dispatch(setLoading(false)); //whole app stops loading
      if ('onSuccess' in callbacks) {
        callbacks.onSuccess(response)
      }
    },
    onError: (error) => {
      dispatch(setLoading(false)); //whole app stops loading
      if ('onError' in callbacks) {
        callbacks.onError(error)
      }
    }
  });

  //getUser error: new user
  const handleNewUser = (response) => {
    dispatch(setEmployer({ email: auth0_user.email }))
    dispatch(setNeedsUserOnboarding(true));
    dispatch(setNeedsJobsOnboarding(true));
    dispatch(setLoading(false));  //whole app stops loading
    if ('onSuccess' in callbacks) {
      callbacks.onSuccess(response)
    }
  };

  //getUser error: other
  const handleError = (error) => {
    dispatch(setLoading(false));  //whole app stops loading
    if ('onError' in callbacks) {
      callbacks.onError(error)
    } else {
      console.error(error)
      dispatch(setUnrecoverableError(true));
    }
  }

  const getUser = () => {
    dispatch(setLoading(true)); //whole app loading
    employerMethods.get()
      .then((response) => {
        if (response.status_code === 400) {
          if (response.detail === "User with sub does not exist") {
            handleNewUser(response);
          } else {
            handleError(response);
          }
        } else {
          dispatch(setEmployer({ ...response, email: auth0_user.email }));
          refreshJobs(); //received setLoading(false) in hook arguments
        }
      }).catch((getIdError) => {
        if (getIdError.response?.data?.error_message === 'user is not registered') {
          handleNewUser(getIdError);
        } else {
          handleError(getIdError);
        }
      });
  }

  return getUser;
}
