/* Context */
import { useField } from "formik";

/* i18n */
import { useIntl } from "react-intl";

/* Utils */
import { educationLevelsIntlArr } from "lib/education";

/* Material UI and other UI Dependencies */
import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

//have to use as child of <Formik><Form>, not useFormik
const EducationLevelSelect = ({ formikId = "min_education_level" }) => {
  const intl = useIntl();
  const [fieldInputProps, fieldMetaProps] = useField(formikId);
  const hasError = fieldMetaProps.touched && Boolean(fieldMetaProps.error);

  return (
    <FormControl variant="filled" error={hasError}>
      <InputLabel id="education-level-label">
        {`${intl.formatMessage({
          id: "forms.onboarding.educationLevelLabel",
          description: "string",
        })} (${intl.formatMessage({ id: "optional" })})`}
      </InputLabel>
      <Select
        name={formikId}
        labelId="education-level-label"
        id="education-level"
        {...fieldInputProps}
      >
        {educationLevelsIntlArr.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {value ? intl.formatMessage({ id: value }) : ""}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText>{fieldMetaProps.error}</FormHelperText>}
    </FormControl>
  );
};

export default EducationLevelSelect;
