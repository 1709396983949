import { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import vars from 'styles/vars.module.scss';

/* Styles */
import styles from 'styles/login.module.scss';
import welcomeStyles from 'styles/welcome.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setUnrecoverableError } from 'app/slices/appSlice';
import { setMinEducationLevelFilter, setMinYearsExperienceFilter } from 'app/slices/jobsSlice';

/* Assets */
import ArrowRight from 'assets/images/Arrow_RightLight.svg';
import Handshake from 'assets/images/Handshake.svg';
import Plane from 'assets/images/Plane.svg';
import Magnifier from 'assets/images/Magnifier.svg';

/* Material UI and other UI Dependencies */
import { Box, LinearProgress, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* API */
import { matchMethods } from 'lib/api/index';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 12,
    border: `solid 1px ${vars.mediumGray}`,
    maxWidth: 276,
    margin: '0 auto',
    zIndex: 10,
  },
  colorPrimary: {
    backgroundColor: vars.lightGray,
  },
  bar: {
    backgroundColor: vars.progressGreen,
  },
}))(LinearProgress);

export default function JobMatch() {
  const [finding, setFinding] = useState(true);
  const [found, setFound] = useState(0);
  const [barWidth, setBarWidth] = useState(0);

  const { id } = useParams();
  const matches = useSelector((state) => state.jobs.matches);
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (barWidth < 75) {
        setBarWidth(barWidth + 20);
      }
    }, 1000);

    return () => clearTimeout(interval.current);
  }, [barWidth]);
  useEffect(() => {
    if (!matches.hasOwnProperty(id)) {
      matchMethods
        .create(id)
        .then((response) => {
          console.log('matches gotten', response);
          setFound(response.num_matches);
          clearTimeout(interval.current);
          setBarWidth(100);
          setFinding(false);
        })
        .catch((error) => {
          console.error(error);
          dispatch(setUnrecoverableError(true));
        });
    }
    dispatch(setMinYearsExperienceFilter(false));
    dispatch(setMinEducationLevelFilter(false));
  }, []);

  const handleButtonClick = () => {
    trackEvent('JOB_MATCH_SEE_MATCHES');
    history.push(`${urlPrefix}/jobs/${id}/matches`);
  };

  return (
    <>
      <div className={onboardingStyles.onboardingHeaderThin}>
        <Box px={3} mb={3}>
          <Typography align="center" variant="h1">
            {finding
              ? intl.formatMessage({ id: 'jobs.match.finding' })
              : intl.formatMessage({ id: 'jobs.match.found' }, { number: found })}
          </Typography>
        </Box>
        {!finding ? (
          <Box display="flex" justifyContent="center">
            <Button color="primary" size="large" variant="contained" onClick={handleButtonClick}>
              {intl.formatMessage({ id: 'jobs.match.seeMatches' })}
            </Button>
          </Box>
        ) : null}
        {finding ? <BorderLinearProgress variant="determinate" value={barWidth} /> : null}
        <Box px={3} mt={1}>
          <Typography align="center" variant="h3" component="p">
            {intl.formatMessage({ id: 'jobs.match.subtitle' })}
          </Typography>
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <div className={styles.loadingScreen__cardsContainer}>
        <div className={welcomeStyles.imagesContainer}>
          <div className={welcomeStyles.imageContainer}>
            <img alt="Resume" className={cn(onboardingStyles.imageMagnifier)} src={Magnifier} />
            <div className={welcomeStyles.imageTextContainer}>
              <Typography align="center" variant="caption" gutterBottom>
                {intl.formatMessage({ id: 'jobs.match.step1', description: 'string' })}
              </Typography>
            </div>
          </div>
          <img alt="Arrow" className={cn(welcomeStyles.imageArrow)} src={ArrowRight} />
          <div className={welcomeStyles.imageContainer}>
            <img alt="Gears" className={cn(onboardingStyles.imagePlane)} src={Plane} />
            <Box className={welcomeStyles.imageTextContainer} pt={3}>
              <Typography align="center" variant="caption" gutterBottom>
                {intl.formatMessage({ id: 'jobs.match.step2', description: 'string' })}
              </Typography>
            </Box>
          </div>
          <img alt="Arrow" className={cn(welcomeStyles.imageArrow)} src={ArrowRight} />
          <div className={welcomeStyles.imageContainer}>
            <img alt="Archery" className={cn(onboardingStyles.imageHandshake)} src={Handshake} />
            <Box className={welcomeStyles.imageTextContainer} pt={3}>
              <Typography align="center" variant="caption" gutterBottom>
                {intl.formatMessage({ id: 'jobs.match.step3', description: 'string' })}
              </Typography>
            </Box>
          </div>
        </div>
        <div className={welcomeStyles.fullTextContainer}>
          <Typography align="center" variant="caption" gutterBottom>
            {intl.formatMessage({ id: 'jobs.match.step1' })}
            <br />
            {intl.formatMessage({ id: 'jobs.match.step2' })}
            <br />
            {intl.formatMessage({ id: 'jobs.match.step3' })}
          </Typography>
        </div>
      </div>
    </>
  );
}
