import cn from 'classnames';
import { Link } from "react-router-dom";

/* Styles */
import styles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

const NoMatches = (props) => {

  const {
    savedPage,
    removedPage,
    submittedPage, 
    matchesTypes,
    activeTab,
    handlePotentialLinkClick,
    handleTopLinkClick,
    otherTabs,
    handleTrainableLinkClick,
    handleEditSkillsClick,
    urlPrefix,
    jobId
  } = props
  
  const intl = useIntl();

  return (
    <>
          <div className={styles.tableNotMatchesRow}>
              {
                !savedPage && !submittedPage && !removedPage ? (
                  <>
                    <span className={styles.noMatchesTitle}>{intl.formatMessage({id: 'jobs.matches.noCandidates'}, {type: intl.formatMessage({id: `jobs.matches.type.${matchesTypes[activeTab]}`})})}</span>
                    <div className={styles.noMatchesText}>
                      {intl.formatMessage({id: 'jobs.matches.toView'})} <span className={layoutStyles.link} onClick={activeTab === 0 ? handlePotentialLinkClick : handleTopLinkClick}>{intl.formatMessage({id: `jobs.matches.type.${otherTabs[0]}`})}</span> <span>{intl.formatMessage({id: 'jobs.matches.or'})}</span> <span className={layoutStyles.link} onClick={activeTab === 2 ? handlePotentialLinkClick : handleTrainableLinkClick}>{intl.formatMessage({id: `jobs.matches.type.${otherTabs[1]}`})}</span> <span>{intl.formatMessage({id: 'jobs.matches.candidatesOr'})}</span> <span className={layoutStyles.link} onClick={handleEditSkillsClick}>{intl.formatMessage({id: 'jobs.matches.addRemoveSkills'})}</span> <span>{intl.formatMessage({id: 'jobs.matches.changeSearchCriteria'})}</span>
                    </div>
                  </>
                ) : null
              }
              {submittedPage ? (
                <div className={styles.noMatchesText}>
                  {intl.formatMessage({id: 'jobs.submitted.emptyState'})} <Link
                    to={`${urlPrefix}/jobs/${jobId}/matches`}
                    className={cn(layoutStyles.link, layoutStyles.linkSize)}
                  >{intl.formatMessage({id: 'jobs.matches'}).toLocaleLowerCase()}</Link>.
                </div>
              ) : null}
              {savedPage ? (
                <div className={styles.noMatchesText}>
                  {intl.formatMessage({id: 'jobs.saved.emptyState'})} <Link
                    to={`${urlPrefix}/jobs/${jobId}/matches`}
                    className={cn(layoutStyles.link, layoutStyles.linkSize)}
                  >{intl.formatMessage({id: 'jobs.matches'}).toLocaleLowerCase()}</Link>.
                </div>
              ) : null}
              {removedPage ? (
                <div className={styles.noMatchesText}>
                  {intl.formatMessage({id: 'jobs.removed.emptyState'})} <Link
                    to={`${urlPrefix}/jobs/${jobId}/matches`}
                    className={cn(layoutStyles.link, layoutStyles.linkSize)}
                  >{intl.formatMessage({id: 'jobs.matches'}).toLocaleLowerCase()}</Link>.
                </div>
              ) : null}
            </div>
    </>
  )
}

export default NoMatches