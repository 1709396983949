/* Core Dependencies */
import { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@tanstack/react-query';
import { pathOr } from 'ramda';

/* API */
import { configureAuth } from 'lib/api/config';

/* Styles */
import styles from 'styles/login.module.scss';

/* App Theme and UI Dependencies */
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/ui-theme';
import { Layout } from 'components/layout';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setDrawerOpen, setUnrecoverableError } from 'app/slices/appSlice';
import { setEmployer } from 'app/slices/employerSlice';
import { setNeedsUserOnboarding, setNeedsJobsOnboarding } from 'app/slices/onboardingSlice';
import { setJobs } from 'app/slices/jobsSlice';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from "timeme.js";

/* UI Components */
import { SnackbarProvider } from 'notistack';
import AppHeader from 'components/AppHeader';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Drawer from "components/Drawer";

import OnboardingSignUp from 'components/forms/OnboardingSignUp';
import OnboardingJob from 'components/forms/OnboardingJob';
import OnboardingSkills from 'components/forms/OnboardingSkills';
import OnboardingHeader from 'components/OnboardingHeader';

import CompanyProfile from 'components/CompanyProfile';

import Dashboard from 'components/Dashboard';

import Jobs from 'components/Jobs';
import JobsArchived from 'components/JobsArchived';

import JobsNew from 'components/pages/job/JobsNew';
import JobDescription from 'components/pages/job/JobDescription';
import JobMatches from 'components/JobMatches';
import JobSubmitted from 'components/JobSubmitted';
import JobSaved from 'components/JobSaved';
import JobRemoved from 'components/JobRemoved';

import CandidateDetails from 'components/CandidateDetails';
import {
  Welcome,
  TermsOfUse,
  CreditsCitations,
  CandidateConsent
} from 'components/pages/unauthenticated';


function App() {

  /*-- global state --*/
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: auth0_loading,
    user: auth0_user,
    logout,
  } = useAuth0();
  const {
    language,
    drawerOpen,
    loading: appLoading,
    unrecoverableError,
    urlPrefix
  } = useSelector((state) => state.app);
  const employer = useSelector((state) => state.employer)
  const {
    loadingStepMessage,
    needsUserOnboarding,
    needsJobsOnboarding
  } = useSelector(state => state.onboarding);

  /*-- component state --*/
  const employerDataLoaded = (employer.hasOwnProperty("id") && employer.id.length > 0);

  /*-- hooks --*/
  
  const dispatch = useDispatch();

  /*-- UI state --*/
  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  /*-- authentication --*/
  const signOutHandler = () => {
    dispatch(setLoading(true));
    logout({
      returnTo: window.location.origin,
      federated: true
    });
    dispatch(setLoading(false));
  }

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      console.warn("Failed to get access token", e);
    }
    return null;
  }

  useEffect(() => {
    configureAuth(getAccessToken);
    if (!auth0_loading && isAuthenticated) {
      dispatch(setLoading(true));
    } else {
      if (!auth0_loading) dispatch(setLoading(false));
    }
  }, [auth0_loading, isAuthenticated]);

  const notRefetch = { retry: false, retryOnMount: false, refetchOnWindowFocus: false };
  const employerQuery = useQuery({ queryKey: ['/users/'], enabled: isAuthenticated, ...notRefetch });
  const jobsQuery = useQuery({ queryKey: ['/users/me/jobs'], enabled: isAuthenticated && employerDataLoaded, ...notRefetch });

  useEffect(() => {
    if (employerQuery.isSuccess) {
      if (pathOr('', ['data', 'status_code'], employerQuery) === 400) {
        if (pathOr('', ['data', 'detail'], employerQuery) === "User with sub does not exist") {
          dispatch(setEmployer({ email: auth0_user.email }))
          dispatch(setNeedsUserOnboarding(true));
          dispatch(setNeedsJobsOnboarding(true));
          dispatch(setLoading(false));
        } else {
          dispatch(setUnrecoverableError(true));
        }
      } else {
        dispatch(setEmployer({ ...employerQuery.data, email: auth0_user.email }));
      }
    }
  }, [employerQuery.isSuccess]);

  useEffect(() => {
    if (jobsQuery.isSuccess) {
      if (jobsQuery.data.length > 0) {
        dispatch(setJobs(jobsQuery.data));
      } else {
        dispatch(setNeedsJobsOnboarding(true));
      }
      dispatch(setLoading(false));
    }
    if (jobsQuery.isError) {
      dispatch(setUnrecoverableError(true));
    }
  }, [jobsQuery.isSuccess, jobsQuery.isError]);

  /*-- analytics --*/
  useEffect(() => {
    /**
     * Configuration of idle time before we track
     * the user as inactive
     */
    TimeMe.initialize({
      idleTimeoutInSeconds: 300 // seconds
    });

    TimeMe.callWhenUserLeaves(() => {
      const timeReport = TimeMe.getTimeOnAllPagesInSeconds();
      timeReport.forEach(({ pageName, timeOnPage }) => {
        if (pageName !== 'default-page-name') {
          trackEvent(`TIME_${pageName}_PAGETOTAL`, { timeOnPage: timeOnPage.toString() })
        }
      })
      const timeActiveSession = timeReport.reduce((prev, { timeOnPage }) => prev + timeOnPage, 0);
      trackEvent('TIME_ACTIVE_SESSION', { timeOnPage: timeActiveSession.toString() });
    });

    TimeMe.callWhenUserReturns(() => {
      TimeMe.resetAllRecordedPageTimes();
    });
  }, []);

  function renderContents() {
    if (!isAuthenticated) {
      return <Welcome />
    } else if (unrecoverableError) {
      return (
        <ErrorMessage />
      );
    } else if (appLoading) {
      return (
        <Loading.Icon />
      );
    } else if (loadingStepMessage.length > 0) {
      return (
        <>
          <OnboardingHeader />
          <div className={styles.loginFlow}>
            <div className={styles.loginFlow__formContainer}>
              <Loading.Step />
            </div>
          </div>
        </>
      );
    } else if (needsUserOnboarding || needsJobsOnboarding) {
      return (
        <>
          <OnboardingHeader />
          <div className={styles.loginFlow}>
            <div className={styles.loginFlow__formContainer}>
              <Switch>
                <Route path={['/onboarding/signup', '/:lang/onboarding/signup']}>
                  <OnboardingSignUp />
                </Route>
                <Route path={['/onboarding/job', '/:lang/onboarding/job']}>
                  <OnboardingJob />
                </Route>
                <Route path={['/onboarding/skills', '/:lang/onboarding/skills']}>
                  <OnboardingSkills />
                </Route>
                <Route>
                  <Redirect to={needsUserOnboarding ? `${urlPrefix}/onboarding/signup` : `${urlPrefix}/onboarding/job`} />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <OnboardingHeader />
          <AppHeader />
          <Switch>
            {/* pages that don't need job id */}
            <Route path={['/company-profile', '/:lang/company-profile']} children={<CompanyProfile />} />
            <Route path={['/dashboard', '/:lang/dashboard']} children={<Dashboard />} />
            {/* pages that need job id */}
            <Route
              path={[
                '/jobs/:id/matches/:matchId', '/:lang/jobs/:id/matches/:matchId',
                '/jobs/:id/submitted/:matchId', '/:lang/jobs/:id/submitted/:matchId',
                '/jobs/:id/saved/:matchId', '/:lang/jobs/:id/saved/:matchId',
                '/jobs/:id/removed/:matchId', '/:lang/jobs/:id/removed/:matchId',
              ]}
              children={<CandidateDetails />}
            />
            <Route path={['/jobs/:id/match', '/:lang/jobs/:id/match']} children={<Loading.JobMatch />} />
            <Route path={['/jobs/:id/description', '/:lang/jobs/:id/description']} children={<JobDescription />} />
            <Route path={['/jobs/:id/matches', '/:lang/jobs/:id/matches']} children={<JobMatches />} />
            <Route path={['/jobs/:id/submitted', '/:lang/jobs/:id/submitted']} children={<JobSubmitted />} />
            <Route path={['/jobs/:id/saved', '/:lang/jobs/:id/saved']} children={<JobSaved />} />
            <Route path={['/jobs/:id/removed', '/:lang/jobs/:id/removed']} children={<JobRemoved />} />
            {/* pages that don't need job id */}
            <Route path={['/jobs/new', '/:lang/jobs/new']} children={<JobsNew />} />
            <Route path={['/jobs/archived', '/:lang/jobs/archived']} children={<JobsArchived />} />
            <Route path={['/jobs', '/:lang/jobs']} children={<Jobs />} />
            <Route>
              <Redirect to={`${urlPrefix}/dashboard`} />
            </Route>
          </Switch>
        </>
      );
    }
  }
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Layout locale={language} handleDrawerOpen={handleDrawerOpen} loggedIn={isAuthenticated}>
          <SnackbarProvider>
            <Switch>
              <Route path={['/terms-of-use', ':lang/terms-of-use']}>
                <TermsOfUse />
              </Route>
              <Route path={['/credits-and-citations', ':lang/credits-and-citations']}>
                <CreditsCitations />
              </Route>
              <Route path={['/candidate-consent-form/:profileId', '/:lang/candidate-consent-form/:profileId']}>
                <CandidateConsent />
              </Route>
              <Route path={['/candidate-consent-form/:profileId/unsubscribe', '/:lang/candidate-consent-form/:profileId/unsubscribe']}>
                <CandidateConsent />
              </Route>
              <Route>
                {renderContents()}
              </Route>
            </Switch>
          </SnackbarProvider>
          <Drawer
            anchor="right"
            open={drawerOpen}
            handleDrawerClose={handleDrawerClose}
            signOut={signOutHandler}
          />
        </Layout>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
