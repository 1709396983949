import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setUnrecoverableError } from 'app/slices/appSlice';
import { setJobs } from 'app/slices/jobsSlice';
import { setNewJobId, setNeedsJobsOnboarding } from 'app/slices/onboardingSlice';
import { setCurrentJob } from 'app/slices/currentJobSlice';
import * as jobMethods from '../jobMethods';

export const useRefreshAllJobs = (callbacks = {}) => {
  const dispatch = useDispatch();

  const refreshJobs = () => {
    jobMethods
      .getAll()
      .then((response) => {
        if (response.length > 0) {
          dispatch(setJobs(response));
          if ('onSuccess' in callbacks) {
            callbacks.onSuccess(response);
          }
        } else {
          //there shouldn't be a reason to get an empty array back
          //unless it's a user who should create a job in the onboarding flow
          console.warn('no jobs found', response);
          dispatch(setNeedsJobsOnboarding(true));
          dispatch(setLoading(false));
        }
      })
      .catch((error) => {
        if ('onError' in callbacks) {
          callbacks.onError(error);
        } else {
          console.error(error);
          dispatch(setUnrecoverableError(true));
        }
      });
  };

  return refreshJobs;
};

export const useUpdateJob = (jobId, callbacks = {}) => {
  const dispatch = useDispatch();
  const refreshJobs = useRefreshAllJobs();

  const updateJob = (formValues) => {
    const postBody = {
      ...formValues,
      certifications: formValues.certifications.filter((cert) => cert.length > 0),
    };
    jobMethods
      .update(jobId, postBody)
      .then((response) => {
        refreshJobs();
        if ('onSuccess' in callbacks) {
          callbacks.onSuccess(response);
        }
      })
      .catch((error) => {
        console.log(error);
        if ('onError' in callbacks) {
          callbacks.onError(error);
        } else {
          console.error(error);
          dispatch(setUnrecoverableError(true));
        }
      });
  };

  return updateJob;
};

export const useCreateJob = (callbacks = {}) => {
  const { newJobId } = useSelector((state) => state.onboarding); //temporary until onboarding is updated
  const { currentJobId } = useSelector((state) => state.currentJob);
  const dispatch = useDispatch();
  const refreshJobs = useRefreshAllJobs();
  const updateJobOnboarding = useUpdateJob(newJobId, callbacks); //temporary until onboarding is updated
  const updateJob = useUpdateJob(currentJobId, callbacks);

  const createJob = (formValues) => {
    const postBody = {
      ...formValues,
      certifications: formValues.certifications.filter((cert) => cert.length > 0),
    };
    dispatch(setCurrentJob(postBody));

    if (newJobId) {
      updateJobOnboarding(postBody);
    } else if (currentJobId) {
      updateJob(postBody);
    } else {
      jobMethods
        .create(postBody)
        .then((response) => {
          if ('onSuccess' in callbacks) {
            callbacks.onSuccess(response);
          }
          refreshJobs();
        })
        .catch((error) => {
          if ('onError' in callbacks) {
            callbacks.onError(error);
          } else {
            console.error(error);
            dispatch(setUnrecoverableError(true));
          }
        });
    }
  };

  return createJob;
};
