import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

/* Misc */
import * as helpers from './utils';

/* Analytics */
import { trackEvent } from 'lib/analytics';


const CandidateHeader = () => {

  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();
  const { id: jobId, matchId } = useParams();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const isMobile = useMediaQuery('(max-width:639px)');
  const { filteredMatches } = useSelector((state) => state.jobs);

  const active_route = helpers.getActiveRoute(pathname);
  const active_matches = helpers.getActiveMatchList(active_route, jobId, filteredMatches);
  const activeCandidateIndex = active_matches.findIndex(match => match.id === matchId)

  const handlePrevNextClick = (addNumer) => {
    const eventName = `JOBS_${active_route.toUpperCase()}_VIEW_DETAILS_${addNumer > 0 ? 'NEXT' : 'PREVIOUS'}`;
    trackEvent(eventName);

    const nextMatch = active_matches[activeCandidateIndex + addNumer];
    history.push(`${urlPrefix}/jobs/${jobId}/${active_route}/${nextMatch.id}`);
  }

  return (<Box display="flex" flexDirection="column" justifyContent="center">

    <Box display="grid" gridTemplateColumns="1fr 2fr" mt={4}>
      <Link
        to={`${urlPrefix}/jobs/${jobId}/${active_route}`}
        underline="always"
        className={cn(layoutStyles.link, layoutStyles.linkSize)}
      >&lt; {intl.formatMessage({ id: `candidates.details.${helpers.backToLinkDict[active_route]}` })}</Link>
      <div className={styles.candidateDetailsButtons}>
        <Button
          disabled={active_matches.length === 0 || activeCandidateIndex === 0}
          color="primary"
          variant="contained"
          onClick={() => handlePrevNextClick(-1)}
        >{intl.formatMessage({ id: isMobile ? 'candidates.details.previous' : 'candidates.details.previousCandidate' })}</Button>
        <Button
          disabled={active_matches.length === 0 || activeCandidateIndex === active_matches.length - 1}
          color="primary"
          variant="contained"
          onClick={() => handlePrevNextClick(1)}
        >{intl.formatMessage({ id: isMobile ? 'candidates.details.next' : 'candidates.details.nextCandidate' })}</Button>
      </div>
    </Box>
    <Box mb={2} mt={3} display="flex" justifyContent="flex-start">
      <Typography variant="h1" component="h1" >
        {intl.formatMessage({ id: 'candidates.details.candidateDetails' })}
      </Typography>
    </Box>

  </Box>)
}

export default CandidateHeader;