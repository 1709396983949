import { apiRequest } from "./config";

const JOBS_ENDPOINT = '/users/me/jobs'

export const getAll = () => {
  return new Promise((resolve, reject) => {
    apiRequest("get", JOBS_ENDPOINT)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const get = (job_id) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${JOBS_ENDPOINT}/${job_id}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const create = (value) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", JOBS_ENDPOINT, { data: value }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const update = (job_id, values) => {
  return new Promise((resolve, reject) => {
    apiRequest("put", `${JOBS_ENDPOINT}/${job_id}`, { data: { ...values, job_id } }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const archive = (job_id) => {
  return apiRequest("delete", `${JOBS_ENDPOINT}/${job_id}`);
}