import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState: {
		needsUserOnboarding: false,
		contactUpdate: false,
		needsJobsOnboarding: false,
		onboardingMaxStep: 0,
		loadingStepMessage: '',
		newJobId: '',
		combinedSkills: [], //?
		autocompleteSkills: [], //?
	},
	reducers: {
		//manage user onboarding flow
		setNeedsUserOnboarding: (state, action) => {
			state.needsUserOnboarding = action.payload;
		},
		setNeedsJobsOnboarding: (state, action) => {
			state.needsJobsOnboarding = action.payload;
		},
		setLoadingStepMessage: (state, action) => {
			state.loadingStepMessage = action.payload;
		},
		setOnboardingMaxStep: (state, action) => {
			state.onboardingMaxStep = action.payload;
		},
		//handle createJob success response
		setNewJobId: (state, { payload }) => {
			if (Array.isArray(payload) && payload.length === 1) {
				state.newJobId = payload[0].id;
			} else {
				console.log('unexpected job id payload', payload)
			}
		},
		clearNewJobId: (state) => {
			state.newJobId = '';
		},
		setCombinedSkills: (state, action) => { //used in the form
			state.combinedSkills = action.payload;
		},
		setAutocompleteSkills: (state, action) => { //used by "skillsAutocomplete"
			state.autocompleteSkills = action.payload;
		},
		setContactUpdate: (state, action) => {
      state.contactUpdate = action.payload;
    },
	}
});

// Action creators are generated for each case reducer function
export const {
	setNeedsUserOnboarding,
	setNeedsJobsOnboarding,
	setLoadingStepMessage,
	setOnboardingMaxStep,
	setCombinedSkills,
	setContactUpdate,
	setAutocompleteSkills,
	setNewJobId,
	clearNewJobId,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
