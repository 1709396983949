/* i18n */
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";

/* Styles */
import stepperStyles from 'styles/stepper.module.scss';
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import CheckIcon from '@material-ui/icons/Check';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { trackEvent } from 'lib/analytics';

/* Redux */
import { useSelector } from 'react-redux';

function StepIcon(props) {
  const { active, completed } = props;
  return (
    <div
      className={cn(stepperStyles.root, {
        [stepperStyles.active]: active,
        [stepperStyles.completed]: completed,
      })}
    >
      {completed && <CheckIcon />}
    </div>
  );
}

const OnboardingSteps =({ activeStep }) => {
  const intl = useIntl();
  const history = useHistory();

  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const matches = useMediaQuery('(max-width:671px)');

  const { onboardingMaxStep, loadingStepMessage } = useSelector((state) => state.onboarding);

  const handleLabelClick = (index) => {
    if (loadingStepMessage.length > 0 || index > onboardingMaxStep) {
      return false;
    }
    const routes = [
      'signup', 'job', 'skills'
    ];
    const eventsDict = [
      'ONBOARDING_NAV_SIGNUP',
      'ONBOARDING_NAV_JD',
      'ONBOARDING_NAV_REQ_SKILLS'
    ]
    trackEvent(eventsDict[index])
    history.push(`${urlPrefix}/onboarding/${routes[index]}`);
  }

  const steps = [
    intl.formatMessage(
      {id:'onboarding.steps.signUp'}
    ),
    intl.formatMessage(
      {id:'onboarding.steps.job'}
    ),
    intl.formatMessage(
      {id:'onboarding.steps.skills'}
    )
  ];

  return(
    <div className={stepperStyles.stepperContainer__Onboarding}>
      <Stepper
        classes={{
          root: stepperStyles.onboardingRoot
        }}
        alternativeLabel={matches}
        activeStep={activeStep}
        connector={(
          <StepConnector classes={{
            root: stepperStyles.connectorRoot,
            line: stepperStyles.connectorLineThin
          }}/>
      )}>
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => handleLabelClick(index)}
          >
            <StepLabel
              StepIconComponent={StepIcon}
              classes={{
                label: stepperStyles.stepLabelOnboarding
              }}
            >{label}</StepLabel>
            </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default OnboardingSteps;