import { useEffect } from 'react';
/* Styles */
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from "timeme.js";

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from "@material-ui/core";

const TermsOfUse = ({locale}) => {

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName("TERMSOFUSE");
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Terms of Use')
    };
  }, []);

  return(
    <>
      <div className={layoutStyles.content}>
        <Container maxWidth="lg">
          <Typography variant="h1" gutterBottom>
            TERMS OF USE
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            These Terms of Use (these “Terms”) are posted by the State of Hawai’i Department of Labor and Industrial Relations (“DLIR,” “we,” “us,” or “our”) and govern your use of the employer portal (the “Portal”), Ready Hire HI.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            The Portal and Ready Hire HI may be referred to collectively as the “Services.”
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            These Terms also include any other disclosures or disclaimers related to the Services which are presented to you in the Portal or in conjunction with the Services.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            The purpose of the Portal is to provide employers (“you” or “your”) with access to potential job candidates who have registered with Hawai’i’s Career Acceleration Navigator program (“HI CAN”) and/or consenting Unemployment Insurance (UI) claimants.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Ready Hire HI should not, however, be used as substitute for your own internal hiring and vetting processes, including without limitation your further review of a candidate’s qualifications, interviews of a candidate, and background checks. While we aim to help you more efficiently and effectively locate potential candidates, DLIR is not responsible for the ultimate quality of a candidate or a hired candidate’s eventual job performance.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Please note that the Portal is intended for potential employers only. If you are an individual seeking employment, your use of HI CAN is governed separately by the HI CAN terms and conditions.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING THE SERVICES. BY ACCESSING, USING, OR PARTICIPATING IN THE SERVICES YOU EXPRESSLY AGREE TO THESE TERMS.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Ownership and License
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            We grant you a limited, revocable, non-transferable, non-exclusive license to access and use the Services in accordance with these Terms. Any account you create on the Portal is for your business not your personal use.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You acknowledge and agree that we and/or our partners, licensors, and contractors maintain all right, title, and interest in and to the Services (and all text, images, trademarks, logos, and other content thereon), and except for the limited license right above nothing in these Terms shall be construed as granting you any proprietary or license rights in or to the Services or anything contained therein.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You may use the Services only in compliance with these Terms. You are responsible for your own communications, including the transmission, uploading, or posting of information on or through the Portal, and are responsible for the consequences of such communications.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Your Job Postings
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Any job posting provided by you may not contain: 
          <ul>
            <li>the names, logos or trademarks of unaffiliated companies;</li>
            <li>material or links to material that may be deemed sexually exploitative or violent, or solicits personal information from anyone under 18; or</li>
            <li>inaccurate, false, or misleading information.</li>
          </ul>
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You may not use your Portal account or any job posting to:
          <ul>
            <li>seek candidates in a manner that does not comply with applicable local, national and international laws, including but not limited to laws relating to labor and employment, equal employment opportunity and employment eligibility requirements, data privacy, data access and use, and intellectual property;</li>
            <li>determine an individual’s eligibility for credit or insurance for person, family, or household purposes;</li>
            <li>sell, promote or advertise products or services;</li>
            <li>post any franchise, pyramid scheme, distributorship, multi-level marketing opportunity, or sales representative agency arrangement;</li>
            <li>post any business opportunity that requires any payment from the candidate;</li>
            <li>post any business opportunity that pays commission, unless the posting clearly states that the available job pays commission only and clearly describes the product or service that the job seeker would be selling;</li>
            <li>promote any opportunity that does not represent bona fide employment which is generally indicated by the employer’s use of IRS forms W-2 or 1099;</li>
            <li>advertise sexual services or seek employees for jobs of a sexual nature;</li>
            <li>request the use of human body parts or the donation of human parts, including, without limitation, reproductive services such as egg donation and surrogacy;</li>
            <li>endorse a political party, political agenda, political position or issue;</li>
            <li>promote a religion;</li>
            <li>post jobs located in countries subject to economic sanctions of the United States Government; or</li>
            <li>except where allowed by applicable law, post jobs which require the applicant to provide information relating to his/her (i) racial or ethnic origin, (ii) political beliefs, (iii) philosophical or religious beliefs, (iv) membership of a trade union, (v) physical or mental health (including pregnancy status), (vi) sexual life, (vii) the commission of criminal offences or proceedings, or (viii) age.</li>
          </ul>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
          Other Restrictions
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
          Without limiting the foregoing, you agree you will not:
          <ul>
            <li>use spiders, robots, or other automated techniques to catalog, download, store, or otherwise reproduce or distribute data or content available through the Services;</li>
            <li>take any action to interfere with the Services;</li>
            <li>collect any personal information of any other user of the Services (except for any personal information about potential employees provided to you in connection with the Services);</li>
            <li>send or transmit any viruses, corrupted data, or any other harmful, disruptive, or destructive code, file, or information, including without limitation any spyware;</li>
            <li>send unsolicited emails, including without limitation promotions or advertising of products or services;</li>
            <li>post or transmit any threatening, libelous, defamatory, obscene, lewd, scandalous, or inflammatory material or content or any material or content that could otherwise violate applicable laws;</li>
            <li>impersonate any person or entity, misrepresent your affiliation with a person or entity, or in any way forge or mask your true identity;</li>
            <li>reverse engineer any aspect of the Services or take any act that might reveal or disclose any source code, or bypass or circumvent measurers or controls utilized to prohibit, restrict or limit access to any webpage, content or code;</li>
            <li>engage in any criminal or illegal acts or in any activities that may violate a third party’s privacy or publicity rights; or</li>
            <li>encourage and/or advise any individual to commit any act prohibited by these Terms.</li>
          </ul>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
          Termination of Your Account
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Without limiting any of our other rights herein, you specifically acknowledge and agree that, in the event your Portal account becomes compromised, or if DLIR determines, in its sole and absolute discretion, that you have violated these Terms, are not a legitimate employer, or are otherwise using the Portal for illegitimate purposes, we reserve the right to delete your account, remove you from the Portal, and communicate such information to any HI CAN users who have previously communicated with you.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Submitted Content
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            You acknowledge and agree that all information, data, text, software, music, sound, photographs, graphics, video, advertisements, messages or other materials submitted, posted or displayed by you on or through the Portal (“Employer Content”) is your sole responsibility. DLIR claims no ownership or control over any Employer Content. You or a third party licensor, as appropriate, retain all patent, trademark and copyright to any Employer Content you submit, post or display on or through the Portal and you are responsible for protecting those rights, as appropriate.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            By submitting, posting or displaying Employer Content on or through the Portal, you grant DLIR a worldwide, non-exclusive, royalty-free, transferable, sub-licensable license to use, reproduce, adapt, distribute and publish such Employer Content in connection with HI CAN and ReadyHire HI.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            In addition, by submitting, posting or displaying Employer Content which is intended to be available to the general public, you grant DLIR a worldwide, non-exclusive, royalty-free license to reproduce, adapt, distribute and publish such Employer Content for the purpose of promoting DLIR and its services. DLIR reserves the right to refuse to accept, post, display or transmit any Employer Content in its sole discretion.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You also represent and warrant that you have the right to grant the licenses stated above. If you post Employer Content in any public area of the Portal, you also permit any other user to access, display, view, store and reproduce such Employer Content for personal use.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            DLIR may review and remove any Employer Content that, in its sole judgment, violates these Terms, violates applicable laws, rules or regulations, is abusive, disruptive, offensive or illegal, or violates the rights, or harms or threatens the safety, of other users of HI CAN, the Portal or other DLIR services.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Security and Confidentiality
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            In connection with your use of the Services, you may have the opportunity to review or access confidential and proprietary information, materials, products, and content belonging to other users of the Services or belonging to DLIR or partners, licensors, or contractors (collectively, “<u>Confidential Information</u>”). Confidential Information is and shall remain the sole and exclusive property of its owner. In no event shall you obtain any right, title, or interest in or to any Confidential Information. You agree to protect the confidentiality and secrecy of the Confidential Information. You agree not to modify, copy, reproduce, republish, display, transmit, distribute, reverse engineer, create derivative works of, decompile, or otherwise use, alter, or transfer Confidential Information without the prior express written consent of DLIR. You acknowledge and agree that Confidential Information may be subject to and protected by intellectual property laws, regulations, and codes.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            All communications from you related to the Services, including without limitation content submitted or transmitted by you to DLIR, by electronic mail or otherwise, shall be treated as non-confidential and non-proprietary information, unless specifically indicated by you either prior to, or contemporaneously with, the submission or transmission of such communications and content.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Using the Services may require the use of a username and password or other form of authentication where appropriate. The confidentiality of the access credentials is your responsibility. You agree to notify DLIR immediately of any unauthorized use of accounts or any other breach of confidentiality or security. The use of another person’s access credentials is expressly prohibited.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            You acknowledge that the use of the implemented authentication methods is an adequate form of security. DLIR is not liable for any harm related to the misuse or theft of access credentials, disclosure of access credentials, or your authorization to allow another person or entity to access and use the Services using your access credentials. You will be held liable for any harm ensuing from the use of your account in connection with the Services.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Communication Through the Portal
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            You acknowledge and agree that you may receive messages or email notifications relating to your use of the Portal. DLIR disclaims all warranties with regards to the transmission or storage of such notifications, does not guarantee their delivery or receipt, and does not guarantee the date or time at which they may be sent.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Once a potential candidate has your contact information, you agree that DLIR is not responsible or liable for the manner in which the candidate uses the information.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            If you send or receive communication on or through the Portal, you agree to your communication being processed, analyzed, reviewed, and stored, including via automated means, for DLIR’s or our partners’ or contractors’ own internal business purposes.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Third-Party Websites
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            In connection with your use of the Services, you may be able to voluntarily connect to websites maintained or operated by third parties (“Third-Party Websites”). DLIR does not make any representations or warranties regarding or endorse any Third Party Website or any products, services, or opportunities advertised, offered, or sold in connection with any Third Party Website. Please carefully review all policies and terms applicable to the Third Party Websites.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Waiver and Release
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            The Portal is a venue for employers to post job opportunities and search for and evaluate job candidates. DLIR is not involved in, and does not control, the actual transaction between employers and candidates. As a result, DLIR is not responsible for the quality, safety or legality of the candidates.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            DLIR does not screen or censor the candidates, and is not involved in the actual communications between candidates and potential employers. Please note that there are risks, including but not limited to the risk of physical harm, inherent in dealing with strangers, underage persons or people acting under false pretenses. You assume all risks associated with using the Services.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            By registering for the Portal or otherwise using the Services, you hereby fully and forever waive, release, acquit, and discharge DLIR and any other party involved in creating, producing or delivering the Services, including without limitation any of DLIR’s licensors or contractors (collectively, the “<u>DLIR Parties</u>”), including their respective managers, officers, directors, employees and agents, from any and all claims, causes of action, demands, liabilities, damages of any kind, obligations, costs, expenses, and debts, of every kind and nature whatsoever, whether based on statute, tort, contract, common law, or other theory of recovery, which may arise, now or at any time in the future, out of or in connection with your participation in the Services.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            DISCLAIMER
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. NEITHER DLIR NOR ANY DLIR PARTY MAKES ANY REPRESENTATIONS: (1) ABOUT THE SUITABILITY OF THE SERVICES FOR ANY PURPOSE; (2) THAT YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; (3) THAT THE SERVICES, OR ANY THIRD-PARTY WEBSITES, WILL MEET YOUR REQUIREMENTS; OR (4) THAT THE SERVICES WILL BE TIMELY OR SECURE.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            FURTHERMORE, DLIR AND DLIR PARTIES DO NOT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE SERVICES.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            DLIR AND DLIR PARTIES DO NOT MAKE ANY REPRESENTATION OR WARRANTY REGARDING THE QUALITY OF ANY JOB CANDIDATE, THEIR FITNESS FOR WORK OR A PARTICULAR POSITION, THEIR MORAL CHARACTER, OR THEIR PRIOR LEGAL OR WORK HISTORY. WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY AGREE THAT DLIR AND DLIR PARTIES SHALL NOT BE LIABLE UNDER ANY NEGLIGENT REFERRAL OR SIMILAR THEORY RELATED TO YOUR HIRE OF A JOB CANDIDATE THROUGH THE PORTAL.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            LIMITATION OF LIABILITY
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, YOU ACKNOWLEDGE AND AGREE THAT IN NO EVENT SHALL DLIR OR ANY DLIR PARTIES BE LIABLE FOR ANY CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, OR SPECIAL DAMAGES, OR OTHERWISE FOR ANY DAMAGES ARISING OUT OF, OR IN ANY WAY CONNECTED WITH: (1) YOUR USE OR THE INABILITY TO USE THE SERVICES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES; (3) YOUR HIRE OF ANY JOB CANDIDATE, OR ANY OTHER TRANSACTIONS ENTERED INTO, BY VIRTUE OF THE PORTAL; OR (4) THE UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT, OR OTHER THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER DLIR WAS GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            IN THE EVENT DLIR OR ANY DLIR PARTIES BEAR LIABILITY, THE LIABILITY WILL BE LIMITED TO THE MAXIMUM AMOUNT PAID BY YOU, IF ANY, FOR USING THE SERVICES IN THE TWELVE (12) MONTHS PRIOR TO THE EVENTS GIVING RISE TO THE ALLEGED LIABILITY OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS GREATER.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Compliance with Applicable Laws
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            You acknowledge and agree that you will comply with all applicable international, national, federal, state, and local laws, codes, regulations, rules, and requirements which apply to your use of, participation in, and access to the Services, including without limitation laws relating to labor and employment, equal employment opportunity and employment eligibility requirements, data privacy, data access and use, and intellectual property.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Indemnification
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            You agree to indemnify, defend, and hold harmless DLIR and all DLIR Parties, as well as their directors, officers, employees, agents, successors and assigns, from and against all liabilities, losses, damages and costs, including reasonable attorneys’ fees, they may suffer as the result of third-party claims, demands, actions, suits or judgments against them resulting from or arising out of: (a) your violation of these Terms or applicable laws or regulations; or (b) your negligence or willful misconduct.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Governing Law and Venue
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Venue for any dispute hereunder shall be the state and federal courts in the State of Hawai’i. These Terms shall be governed and enforced by applicable laws of the State of Hawaii, without regard to conflict of law provisions thereof.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            No Assignment
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Neither party may assign their rights or obligations under these Terms without the prior written consent of the other party.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Changes to the Services and these Terms
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            We reserve the right, in our sole discretion, to change, suspend, add to, or discontinue any aspect of the Services, and we will not be liable to you or to any third party for doing so. Your continued use of the Services will constitute your acceptance of any such changes.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            We also reserve the right, in our sole discretion, to make changes to these Terms. Such changes will become effective upon their posting. Accordingly, DLIR encourages you to review these Terms on an ongoing basis.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Miscellaneous
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            The headings contained in these Terms are for reference only and shall have no effect on the interpretation or application of these Terms. DLIR’s failure to enforce a breach by you of these Terms shall not waive or release you from such breach and shall not waive, release, or prevent DLIR from enforcing any subsequent breach by you of these Terms.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            If any term or provision of these Terms shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction, such term or provision shall be deemed null and void and shall not affect the application and/or interpretation of these Terms. The remaining terms or provisions of these Terms shall continue in full force and effect, as if the invalid or unenforceable term or provision was not a part of these Terms.
          </Typography>
        </Container>
      </div>
    </>
  )
}

export default TermsOfUse;