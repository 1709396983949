import { useEffect } from 'react';
import { Link } from "react-router-dom";
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Form */
import JobForm from 'components/forms/job';

/* Material UI and other UI Dependencies */
import {
  Box,
  Typography,
  Container,
  Divider
} from "@material-ui/core";


/* Misc */
import TimeMe from "timeme.js";

const JobsNew = () => {

  // const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName("NEW_JOB");
    TimeMe.startTimer();
    return () => {
      // Leaving new job
    };
  }, []);

  return (
    <div className={styles.jobsMatchesWrapper}>
      <div className={dashboardStyles.content}> {/* this class isn't rendering  */}
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="flex-start">
            <Link
              to={`${urlPrefix}/jobs`}
              underline="always"
              className={cn(layoutStyles.link, layoutStyles.headingSize, layoutStyles.sourceSerif)}
            >
              {intl.formatMessage({ id: 'jobs.jobs' })}
            </Link>
            <Typography variant="h1" component="h2" >
              &nbsp; &gt; {intl.formatMessage({ id: 'jobs.new' })}
            </Typography>
          </Box>
          <Box mb={2} className={styles.matchesHeaderContent}>
            {/* no Job Nav & Box has mb 2 instead of 3 */}
          </Box>
          <Divider />
          <Box marginTop={3} marginBottom={5}>
            <JobForm.AddNew />
          </Box>
        </Container >
      </div >
    </div >
  )
}

export default JobsNew;
