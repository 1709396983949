import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    form: {
      soft_skills: '',
      company_description: '',
      company_name: '',
      contact_name: '',
      phone_number: '',
      zipcode: '',
      fein: '',
      naics_code: '',
      naics_title: '',
      naics_query: '',
      secondary_naics_code: '',
      secondary_naics_title: '',
      secondary_naics_query: '',
      tos: false,
      contact_email: ''
    }    
  },
  reducers: {
    setCompanyForm: (state, action) => {
      state.form = {...state.form, ...action.payload};
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCompanyForm } = companySlice.actions;

export default companySlice.reducer;