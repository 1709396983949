import { createSlice } from '@reduxjs/toolkit';

const updateProfileProgress = (updatedProfileState) => {
  const allPostableFields = [
    'name',
    'contact',
    'email',
    // 'fein',
    'soft_skills',
    'zip_code',
    'primary_industry_code',
    'secondary_industry_code',
    'description'
  ];
  const isFieldCompleted = ([fieldName, val]) => (val !== '' && allPostableFields.includes(fieldName));

  const completedFields = (Object.entries(updatedProfileState)).filter(isFieldCompleted);

  if (completedFields.length > 0) {
    return `${(Math.round((completedFields.length / allPostableFields.length) * 100))}%`
  } else {
    return '0%';
  }
}

export const employerSlice = createSlice({
  name: 'employer',
  initialState: {
    name: '',
    description: '',
    contact: '',
    email: '',
    phone_number: '',
    fein: '',
    soft_skills: '',
    zip_code: '',
    primary_industry_code: '',
    secondary_industry_code: '',
    // fein: '',
    
    // naics_title: '',
    // naics_query: '',
    // secondary_naics_title: '',
    // secondary_naics_query: '',

    profileCompletion: '0%',
  },
  reducers: {
    setEmployer: (state, { payload }) => {
      const newState = { ...state, ...payload };
      return {
        ...newState,
        profileCompletion: updateProfileProgress(newState)
      };
    }
  },
})

// Action creators are generated for each case reducer function
export const { setEmployer } = employerSlice.actions;

export default employerSlice.reducer;