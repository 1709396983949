import { configureStore } from '@reduxjs/toolkit';
import appSlice from 'app/slices/appSlice';
import companySlice from 'app/slices/companySlice';
import employerSlice from './slices/employerSlice';
import jobsSlice from 'app/slices/jobsSlice';
import currentJobSlice from 'app/slices/currentJobSlice';
import onboardingSlice from 'app/slices/onboardingSlice';
import candidateSlice from './slices/candidateSlice';

export default configureStore({
	reducer: {
		app: appSlice,
		company: companySlice,
		employer: employerSlice,
		jobs: jobsSlice,
		currentJob: currentJobSlice,
		onboarding: onboardingSlice,
		candidates: candidateSlice
	}
});
