import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/* Styles */
import cn from 'classnames';
import styles from 'styles/candidates.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setFromDetails, setActiveTabBeforeDetails } from 'app/slices/appSlice';
import { setSelectedCandidates } from 'app/slices/candidateSlice';
import { toggleJobCategory, setMinYearsExperienceFilter, setMinEducationLevelFilter } from 'app/slices/jobsSlice';

/* Material UI and other UI Dependencies */
import { useMediaQuery } from '@material-ui/core';

/* Assets */
import GreenCheck from 'assets/images/GreenCheck.svg';
import YellowStop from 'assets/images/YellowStop.svg';
import WhiteCheck from 'assets/images/WhiteCheck.svg';
import Question from 'assets/images/Question.svg';
import ArrowDown from 'assets/images/Arrow_Dropdown.svg';
import Plane from 'assets/images/Plane';

/* Helper */
import { difference } from 'ramda';
import { getEmployerDataByCategory, sortCandidatesByMatchRating, SORT_ORDERS } from './helper';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Components */
import SkillsExplanation from './SkillsExplanation';
import Tabs from './Tabs';
import TableHeader from './TableHeader';
import Category from './Category';
import NoMatches from './NoMatches';
import CategoryMobile from './CategoryMobile';
import CandidateRow from './CandidateRow';
import Loading from 'components/Loading';

const CandidatesTable = ({
  tabs = true,
  candidatesSubmitted = false, // State to indicate if the request to submit candidates was completed
  matchingCandidates = [],
  submittedCandidates = false, // Candidates are being submitted
  submittedPage = false,
  savedCandidates = [], // Candidates when we are showing saved candidates table
  savedPage = false,
  removedCandidates = [], // Candidates when we are showing removed candidates table
  removedPage = false,
  setModalOpen = () => {},
  job: { archived, selected_skills = [] },
  setAboveActiveTab = () => {},
  fetching = false,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const { id: jobId } = useParams();
  const { jobs, min_years_experience_filter, min_education_level_filter } = useSelector((state) => state.jobs);
  const jobIndex = useSelector((state) => state.jobs.jobIndex);
  const { selected_candidates, submittingCandidates } = useSelector((state) => state.candidates);
  const job = jobIndex.hasOwnProperty(jobId) ? jobs[jobIndex[jobId]] : {};

  const [activeTab, setActiveTab] = useState(0);
  const [sortBy, setSortBy] = useState(SORT_ORDERS.LARGEST);

  const { fromDetails, activeTabBeforeDetails } = useSelector((state) => state.app);
  const showMobile = useMediaQuery('(max-width:799px)');

  //filters through userSelectedCandidates prop for any with this job id
  //and sets the list to redux
  const toggleCandidateSelected = (id) => {
    const candidateIndex = selected_candidates.indexOf(id);
    if (candidateIndex > -1) {
      dispatch(setSelectedCandidates(difference(selected_candidates, [id])));
    } else {
      dispatch(setSelectedCandidates(selected_candidates.concat(id)));
    }
  };

  //sorts all three filtered lists of matches on every render
  const sortingOptions = [
    { value: SORT_ORDERS.LARGEST, title: intl.formatMessage({ id: 'jobs.matches.sort.largest' }) },
    { value: SORT_ORDERS.SMALLEST, title: intl.formatMessage({ id: 'jobs.matches.sort.smallest' }) },
  ];
  const handleSortingChange = ({ target: { value } }) => {
    setSortBy(value);
  };

  const matching_candidates = sortCandidatesByMatchRating(matchingCandidates, sortBy);
  const submitted_candidates = submittedPage ? sortCandidatesByMatchRating(submittedCandidates, sortBy) : [];
  const saved_candidates = savedPage ? sortCandidatesByMatchRating(savedCandidates, sortBy) : [];
  const removed_candidates = removedPage ? sortCandidatesByMatchRating(removedCandidates, sortBy) : [];

  //splits the matching_candidates list into top/potential/trainable on every render
  const topCandidates = matching_candidates.filter((mc) => mc['candidate_id.soc_title'] && mc.match_score >= 50);
  const topCount = topCandidates.length;
  const potentialCandidates = matching_candidates.filter(
    (mc) => mc['candidate_id.soc_title'] && mc.match_score >= 25 && mc.match_score < 50
  );
  const potentialCount = potentialCandidates.length;
  const trainableCandidates = matching_candidates.filter((mc) => mc['candidate_id.soc_title'] && mc.match_score < 25);
  const trainableCount = trainableCandidates.length;

  useEffect(() => {
    if (submittedPage || savedPage || removedPage) {
      return;
    }
    if (fromDetails) {
      dispatch(setFromDetails(false));
      return setActiveTab(activeTabBeforeDetails);
    }
    //recalculates top vs potential vs trainable from matchingCandidates prop on initial load
    //saves active tab to redux and also to component state..
    const unsortedMatches = [...matchingCandidates];
    if (activeTab === 0) {
      const unsortedTop = unsortedMatches.filter((mc) => mc.match_score >= 50);
      if (!unsortedTop.length) {
        const unsortedPotential = unsortedMatches.filter((mc) => mc.match_score >= 25 && mc.match_score < 50);
        if (unsortedPotential.length) {
          dispatch(setActiveTabBeforeDetails(1));
          return setActiveTab(1);
        } else {
          const unsortedTrainable = unsortedMatches.filter((mc) => mc.match_score < 25);
          if (unsortedTrainable.length) {
            dispatch(setActiveTabBeforeDetails(2));
            return setActiveTab(2);
          }
        }
      }
    }
    if (activeTab === 1) {
      const unsortedPotential = unsortedMatches.filter((mc) => mc.match_score >= 25 && mc.match_score < 50);
      const unsortedTrainable = unsortedMatches.filter((mc) => mc.match_score < 25);
      if (!unsortedPotential.length && unsortedTrainable.length) {
        dispatch(setActiveTabBeforeDetails(2));
        setActiveTab(2);
      }
    }
  }, []);

  //decides which of those three lists to show based on which tab is active
  let matchesToShow = [];
  let detailsPath = 'matches';
  if (activeTab === 0) matchesToShow = topCandidates;
  if (activeTab === 1) matchesToShow = potentialCandidates;
  if (activeTab === 2) matchesToShow = trainableCandidates;
  if (submittedPage) {
    matchesToShow = submitted_candidates;
    detailsPath = 'submitted';
  }
  if (savedPage) {
    matchesToShow = saved_candidates;
    detailsPath = 'saved';
  }
  if (removedPage) {
    matchesToShow = removed_candidates;
    detailsPath = 'removed';
  }

  /* This is where we regroup our matchesToShow data by soc_title */
  const groupedByCategory = getEmployerDataByCategory(matchesToShow);
  const categories = Object.keys(groupedByCategory);

  const matchesTypes = ['top', 'potential', 'trainable'];
  const otherTabs = difference(matchesTypes, [matchesTypes[activeTab]]);

  const handleOpenTopTooltip = () => {
    const eventName = 'JOBS_MATCHES_TOP_QUESTION';
    trackEvent(eventName);
  };

  const handleOpenPotentialTooltip = () => {
    const eventName = 'JOBS_MATCHES_POTENTIAL_QUESTION';
    trackEvent(eventName);
  };

  const handleOpenTrainabelTooltip = () => {
    const eventName = 'JOBS_MATCHES_TRAINABLE_QUESTION';
    trackEvent(eventName);
  };

  const handleTopLinkClick = () => {
    if (['matches'].indexOf(detailsPath) > -1) {
      const eventName = `JOBS_${detailsPath.toUpperCase()}_${matchesTypes[activeTab].toUpperCase()}_NO_CANDIDATE_TOP`;
      trackEvent(eventName);
    }
    dispatch(setActiveTabBeforeDetails(0));
    setActiveTab(0);
    setAboveActiveTab(0);
  };

  const handlePotentialLinkClick = () => {
    if (['matches'].indexOf(detailsPath) > -1) {
      const eventName = `JOBS_${detailsPath.toUpperCase()}_${matchesTypes[
        activeTab
      ].toUpperCase()}_NO_CANDIDATE_POTENTIAL`;
      trackEvent(eventName);
    }
    dispatch(setActiveTabBeforeDetails(1));
    setActiveTab(1);
    setAboveActiveTab(1);
  };

  const handleTrainableLinkClick = () => {
    if (['matches'].indexOf(detailsPath) > -1) {
      const eventName = `JOBS_${detailsPath.toUpperCase()}_${matchesTypes[
        activeTab
      ].toUpperCase()}_NO_CANDIDATE_TRAINABLE`;
      trackEvent(eventName);
    }
    dispatch(setActiveTabBeforeDetails(2));
    setActiveTab(2);
    setAboveActiveTab(2);
  };

  const handleEditSkillsClick = () => {
    if (['matches'].indexOf(detailsPath) > -1) {
      const eventName = `JOBS_${detailsPath.toUpperCase()}_${matchesTypes[
        activeTab
      ].toUpperCase()}_NO_CANDIDATE_ADD_REMOVE_SKILLS`;
      trackEvent(eventName);
    }
    setModalOpen(true);
  };

  const handleViewDetailsClick = () => {
    const eventName = `JOBS_${detailsPath.toUpperCase()}_VIEW_DETAILS`;
    trackEvent(eventName);
  };

  const handleToggleSwitchClick = (event, skillName, index) => {
    const matchesTab = detailsPath === 'matches' ? `_${matchesTypes[activeTab].toUpperCase()}` : '';
    const eventName = `JOBS_${detailsPath.toUpperCase()}${matchesTab}_SKILLS_TOGGLE_${index + 1}`;

    if (skillName) {
      trackEvent(eventName);
      dispatch(toggleJobCategory({ job_id: job.id, category_id: skillName }));
    }
  };

  return (
    <>
      {/* All tabs */}
      {tabs ? (
        <Tabs
          reloading={fetching}
          setActiveTab={setActiveTab}
          setActiveTabBeforeDetails={setActiveTabBeforeDetails}
          setAboveActiveTab={setAboveActiveTab}
          activeTab={activeTab}
          showMobile={showMobile}
          topCount={topCount}
          handleOpenTopTooltip={handleOpenTopTooltip}
          Question={Question}
          potentialCount={potentialCount}
          handleOpenPotentialTooltip={handleOpenPotentialTooltip}
          trainableCount={trainableCount}
          handleOpenTrainabelTooltip={handleOpenTrainabelTooltip}
        />
      ) : null}

      {/* Table with data starts here */}
      {!showMobile ? (
        <div
          className={cn({
            [styles.table]: true,
            [styles.table__NoShadow]: !tabs,
          })}
        >
          {/* Table header and titles */}
          <TableHeader
            reloading={fetching}
            sortBy={sortBy}
            handleSortingChange={handleSortingChange}
            detailsPath={detailsPath}
            ArrowDown={ArrowDown}
            sortingOptions={sortingOptions}
            selected_skills={selected_skills}
            submittedPage={submittedPage}
            archived={archived}
            handleToggleSwitchClick={handleToggleSwitchClick}
            job={job}
            setFilterByMinEducation={(val) => dispatch(setMinEducationLevelFilter(val))}
            setFilterByMinExperience={(val) => dispatch(setMinYearsExperienceFilter(val))}
            filterByMinEducation={min_education_level_filter}
            filterByMinExperience={min_years_experience_filter}
          />

          {/* Here we have the actual data with rows of matches */}
          {fetching ? (
            <Loading.Icon />
          ) : matchesToShow.length > 0 ? (
            categories.length > 1 ? (
              categories.map((category) => {
                return (
                  <Category
                    key={category}
                    category={category}
                    groupedByCategory={groupedByCategory[category]}
                    job={job}
                    mc={''}
                    candidatesSubmitted={candidatesSubmitted}
                    submittedPage={submittedPage}
                    Plane={Plane}
                    archived={archived}
                    toggleCandidateSelected={toggleCandidateSelected}
                    urlPrefix={urlPrefix}
                    jobId={jobId}
                    detailsPath={detailsPath}
                    handleViewDetailsClick={handleViewDetailsClick}
                    selected_skills={selected_skills}
                    showMobile={showMobile}
                  />
                );
              })
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {matchesToShow.map((groupedData) => (
                  <CandidateRow
                    key={groupedData.id}
                    job={job}
                    mc={groupedData}
                    candidatesSubmitted={candidatesSubmitted}
                    userSelectedCandidates={selected_candidates}
                    submittedPage={submittedPage}
                    Plane={Plane}
                    archived={archived}
                    toggleCandidateSelected={toggleCandidateSelected}
                    urlPrefix={urlPrefix}
                    jobId={jobId}
                    detailsPath={detailsPath}
                    handleViewDetailsClick={handleViewDetailsClick}
                    selected_skills={selected_skills}
                  />
                ))}
              </div>
            )
          ) : (
            <>
              <NoMatches
                savedPage={savedPage}
                removedPage={removedPage}
                submittedPage={submittedPage}
                matchesTypes={matchesTypes}
                activeTab={activeTab}
                handlePotentialLinkClick={handlePotentialLinkClick}
                handleTopLinkClick={handleTopLinkClick}
                otherTabs={otherTabs}
                handleTrainableLinkClick={handleTrainableLinkClick}
                handleEditSkillsClick={handleEditSkillsClick}
                urlPrefix={urlPrefix}
                jobId={jobId}
              />
            </>
          )}
        </div>
      ) : (
        <>
          {categories.length > 1 &&
            categories.map((category) => {
              return (
                <CategoryMobile
                  key={category}
                  category={category}
                  groupedByCategory={groupedByCategory[category]}
                  job={job}
                  userSelectedCandidates={selected_candidates}
                  submittingCandidates={submittingCandidates}
                  submittedPage={submittedPage}
                  Plane={Plane}
                  toggleCandidateSelected={toggleCandidateSelected}
                  urlPrefix={urlPrefix}
                  jobId={jobId}
                  detailsPath={detailsPath}
                  selected_skills={selected_skills}
                  matchesTypes={matchesTypes}
                  activeTab={activeTab}
                  handlePotentialLinkClick={handlePotentialLinkClick}
                  handleTopLinkClick={handleTopLinkClick}
                  otherTabs={otherTabs}
                  handleTrainableLinkClick={handleTrainableLinkClick}
                  handleEditSkillsClick={handleEditSkillsClick}
                  matchesToShow={matchesToShow}
                />
              );
            })}
        </>
      )}

      {/* bottom skills explanation */}
      <SkillsExplanation GreenCheck={GreenCheck} WhiteCheck={WhiteCheck} YellowStop={YellowStop} />
    </>
  );
};

export default CandidatesTable;
