import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';

/* API */
import { apiHooks } from 'lib/api/index';

/* Styles */
import styles from 'styles/onboarding.module.scss';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Alert } from '@material-ui/lab';
import { Button, Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import Loading from 'components/Loading';

/* Assets */
import upload from 'assets/images/Upload.svg';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  removeButton: {
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    border: `solid 2px ${cssVars.actionBlue}`,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    fontSize: '10px',
    padding: 0,
    backgroundColor: cssVars.white,
    svg: {
      fontSize: '20.3px',
      color: cssVars.actionBlue,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: cssVars.lightGray,
    },
  },
}));

const acceptedFileTypes = {
  'text/plain': ['.txt'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
};

const JobDescriptionFileUpload = ({
  resultHandler = () => {},
  errorHandler = () => {},
  resetHandler = () => {},
  loading,
  loadingSetter = () => {},
}) => {
  const customStyles = useStyles();
  const intl = useIntl();

  //upload contents
  const [fileContents, setFileContents] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  //field status
  const [submitted, setSubmitted] = useState(false);
  const [apiError, setApiError] = useState(null);

  //form status
  const { values: formValues } = useFormikContext();
  const hasTitle = formValues.title && formValues.title.length > 0;

  //API
  const parseFile = apiHooks.upload.useParseFile({
    onSuccess: resultHandler,
    onError: (error) => {
      setApiError(error);
      errorHandler(error);
    },
  });

  const handleReset = useCallback(() => {
    setFileContents(null);
    setFileExtension(null);
    setUploadedFile(null);
    resetHandler();
    loadingSetter(false);
    setSubmitted(false);
    setApiError(null);
  }, [loadingSetter, resetHandler]);

  const handleDrop = useCallback(
    (acceptedFile) => {
      handleReset();
      try {
        const file = acceptedFile[0];

        const reader = new FileReader();
        reader.onabort = () => console.warn('file reading aborted');
        reader.onerror = () => console.error('file reading failed');
        reader.onload = () => {
          setUploadedFile(file);
          setFileContents(reader.result);
          setFileExtension(acceptedFileTypes[file.type][0]);
        };
        if (acceptedFile[0]) {
          reader.readAsDataURL(acceptedFile[0]);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [handleReset]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, maxSize } = useDropzone({
    onDrop: handleDrop,
    accept: Object.keys(acceptedFileTypes),
    minSize: 0,
    maxSize: 10000000,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    if (!submitted && fileContents !== null && fileExtension !== null) {
      //handle submit
      loadingSetter(true);
      parseFile(fileContents, fileExtension, formValues);
      setSubmitted(true);
    }
  }, [submitted, fileContents, fileExtension, formValues, loadingSetter, parseFile]);

  const wasFileRejected = !!fileRejections.length;
  const getFileUploadErrorMessage = () => {
    if (wasFileRejected) {
      console.error(fileRejections);
      const { code, message } = fileRejections[0].errors[0];
      if (code === 'file-invalid-type') {
        return `${fileRejections[0].file.name} is an unsupported file type.  Please try a text, Word, or pdf document instead.`;
      } else return message;
    } else return null;
  };

  const wasFileUploaded = !!fileContents && !wasFileRejected;

  if (wasFileUploaded) {
    return (
      <FileUploadedView fileName={uploadedFile.name} resetHandler={handleReset} loading={loading} apiError={apiError} />
    );
  } else {
    return (
      <>
        <div
          className={cn({
            [styles.dropZone]: true,
            [styles.dropZone__inactive]: !hasTitle,
            [customStyles.dragActive]: isDragActive && hasTitle,
          })}
        >
          <div
            {...getRootProps({
              disabled: !hasTitle,
            })}
          >
            <input
              {...getInputProps({
                disabled: !hasTitle,
              })}
              title={`${intl.formatMessage({
                id: 'forms.onboarding.jobDescription',
              })}`}
            />
            <img alt="Upload" src={upload} className={styles.uploadImage} />
            <Box mt={2}>
              <Typography variant="h5" component="h2" align="center" gutterBottom>
                <FormattedMessage id="fomrs.onboarding.dragDescription" />
              </Typography>
              <Typography variant="h3" align="center">
                <FormattedMessage id="forms.onboarding.recommended" />
              </Typography>
              <Typography variant="h3" align="center">
                <FormattedMessage id="forms.onboarding.filesFormat" />
              </Typography>
              <Box mt={3} align="center">
                <Button color="primary" size="large" variant="contained" disabled={!hasTitle}>
                  {intl.formatMessage({ id: 'forms.onboarding.browse' })}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
        {wasFileRejected && (
          <Alert severity="error">
            <FormattedMessage id="forms.enroll.uploadErrorTooLarge" defaultMessage={getFileUploadErrorMessage()} />
          </Alert>
        )}
      </>
    );
  }
};

export default JobDescriptionFileUpload;

export const FileUploadedView = ({ fileName, resetHandler = () => {}, apiError = false, loading = false }) => {
  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingLeft={2}
        bgcolor={!!apiError ? cssVars.lightRed : cssVars.lightGray}
        borderColor={cssVars.mediumGray}
        borderBottom={`1px solid ${cssVars.mediumGray}`}
        pt={2}
        pb={2}
      >
        <Typography component={'p'} variant="h3">
          {fileName}
        </Typography>
        <IconButton onClick={resetHandler} disabled={loading}>
          {loading ? <Loading.Inline /> : <CloseIcon />}
        </IconButton>
      </Box>
    </>
  );
};
