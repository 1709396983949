export function toJSONLocal(date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

export const padZero = (num) => {
  return num < 10 ? `0${num}` : num;
}

export const parseDate = (d = '') => {
  if (d.length === 8) {
    const d1 = d.split('');
    d1.splice(6, 0, '-');
    d1.splice(4, 0, '-')
    const date = new Date(d1.join(''));
    return date.toLocaleDateString('en-us', { year: "numeric", month: "long" });
  } else {
    return d;
  }
}

export function isFutureDate(dateStr) {
  const month = dateStr.slice(0, 2);
  const year = dateStr.slice(3, 7);
  const originalDate = new Date(year, parseInt(month) - 1);
  const date = new Date();
  const currentDate = new Date(date.getFullYear(), date.getMonth());
  return originalDate > currentDate;
}

export function formatDateInverse(dateStr) {
  if (dateStr.length !== 8 && dateStr.length !== 6) {
    return '';
  }
  let year = dateStr.substring(0, 4);
  let month = dateStr.substring(4, 6);
  return month + '/' + year;
}

export function formatDate(dateStr) {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  return `${year}${month}${day}`;
}