import { createSlice } from '@reduxjs/toolkit';
import { defaultLanguage } from 'i18n/config';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    language: defaultLanguage,
    urlPrefix: '',
    drawerOpen: false,
    loading: true,
    unrecoverableError: false,
    fromDetails: false, // Coming back to matches table from details page
    activeTabBeforeDetails: 0 // Used together with the previous prop
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      state.urlPrefix = action.payload === 'en' ? '' : `/${action.payload}`;
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUnrecoverableError: (state, action) => {
      state.unrecoverableError = action.payload;
    },
    setActiveTabBeforeDetails: (state, action) => {
      state.activeTabBeforeDetails = action.payload;
    },
    setFromDetails: (state, action) => {
      state.fromDetails = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setLanguage,
  setDrawerOpen,
  setLoading,
  setUnrecoverableError,
  setFromDetails,
  setActiveTabBeforeDetails
} = appSlice.actions;

export default appSlice.reducer