import { useField } from 'formik';

/* Styles */
import loginStyles from 'styles/login.module.scss';

/* Material UI and other UI Dependencies */
import { TextField } from "@material-ui/core";

/* Utils */
import * as utils from 'lib/formHelpers';

//have to use as child of <Formik><Form>, not useFormik
const InputTextField = ({
  formikId,
  formattedLabelText = null,
  inputProps,
  muiProps }) => {

  const [fieldInputProps, fieldMetaProps] = useField(formikId);
  const hasError = fieldMetaProps.touched && Boolean(fieldMetaProps.error)

  return <TextField
    FormHelperTextProps={{
      className: loginStyles.helperText
    }}
    error={hasError}
    fullWidth
    helperText={hasError && fieldMetaProps.error}
    label={formattedLabelText}
    onKeyDown={utils.preventSubmitOnEnter}
    type="text"
    variant="filled"
    inputProps={{
      title: formattedLabelText,
      "aria-label": formattedLabelText,
      autoComplete: 'off',
      ...inputProps
    }}
    {...fieldInputProps}
    {...muiProps}
  />
}

export default InputTextField;
