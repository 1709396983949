import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'formik';

/* Styles */
import styles from 'styles/onboarding.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import Button from '@material-ui/core/Button';

/* Assets */
import upload from 'assets/images/Upload.svg';

const useStyles = makeStyles((theme) => ({
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function UploadFile({ className, onDrop, initialFiles = [], onRemove, ...formikProps }) {
  const classes = useStyles();
  const [files, setFiles] = useState(initialFiles);
  const intl = useIntl();

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    onRemove();
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      // Read file contents and store base64 encoded data to
      // File object in 'contents' attribute
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          file.contents = reader.result;
          const tokens = file.name.split('.');
          let ext = '';
          if (tokens.length > 1) {
            file.ext = '.' + tokens.pop();
            ext = file.ext;
          }
          onDrop(reader.result, ext);
        };
        reader.readAsDataURL(file);
      });

      setFiles(acceptedFiles);

      formikProps.form.setFieldValue(formikProps.field.name, acceptedFiles);
    },
    [formikProps.field.name, formikProps.form.setFieldValue, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, maxSize } = useDropzone({
    onDrop: handleDrop,
    accept:
      'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf',
    minSize: 0,
    maxSize: 10000000,
    maxFiles: 1,
    multiple: false,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Alert severity="error" key={file.path}>
      <AlertTitle>
        {file.path} - {file.size} bytes
      </AlertTitle>
      <Typography variant="body1" gutterBottom={0}>
        <FormattedMessage id="forms.enroll.fileFormats" defaultMessage="Must be a (.doc, .docx, .pdf, .txt) format" />
      </Typography>
    </Alert>
  ));

  return (
    <div className={cn(classes.root, className)}>
      {files.length === 0 ? (
        <div
          className={cn({
            [styles.dropZone]: true,
            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} title={`${intl.formatMessage({ id: 'forms.onboarding.jobDescription' })}`} />
          <div>
            <img alt="Upload" src={upload} className={styles.uploadImage} />
          </div>
          <div>
            <Box mt={2}>
              <Typography variant="h5" component="h2" align="center" gutterBottom>
                <FormattedMessage id="fomrs.onboarding.dragDescription" />
              </Typography>
              <Typography variant="h3" align="center">
                <FormattedMessage id="forms.onboarding.recommended" />
              </Typography>
              <Typography variant="h3" align="center">
                <FormattedMessage id="forms.onboarding.filesFormat" />
              </Typography>
              <Box mt={3} align="center">
                <Button color="primary" size="large" variant="contained">
                  {intl.formatMessage({ id: 'forms.onboarding.browse' })}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      ) : null}
      {files.length > 0 && (
        <>
          <List className={styles.list}>
            {files.map((file, i) => (
              <>
                <ListItem divider={i < files.length - 1} key={i} disableGutters>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h4' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                    secondary={intl.formatMessage({ id: 'forms.onboarding.extractInfo' })}
                  />
                </ListItem>
                <Box display="block">
                  <Button variant="outlined" color="primary" onClick={removeFile(file)}>
                    {intl.formatMessage({ id: 'forms.onboarding.remove' })}
                  </Button>
                </Box>
              </>
            ))}
          </List>
        </>
      )}
      {isFileTooLarge && (
        <Alert severity="error">
          <FormattedMessage id="forms.enroll.uploadErrorTooLarge" defaultMessage="File is too large" />
        </Alert>
      )}
      {fileRejections && <div>{fileRejectionItems}</div>}
    </div>
  );
}

UploadFile.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  initialFiles: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func,
  //the below props come from passing UploadFile to formik's Field component
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    //and a lot of other formik values
  }),
  formik: PropTypes.object,
  children: PropTypes.any,
};

export default connect(UploadFile);
