import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setMinYearsExperienceFilter, setMinEducationLevelFilter } from 'app/slices/jobsSlice';

/* Analytics */
import TimeMe from 'timeme.js';

/* Date */
import { toJSONLocal } from 'lib/date';

/* Material UI and other UI Dependencies */
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const Careers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const jobs = useSelector((state) => state.jobs.jobs.filter((j) => j.archived));

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS_ARCHIVED');
    TimeMe.startTimer();
    dispatch(setMinYearsExperienceFilter(false));
    dispatch(setMinEducationLevelFilter(false));
    return () => {
      // Leaving Jobs Archived
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="space-between">
            <Typography variant="h1" component="h2" gutterBottom>
              {intl.formatMessage({ id: 'jobs.title' }, { name: 'José' })}
            </Typography>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => history.push(`${urlPrefix}/jobs/new`)}
            >
              {intl.formatMessage({ id: 'jobs.addJob' })}
            </Button>
          </Box>
          <Box mb={3}>
            <Link
              to={`${urlPrefix}/jobs`}
              className={cn(layoutStyles.link, layoutStyles.linkSize, layoutStyles.linkPadding)}
            >
              {intl.formatMessage({ id: 'jobs.openJobs' })}
            </Link>{' '}
            <span className={styles.currentLink}>{intl.formatMessage({ id: 'jobs.archivedJobs' })}</span>
          </Box>
          <hr className={layoutStyles.separator} />
          <div className={styles.cardsContainer}>
            {jobs.map((job) => {
              const ts = job.timestamp;
              const opened = ts
                ? new Date(
                    `${ts.substr(0, 4)}-${ts.substr(4, 2)}-${ts.substr(6, 2)}T${ts.substr(8, 2)}:${ts.substr(
                      10,
                      2
                    )}:${ts.substr(12, 2)}`
                  )
                : new Date();
              return (
                <div className={styles.jobCard} key={job.id}>
                  <span className={styles.jobCardTitle}>{job.title}</span>
                  <span className={styles.jobCardSubitle}>{`${intl.formatMessage({
                    id: 'jobs.openedOn',
                  })} ${toJSONLocal(opened)}`}</span>
                  <Box m={1} />
                  <Box display="flex" justifyContent="center">
                    <Link
                      to={`${urlPrefix}/jobs/${job.id}/description`}
                      underline="always"
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                    >
                      {intl.formatMessage({ id: 'jobs.description' })}
                    </Link>
                  </Box>
                  <Box m={1} />
                  <Box display="flex" justifyContent="space-between" style={{ maxWidth: '195px', margin: '0 auto' }}>
                    <Link
                      to={`${urlPrefix}/jobs/${job.id}/matches`}
                      underline="always"
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                    >
                      {intl.formatMessage({ id: 'jobs.matches' })}
                    </Link>
                    <Link
                      to={`${urlPrefix}/jobs/${job.id}/submitted`}
                      underline="always"
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                    >
                      {intl.formatMessage({ id: 'jobs.submitted' })}
                    </Link>
                  </Box>
                </div>
              );
            })}
          </div>
          <Box m={5} />
        </Container>
      </div>
    </>
  );
};

export default withWidth()(Careers);
