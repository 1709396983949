/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { FormattedMessage } from 'react-intl';

/* Material UI and other UI Dependencies */
import OnboardingHeader from 'components/OnboardingHeader';
import Typography from "@material-ui/core/Typography";
import alert from 'assets/images/Alert.svg';

export default function ErrorMessage() {

  return (
    <>
      <OnboardingHeader />
      <div className={styles.loginFlow}>
        <div className={styles.loginFlow__formContainer}>
          <div className={styles.loginFlow__form}>
            <Typography align="center" variant="h1" gutterBottom>
              <FormattedMessage id="app.error" />
            </Typography>
          </div>
          <hr className={layoutStyles.separatorBold} />
          <div className={styles.loadingScreen__cardsContainer}>
            <p style={{textAlign: 'center'}}>
              <img width='80' src={alert} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}