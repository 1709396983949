/* i18n */
import { useIntl } from "react-intl";

/* Styles */
import styles from "styles/candidates.module.scss";
import layoutStyles from "styles/layout.module.scss";

/* Redux */
import { useSelector } from "react-redux";

/* Material UI and other UI Dependencies */
import { Box, Typography } from "@material-ui/core";

/* Assets */
import GreenCheck from "assets/images/GreenCheck.svg";
import YellowStop from "assets/images/YellowStop.svg";
import WhiteCheck from "assets/images/WhiteCheck.svg";

const MatchingConfirmedSkills = ({ skillList = [] }) => {
  const intl = useIntl();
  if (skillList.length === 0) return null;

  return (
    <Box paddingY={1}>
      <div className={styles.candidateDetailsCard}>
        <Typography variant="h2" component="span" gutterBottom>
          {intl.formatMessage({ id: "candidates.details.identifiedSkills" })}
        </Typography>
        <Box m={2} />
        <div className={styles.candidateSkillsCards}>
          {skillList.map((skill) => (
            <div key={skill} className={styles.candidateSkillCard}>
              <img
                className={styles.cardCheckImage}
                src={GreenCheck}
                alt={intl.formatMessage({ id: "jobs.matches.skillRating" })}
              />
              <span>{skill}</span>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

const MatchingMayHaveSkills = ({ skillList = [] }) => {
  const intl = useIntl();
  if (skillList.length === 0) return null;

  return (
    <Box paddingY={1}>
      <div className={styles.candidateDetailsCard}>
        <Typography variant="h2" component="span" gutterBottom>
          {intl.formatMessage({ id: "candidates.details.skillsMayHave" })}
        </Typography>
        <Box m={2} />
        <div className={styles.candidateSkillsCards}>
          {skillList.map((skill) => (
            <div key={skill} className={styles.candidateSkillCard}>
              <img
                className={styles.cardCheckImage}
                src={WhiteCheck}
                alt={intl.formatMessage({ id: "jobs.matches.skillRating" })}
              />
              <span>{skill}</span>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

const MatchingNotConfirmedSkills = ({ skillList = [] }) => {
  const intl = useIntl();
  if (skillList.length === 0) return null;

  return (
    <Box paddingY={1}>
      <div className={styles.candidateDetailsCard}>
        <Typography variant="h2" component="span" gutterBottom>
          {intl.formatMessage({ id: "candidates.details.skillsNotConfirmed" })}
        </Typography>
        <Box m={2} />
        <div className={styles.candidateSkillsCards}>
          {skillList.map((skill) => (
            <div key={skill} className={styles.candidateSkillCard}>
              <img
                className={styles.cardCheckImage}
                src={YellowStop}
                alt={intl.formatMessage({ id: "jobs.matches.skillRating" })}
              />
              <span>{skill}</span>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

const OtherConfirmedSkills = ({ skillList = [] }) => {
  const intl = useIntl();
  if (skillList.length === 0) return null;

  return (
    <Box paddingY={1}>
      <div className={styles.candidateDetailsCard}>
        <Typography variant="h2" component="span" gutterBottom>
          {intl.formatMessage({ id: "candidates.details.otherSkills" })}
        </Typography>
        <Box m={2} />
        <div className={styles.candidateSkillsCards}>
          {skillList.map((skill) => (
            <div key={skill} className={styles.candidateSkillCard}>
              <span>{skill}</span>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

const CandidateSkillsSections = ({
  matched_skills = {},
  other_skills = {},
  jobId,
}) => {
  const jobs = useSelector((state) => state.jobs.jobs);
  const jobIndex = useSelector((state) => state.jobs.jobIndex);
  const job = jobIndex.hasOwnProperty(jobId) ? jobs[jobIndex[jobId]] : {};

  const sortedMatchedSkills = Object.entries(matched_skills).reduce(
    (sortedMatches, [skillName, skillScore]) => {
      if (parseFloat(skillScore) > 0.5) {
        sortedMatches["confirmed"].push(skillName);
      } else if (parseFloat(skillScore) > 0.1) {
        sortedMatches["mayHave"].push(skillName);
      } else {
        sortedMatches["notConfirmed"].push(skillName);
      }
      return sortedMatches;
    },
    { confirmed: [], mayHave: [], notConfirmed: [] }
  );

  job.selected_skills?.forEach((sk) => {
    if (
      !sortedMatchedSkills.confirmed.includes(sk) &&
      !sortedMatchedSkills.mayHave.includes(sk) &&
      !sortedMatchedSkills.notConfirmed.includes(sk)
    ) {
      sortedMatchedSkills["notConfirmed"].push(sk);
    }
  });

  return (
    <>
      <MatchingConfirmedSkills skillList={sortedMatchedSkills.confirmed} />
      <MatchingMayHaveSkills skillList={sortedMatchedSkills.mayHave} />
      <OtherConfirmedSkills skillList={other_skills} />
    </>
  );
};

export default CandidateSkillsSections;
