import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Tooltip, } from "@material-ui/core";
import Loading from 'components/Loading';

/* Redux */
import { useDispatch } from 'react-redux';

const Tabs = (props) => {

  const {
    reloading,
    setActiveTab,
    setActiveTabBeforeDetails,
    setAboveActiveTab,
    activeTab,
    showMobile,
    topCount,
    handleOpenTopTooltip,
    Question,
    potentialCount,
    handleOpenPotentialTooltip,
    trainableCount,
    handleOpenTrainabelTooltip
  } = props

  const dispatch = useDispatch()
  const intl = useIntl();

  return (
    <div className={styles.tabs}>
      {/* Top tab */}
      <div onClick={() => { setActiveTab(0); dispatch(setActiveTabBeforeDetails(0)); setAboveActiveTab(0); }} className={cn({
        [styles.tab]: true,
        [styles.activeTab]: activeTab === 0
      })}>
        <Box
          display="flex"
          flexDirection={showMobile ? "column" : "row"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <span style={{ margin: "0 .25em" }}>{`${intl.formatMessage({ id: 'jobs.matches.tabs.top' })}:`}</span>
          <span>{reloading ? <Loading.Inline /> : topCount}</span>
        </Box>
        {!showMobile ? (
          <Tooltip
            title={intl.formatMessage({ id: 'jobs.matches.tooltipTop' })}
            arrow
            placement="top-end"
            classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            enterTouchDelay={0}
            leaveTouchDelay={3500}
            onOpen={handleOpenTopTooltip}
          >
            <img className={styles.tooltipIcon} src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
          </Tooltip>
        ) : null}
      </div>

      {/* Potential tab */}
      <div onClick={() => { setActiveTab(1); dispatch(setActiveTabBeforeDetails(1)); setAboveActiveTab(1); }} className={cn({
        [styles.tab]: true,
        [styles.activeTab]: activeTab === 1
      })}>
        <Box
          display="flex"
          flexDirection={showMobile ? "column" : "row"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <div style={{ margin: "0 .25em" }}>{`${intl.formatMessage({ id: 'jobs.matches.tabs.potential' })}:`}</div>
          <div>{reloading ? <Loading.Inline /> : potentialCount}</div>
        </Box>
        {!showMobile ? (
          <Tooltip
            title={intl.formatMessage({ id: 'jobs.matches.tooltipPotential' })}
            arrow
            placement="top-end"
            classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            enterTouchDelay={0}
            leaveTouchDelay={3500}
            onOpen={handleOpenPotentialTooltip}
          >
            <img className={styles.tooltipIcon} src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
          </Tooltip>
        ) : null}
      </div>

      {/* Trainable tab */}
      <div onClick={() => { setActiveTab(2); dispatch(setActiveTabBeforeDetails(2)); setAboveActiveTab(2); }} className={cn({
        [styles.tab]: true,
        [styles.activeTab]: activeTab === 2
      })}>
        <Box
          display="flex"
          flexDirection={showMobile ? "column" : "row"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <div style={{ margin: "0 .25em" }}>{`${intl.formatMessage({ id: 'jobs.matches.tabs.trainable' })}:`}</div>
          <div>{reloading ? <Loading.Inline /> : trainableCount}</div>
        </Box>
        {!showMobile ? (
          <Tooltip
            title={intl.formatMessage({ id: 'jobs.matches.tooltipTrainable' })}
            arrow
            placement="top-end"
            classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            enterTouchDelay={0}
            leaveTouchDelay={3500}
            onOpen={handleOpenTrainabelTooltip}
          >
            <img className={styles.tooltipIcon} src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

export default Tabs;