/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import { Box, CircularProgress } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import OnboardingSteps from 'components/OnboardingSteps';

export default function LoadingStep() {
  const { onboardingMaxStep, loadingStepMessage } = useSelector((state) => state.onboarding);
  const intl = useIntl();
  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <OnboardingSteps activeStep={onboardingMaxStep} />
        <Box px={3} mt={3}>
          <Typography align="center" variant="h1"  gutterBottom>
            {intl.formatMessage({id:loadingStepMessage})}
          </Typography>
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <div className={styles.loadingScreen__cardsContainer}>
        <Box display="flex" justifyContent="center" mb={10} mt={10}>
          <CircularProgress size={110} thickness={4} />
        </Box>
      </div>
    </>
  );
}
