import { useEffect } from 'react';
/* Styles */
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from "timeme.js";

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from "@material-ui/core";

const CreditsCitations = () => {

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName("TERMSOFUSE");
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Terms of Use')
    };
  }, []);

  return(
    <>
      <div className={layoutStyles.content}>
        <Container maxWidth="lg">
          <Typography variant="h1" gutterBottom>
            Credits &amp; Citations
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
          Includes data provided through <a target="_blank" className={layoutStyles.link} rel="noreferrer" href="https://www.careeronestop.org/">CareerOneStop</a> by the United States Department of Labor Employment and Training Administration and the Minnesota Department of Employment and Economic Development. This page includes information from <a target="_blank" className={layoutStyles.link} rel="noreferrer" href="https://www.onetcodeconnector.org/">O*NET</a> Code Connector by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA). Used under the <a target="_blank" className={layoutStyles.link} rel="noreferrer" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a> license.
          </Typography>
        </Container>
      </div>
    </>
  )
}

export default CreditsCitations;