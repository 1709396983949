import { useIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import MuiDrawer from "@material-ui/core/Drawer";
import Close from "assets/images/Close";
import { useHistory, Link } from "react-router-dom";

/* Styles */
import styles from 'styles/drawer.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

export default function Drawer({ handleDrawerClose, open, signOut }) {

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const handleMenuItemClick = (page) => () => {
    const eventName = `I_MENU_${page}`;
    trackEvent(eventName);
    handleDrawerClose();
  }

  const handleSignOut = () => {
    const eventName = 'I_MENU_SIGNOUT';
    trackEvent(eventName);
    handleDrawerClose();
    signOut();
    history.push(`/${urlPrefix}`);
  }

  return (
    <MuiDrawer
      className={styles.menuDrawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: styles.menuDrawerPaper
      }}
    >
      <div>
        <IconButton
          onClick={handleDrawerClose}
          classes={{
            root: styles.menuDrawerCloseContainer
          }}
        >
          <Close className={styles.menuDrawerClose} />
        </IconButton>
      </div>
      <List>
        <ListItem>
          <Link className={styles.menuDrawerLink} to={`${urlPrefix}/dashboard`} onClick={handleMenuItemClick('HOME')}>
            <ListItemText primary={intl.formatMessage({id: 'layout.menu.home'})} />
          </Link>
        </ListItem>
        <ListItem>
          <Link className={styles.menuDrawerLink} to={`${urlPrefix}/jobs`} onClick={handleMenuItemClick('JOBS')}>
            <ListItemText primary={intl.formatMessage({id: 'layout.menu.jobs'})} />
          </Link>
        </ListItem>
        <ListItem>
          <Link className={styles.menuDrawerLink} to={`${urlPrefix}/company-profile`} onClick={handleMenuItemClick('COMPANY_PROFILE')}>
            <ListItemText primary={intl.formatMessage({id: 'layout.menu.companyProfile'})} />
          </Link>
        </ListItem>
        <ListItem>
          <Link className={styles.menuDrawerLink} to={`/${urlPrefix}`} onClick={handleSignOut}>
            <ListItemText primary={intl.formatMessage({id: 'layout.menu.signOut'})} />
          </Link>
        </ListItem>
      </List>
    </MuiDrawer>
  );
}