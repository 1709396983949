import { sort, propOr, pathOr } from 'ramda';

export const backToLinkDict = {
  matches: 'backToMatches',
  submitted: 'backToSubmitted',
  saved: 'backToSaved',
  removed: 'backToRemoved'
}

export const getActiveRoute = (pathname) => {
  if (pathname.indexOf('matches') > -1) {
    return 'matches'
  }
  if (pathname.indexOf('saved') > -1) {
    return 'saved'
  }
  if (pathname.indexOf('submitted') > -1) {
    return 'submitted'
  }
  if (pathname.indexOf('removed') > -1) {
    return 'removed'
  }
}

export const getActiveMatchList = (activeRoute, jobId, filteredMatches) => {
  const routeDict = {matches: 'active', saved: 'saved', submitted: 'contacted', removed: 'archived'};
  const active_matches = pathOr([], [propOr('', activeRoute, routeDict), jobId], filteredMatches);

  return sort((a, b) => b.match_score - a.match_score, active_matches);
}

export const selectMatchObjectFromMatchesData = (jobId, matchId, matchesData, placeholderMatch = {}) => {
  if (matchesData.hasOwnProperty(jobId)) {
    const currentJobMatches = matchesData[jobId]
    return currentJobMatches.find(match => match.id === matchId) || placeholderMatch;
  } else {
    return placeholderMatch;
  }
}