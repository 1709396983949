import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Analytics */
import TimeMe from "timeme.js";
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

const Careers =() => {
  const {jobs, summary: jobSummary} = useSelector((state) => state.jobs);
  const nOpenJobs = jobs.filter(j => !j.archived).length;
  const employerInfo = useSelector((state) => state.employer)

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName("DASHBOARD");
    TimeMe.startTimer();
    return () => {
      // Leaving dashboard
    };
  }, []);

  const handleJobsClick = () => {
    trackEvent('DASHBOARD_OPEN_JOBS');
    history.push(`${urlPrefix}/jobs`);
  };

  const handleSubmittedClick = () => {
    trackEvent('DASHBOARD_SUBMITTED');
    history.push(`${urlPrefix}/jobs`);
  };

  const handleProfileClick = () => {
    trackEvent('DASHBOARD_PROFILE_PERC');
    history.push(`${urlPrefix}/company-profile`);
  };

  return(
    <>
      <div className={styles.content}>
        <Container maxWidth="md">
          {employerInfo.name && (<>
            <Box mb={3} mt={4}>
              <Typography variant="h1" gutterBottom>
                {intl.formatMessage({ id: 'dashboard.title' }, { name: employerInfo.name })}
              </Typography>
            </Box>
            <Divider />
          </>)}
          <div className={styles.cardsContainer}>
            <div className={styles.card} onClick={handleJobsClick}>
              <span className={styles.cardValue}>{nOpenJobs}</span>
              <span className={styles.cardDescription}>
                {intl.formatMessage({id: 'dashboard.openJobs'})}
              </span>
            </div>
            <div className={styles.card} onClick={handleSubmittedClick}>
              <span className={styles.cardValue}>{jobSummary.candidatesSubmitted}</span>
              <span className={styles.cardDescription}>
                {intl.formatMessage({ id: `dashboard.${jobSummary.candidatesSubmitted === 1 ? 'candidateSubmitted' : 'candidatesSubmitted'}` })}
              </span>
            </div>
            <div className={styles.card} onClick={handleProfileClick}>
              <span className={styles.cardValue}>{employerInfo.profileCompletion}</span>
              <span className={styles.cardDescription}>
                {intl.formatMessage({id: 'dashboard.companyProfileCompleted'})}
              </span>
            </div>
          </div>
            <Box m={5} />
            <h2 className={styles.subtitle}>{intl.formatMessage({id: 'dashboard.aboutTitle'})}</h2>
            <div className={styles.textContainer}>
              <div className={styles.text}>{intl.formatMessage({id: 'dashboard.text'})}</div>
            </div>
            <Box m={5} />
        </Container>
      </div>
    </>
  )
}

export default withWidth()(Careers);