// import { apiRequest } from "lib/API";

export const trackEvent = (eventName, item = {}) => {
  return new Promise((resolve, reject) => {
    console.log('Tracking event: ', eventName, item)
    resolve({ message: 'completed' })
    // apiRequest("post", "/profile", {
    //   data: {
    //     action: "event",
    //     name: eventName,
    //     item
    //   }
    // }).then(response => {
    //   resolve(response);
    // }).catch(error => {
    //   reject(error);
    // })
  });
}