import { useState } from 'react';

/* Styles */
import cn from 'classnames';
import styles from 'styles/candidates.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Box, MenuItem, Select, FormControl, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { ToggleSwitch } from 'components/inputs';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import { useEffect } from 'react';

/* Lib */
import { educationLevelsIntlDict } from 'lib/education';
// Coment to try deploy
const TableHeader = (props) => {
  const {
    sortBy,
    handleSortingChange,
    detailsPath,
    ArrowDown,
    sortingOptions,
    selected_skills,
    submittedPage,
    archived,
    handleToggleSwitchClick,
    job,
    reloading,
    setFilterByMinEducation,
    setFilterByMinExperience,
    filterByMinEducation,
    filterByMinExperience,
  } = props;

  const intl = useIntl();
  const [toggleClicked, setToggleClicked] = useState(false);

  useEffect(() => {
    if (!reloading && toggleClicked) {
      setToggleClicked(false);
    }
  }, [reloading, toggleClicked]);

  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.tableHeader__header}>
          <div className={cn(styles.selectContainer)}>
            <div className={styles.sortContainer}>
              <FormControl variant="filled">
                <Select
                  id="sort-select"
                  value={sortBy}
                  onChange={handleSortingChange}
                  onOpen={() => {
                    const eventName = `JOBS_${detailsPath.toLocaleUpperCase()}_SORT`;
                    trackEvent(eventName);
                  }}
                  MenuProps={{
                    PaperProps: {
                      className: filterStyles.selectMenu,
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={(_props) => (
                    <img alt="Arrow down" className={filterStyles.arrowDown} src={ArrowDown} />
                  )}
                >
                  {sortingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <span className={filterStyles.checkboxLabel}>{option.title}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {job.min_education_level || job.min_years_experience ? (
              <div className={styles.requirementsFilters}>
                <Typography variant="h4" gutterBottom>
                  {intl.formatMessage({ id: 'candidatesTable.filterEduExp' })}
                </Typography>
                {job.min_education_level ? (
                  <FormControl
                    classes={{
                      root: styles.formControlRoot,
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        root: styles.formControlLabelRoot,
                        label: 'MuiTypography-caption',
                      }}
                      label={intl.formatMessage({ id: educationLevelsIntlDict[job.min_education_level] })}
                      control={
                        <Checkbox
                          checked={filterByMinEducation}
                          onChange={(e) => setFilterByMinEducation(e.target.checked)}
                          inputProps={{
                            value: 'matches-min-education',
                          }}
                        />
                      }
                      labelPlacement="end"
                    />
                  </FormControl>
                ) : null}
                {job.min_years_experience ? (
                  <FormControl
                    classes={{
                      root: styles.formControlRoot,
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        root: styles.formControlLabelRoot,
                        label: 'MuiTypography-caption',
                      }}
                      label={intl.formatMessage(
                        { id: 'jobs.matches.nYearsExperience' },
                        { years: job.min_years_experience }
                      )}
                      control={
                        <Checkbox
                          checked={filterByMinExperience}
                          onChange={(e) => setFilterByMinExperience(e.target.checked)}
                          inputProps={{
                            value: 'matches-experience',
                          }}
                        />
                      }
                      labelPlacement="end"
                    />
                  </FormControl>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className={styles.tableHeaderRow}>
            <div className={cn(styles.tableHeaderCell)}>
              {intl.formatMessage({ id: 'jobs.matches.matchPercentage' })}
            </div>
          </div>
        </div>
        <div className={styles.tableHeader__header}>
          <div className={cn(styles.tableHeaderRow, styles.tableHeaderRow__second)}>
            <div className={cn(styles.tableHeaderCell)}>
              {intl.formatMessage({ id: 'jobs.matches.lastJobTitle' })}
              {/* <br /> */}
              {/* {intl.formatMessage({id: 'jobs.matches.industry'})} */}
              <br />
              {intl.formatMessage({ id: 'jobs.matches.id' })}
            </div>
          </div>
        </div>
        <div className={styles.tableSkillsGroup}>
          {selected_skills.map((ss, ssIndex) => (
            <div className={styles.headerSkillCell} key={ss}>
              <span className={styles.headerSkillLabel}>{ss}</span>
              <Box mt={1} mb={2}>
                {!submittedPage && !archived ? (
                  <ToggleSwitch
                    label={ss}
                    checked={job.categories[ss]}
                    onClick={(event) => {
                      if (toggleClicked) return;

                      setToggleClicked(true);
                      handleToggleSwitchClick(event, ss, ssIndex);
                    }}
                    disabled={toggleClicked}
                  />
                ) : null}
              </Box>
            </div>
          ))}
          {/* {
            job.min_years_experience > 0 ? (
              <div className={styles.headerSkillCell}>
                <span className={styles.headerSkillLabel}>{intl.formatMessage({id: 'jobs.matches.yoe'})}</span>
                <Box mt={1} mb={4} />
              </div>
            ) : null
          } */}
          {/* {
            job.min_education_level > 0 ? (
              <div className={styles.headerSkillCell}>
                <span className={styles.headerSkillLabel}>{intl.formatMessage({id: 'jobs.matches.minimumEducation'})}</span>
                <Box mt={1} mb={4} />
              </div>
            ) : null
          } */}
        </div>
      </div>
    </>
  );
};

export default TableHeader;
