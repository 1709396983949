/* i18n */
import { useIntl } from "react-intl";

/* Styles */
import styles from "styles/candidates.module.scss";

/* Material UI and other UI Dependencies */
import { Box, Typography } from "@material-ui/core";

/* Misc */
import { educationLevelsIntlDict } from "lib/education";

const ColumnTitle = ({ children }) => (
  <Box mb={1}>
    <Typography variant="h2" component="span" gutterBottom>
      {children}
    </Typography>
  </Box>
);
const RowTitle = ({ children }) => (
  <Typography variant="h5" component="span" gutterBottom>
    {children}
  </Typography>
);
const RowData = ({ children }) => (
  <Typography variant="body2" component="span" gutterBottom>
    {children}
  </Typography>
);

const CandidateInfoSection = (matchObject) => {
  const intl = useIntl();

  const naics_title = "Unknown"; //we have candidate_id.soc_code
  const soc_title = matchObject["candidate_id.soc_title"] || "Unknown";
  const years_experience = matchObject.hasOwnProperty(
    "candidate_id.years_experience"
  )
    ? `${matchObject["candidate_id.years_experience"]} ${intl.formatMessage({
        id: "candidates.details.years",
      })}`
    : "Unknown";
  const open_to_training = "Unknown";
  const education_level =
    intl.formatMessage({
      id: educationLevelsIntlDict[matchObject["candidate_id.education_level"]],
    }) || "Unknown";

  return (
    <Box paddingY={4}>
      <div className={styles.candidateDetailsCardTwoColumns}>
        <div>
          {" "}
          {/* col */}
          <ColumnTitle mb={1}>
            {" "}
            {/* row */} {/* header */}
            {intl.formatMessage({ id: "candidates.details.experienceSummary" })}
          </ColumnTitle>
          {/* <div>
            {" "}
            <RowTitle>
            {intl.formatMessage({ id: 'candidates.details.priorExperience' })}:&nbsp;
          </RowTitle>
            <RowData>{naics_title}</RowData>
          </div> */}
          <div>
            {" "}
            {/* row */}
            <RowTitle>
              {intl.formatMessage({ id: "candidates.details.priorJobTitle" })}
              :&nbsp;
            </RowTitle>
            <RowData>{soc_title}</RowData>
          </div>
          <div>
            {" "}
            {/* row */}
            <RowTitle>
              {intl.formatMessage({
                id: "candidates.details.avgLenghEmployment",
              })}
              :&nbsp;
            </RowTitle>
            <RowData>{years_experience}</RowData>
          </div>
        </div>
        <div>
          {" "}
          {/* col */}
          <div className={styles.smallScreenPadding} /> {/* row */}{" "}
          {/* padding-top: 26px for screens under 800px width */}
          <ColumnTitle>
            {" "}
            {/* row */} {/* header */}
            {intl.formatMessage({ id: "candidates.details.education" })}
          </ColumnTitle>
          {/* <div>
            {" "}
            <RowTitle>
              {`${intl.formatMessage({
                id: "candidates.details.openToTraining",
              })}: `}
            </RowTitle>
            <RowData>{open_to_training}</RowData>
          </div>*/}
          <div>
            {" "}
            {/* row */}
            <RowTitle>
              {intl.formatMessage({ id: "candidates.details.educationLevel" })}
              :&nbsp;
            </RowTitle>
            <Typography variant="body2" component="span" gutterBottom>
              {education_level}
            </Typography>
          </div>
          {matchObject.field_of_study ? (
            <div>
              {" "}
              {/* row */}
              <RowTitle>
                {intl.formatMessage({ id: "forms.onboarding.education.fos" })}
                :&nbsp;
              </RowTitle>
              <Typography variant="body2" component="span" gutterBottom>
                {matchObject.field_of_study}
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </Box>
  );
};

export default CandidateInfoSection;
