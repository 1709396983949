import { createSlice } from '@reduxjs/toolkit';

export const currentJobSlice = createSlice({
	name: 'currentJob',
	initialState: {
		job: {
			title: "",
			zip_code: "",
			remote: false,
			part_time: false,
			description: "",
			min_education_level: 0,
			certifications: [],
			min_years_experience: 0,
			willing_to_train: false,
			soc_code: "",
			selected_skills: [],
			skills: [],
			job_posting_url: '',
		},
		salaryRange: {},
		careerUrl: '',
		currentJobId: ''
	},
	reducers: {
		setCurrentJob: (state, { payload }) => {
			if (payload.hasOwnProperty('id')) {
				state.currentJobId = payload.id
			}

			const cleanedUpCerts = payload.hasOwnProperty('certifications')
				? payload.certifications.filter(cert => cert === '')
				: state.job.certifications;

			//sanity check, removing any extra formik stuff that may have crept in
			state.job = {
				id: payload.id || state.job.id,
				title: payload.title || state.job.title,
				zip_code: payload.zip_code || state.job.zip_code,
				remote: payload.remote || state.job.remote,
				part_time: payload.part_time || state.job.part_time,
				description: payload.description || state.job.description,
				min_education_level: payload.min_education_level || state.job.min_education_level,
				certifications: cleanedUpCerts,
				min_years_experience: payload.min_years_experience || state.job.min_years_experience,
				willing_to_train: payload.willing_to_train || state.job.willing_to_train,
				soc_code: payload.soc_code || state.job.soc_code,
				selected_skills: payload.selected_skills || state.job.selected_skills,
				skills: payload.skills || state.job.skills,
				job_posting_url: payload.job_posting_url || state.job.job_posting_url,
			}
		},
		clearCurrentJob: (state) => {
			state.job = {
				title: "",
				zip_code: "",
				remote: false,
				part_time: false,
				description: "",
				min_education_level: 0,
				certifications: [],
				min_years_experience: 0,
				willing_to_train: false,
				soc_code: "",
				selected_skills: [], //skills the employer selected
				skills: [], //all relevant skills
				job_posting_url: ''
			};
			state.currentJobId = '';
			state.salaryRange = {};
			state.careerUrl = '';
		},
		//handle parse job description results
		setParsedSocResults: (state, { payload }) => { //payload is soc object
			if (payload.hasOwnProperty('code')) {
				state.job.soc_code = payload.code
			}

			if (payload.hasOwnProperty('salary')) {
				state.salaryRange = payload.salary
			}

			if (payload.hasOwnProperty('url')) {
				state.careerUrl = payload.url;
			}

			return state;
		},
		clearParsedFileResults: (state) => {
			state.job.description = "";
			state.job.soc_code = "";
			state.salaryRange = {};
			state.careerUrl = '';
		},
		setOnboardingSkills: (state, action) => {
			state.job.skills = action.payload;
		},
	}
});

// Action creators are generated for each case reducer function
export const {
	setCurrentJob,
	clearCurrentJob,
	setParsedSocResults,
	clearParsedFileResults,
	setOnboardingSkills
} = currentJobSlice.actions;

export default currentJobSlice.reducer;
