import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';

/* i18n Dependencies */
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import getUserLocale from 'get-user-locale';
import { languages, defaultLanguage } from 'i18n/config';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'app/slices/appSlice';

/* API */
import { defaultQueryFn } from 'app/queries';

function IntlApp() {
  
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.app);

  const options = {
    locale: language,
    defaultLocale: defaultLanguage,
    messages: require(`./i18n/${['es', 'en'].includes(language) ? language : defaultLanguage}.json`),
  };

  options.messages = flatten(options.messages);

  useEffect(() => {
    // Lookup prior language preference from local storage.
    const userLocale    = getUserLocale() && getUserLocale().substr(0, 2) ? getUserLocale().substr(0, 2) : false;
    const lsLocale      = typeof window !== 'undefined' && localStorage && localStorage.getItem('lng') ? localStorage.getItem('lng') : false;
    const browserLocale = languages.includes(userLocale) ? userLocale : false;
    if (lsLocale && language !== lsLocale) {
      dispatch(setLanguage(lsLocale));
    } else if (browserLocale  && language !== lsLocale) {
      dispatch(setLanguage(browserLocale));
    }
  }, [dispatch]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
    <IntlProvider locale={language} defaultLocale={defaultLanguage} messages={options.messages}>
      <App locale={language} />
    </IntlProvider>
    </QueryClientProvider>
  );
}

export default IntlApp;