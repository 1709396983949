import { useState } from 'react';
import * as utils from 'components/CandidateDetails/utils';

/* API */
import { matchMethods, apiHooks } from 'lib/api/index';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Forms */
import { Formik, Form } from 'formik';
import { InputTextField } from 'components/inputs';

/* Material UI and other UI Dependencies */
import {
  Button,
  Box,
} from "@material-ui/core";
import { useSnackbar } from 'notistack';
import Loading from 'components/Loading';

/* Styles */
import styles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';


const UpdateMatchNotes = ({ jobId, matchId }) => {

  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [updatingNotes, setUpdatingNotes] = useState(false);
  const refreshMatches = apiHooks.match.useRefreshAllMatches(jobId, {
    onError: (err) => {
      enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
    }
  })

  const { matches } = useSelector((state) => state.jobs);
  const currentMatch = utils.selectMatchObjectFromMatchesData(jobId, matchId, matches, { notes: '' })

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={false}
      initialValues={{ 'notes': currentMatch.notes }}
      onSubmit={(formValues) => {
        setUpdatingNotes(true);
        matchMethods.updateNotes(jobId, matchId, formValues.notes)
          .then(response => {
            setUpdatingNotes(false);
            console.log(response)
            enqueueSnackbar(intl.formatMessage({ id: 'jobs.matches.saveNotesSuccess' }), { variant: 'success' });
            refreshMatches();
          })
          .catch(error => {
            setUpdatingNotes(false);
            console.error(error)
            enqueueSnackbar(intl.formatMessage({ id: 'jobs.matches.saveNotesError' }), { variant: 'error' });
          })
      }}
    >
      {(formikProps) => {
        // withFormikDevtools(formikProps);

        if (updatingNotes) {
          return <Loading.Icon />
        }

        return (
          <Form>
            <InputTextField
              formikId="notes"
              muiProps={{
                variant: 'outlined',
                placeholder: intl.formatMessage({ id: 'candidates.details.addNotes' }),
                multiline: true,
                minRows: 4,
                maxRows: 12,
                classes: {
                  root: layoutStyles.marginNone
                }
              }}
            />
            <Box className={styles.submitBtn__container} mt={4} display="flex" justifyContent="flex-end">
              <Box mr={1}>
                <Button
                  size="large"
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={formikProps.resetForm}
                  disabled={(!formikProps.dirty) || updatingNotes}
                >
                  {intl.formatMessage({ id: 'jobs.modal.cancel' })}
                </Button>
              </Box>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formikProps.dirty || updatingNotes || !Boolean(formikProps.values.notes)}
              >
                {
                  updatingNotes ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">
                        {intl.formatMessage({ id: 'jobs.modal.saving' })}
                      </span>
                    </div>
                  )
                    : (intl.formatMessage({ id: 'jobs.description.save' }))
                }
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateMatchNotes;