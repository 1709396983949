import { css } from '@emotion/css';
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { propOr, difference } from 'ramda';
import { withStyles } from "@material-ui/core/styles";

/* i18n */
import { useIntl } from 'react-intl';
import skillsJson from 'i18n/skills.json';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const skillsDict = skillsJson.reduce((skDict, skill) => {
  return {...skDict, [skill.en]: skill};
}, {});

const CustomAutocomplete = withStyles({
  tag: {
    borderRadius: 0,
    backgroundColor: cssVars.tagSkill,
    height: 22,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: cssVars.darkGray,
      fontWeight: 700,
      fontSize: '10px',
      textTransform: 'uppercase'
    },
    "& .MuiChip-deleteIcon": {
      width: '14px',
      height: '14px'
    },
  },
  endAdornment: {
    top: 'initial',
    bottom: '9px'
  }
})(Autocomplete);

export default function SkillsAutocomplete ({ isDisabled, addSkillHandler, removeSkillHandler, activeSkills, onboarding=false}) {

  const intl = useIntl();
  const allSkillsEnglish = Object.values(skillsDict).reduce((optionList, allTranslations) => {
    optionList.push(allTranslations)
    return optionList.sort((a, b) => a[intl.locale].localeCompare(b[intl.locale]));
  }, []);

  return (
    <CustomAutocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      id={"add-skills"}
      options={allSkillsEnglish}
      getOptionLabel={(option) => option && option[intl.locale]}
      value={activeSkills}
      disabled={isDisabled}
      forcePopupIcon={false} //disable dropdown icon
      freeSolo={false} //user can't enter custom values
      getOptionSelected={(option, value) => {
        return option[intl.locale] === value[intl.locale] || option['en'] === value.en
        //return option.en === value.title;
      }}
      getOptionDisabled={(options) => (activeSkills.length >= 5 ? true : false)}
      onChange={(_, newValue) => {
        const oldSkills = activeSkills.map(s => propOr(s.title, 'en', s));
        const newSkills = newValue.map(s => propOr(s.title, 'en', s));
        if (newValue.length < oldSkills.length) {
          const removedKey = difference(oldSkills, newSkills)[0];
          const removedSkill = activeSkills.reduce((acc, sk) => (sk.en === removedKey || sk.title === removedKey ? sk : acc), {});
          removeSkillHandler(removedSkill);
        } else {
          const addedKey = difference(newSkills, oldSkills)[0];
          const newSkill = newValue.reduce((acc, sk) => (sk.en === addedKey || sk.title === addedKey ? sk : acc), {});
          addSkillHandler(newSkill);
          if (onboarding) {
            trackEvent('ONBOARDING_SKILLS_ENTRY');
          }
        }
      }}
      classes={{
        input: css({flex: '0 1 100%'}),
        paper: css({
          border: `solid 1px ${cssVars.mediumGray}`,
          margin: '0 !important',
          borderRadius: '0 !important'
        })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
        />
      )}
    />
  );
}