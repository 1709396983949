import { Tooltip } from "@mui/material";

/* i18n */
import { useIntl } from "react-intl";

/* Assets */
import GreenCheck from "assets/images/GreenCheck.svg";
import YellowStop from "assets/images/YellowStop.svg";
import WhiteCheck from "assets/images/WhiteCheck.svg";

/* Styles */
import candidatesStyles from "styles/candidates.module.scss";

const TYPES_ENUM = {
  CONFIRMED: "CONFIRMED",
  MAY_HAVE: "MAY_HAVE",
  NOT_CONFIRMED: "NOT_CONFIRMED",
};

const explanationIntlIds = {
  [TYPES_ENUM.CONFIRMED]: "jobs.matches.skillsConfirmed",
  [TYPES_ENUM.MAY_HAVE]: "jobs.matches.skillsMayHave",
  [TYPES_ENUM.NOT_CONFIRMED]: "jobs.matches.skillsNotConfirmed",
};

const SvgComponent = {
  [TYPES_ENUM.CONFIRMED]: GreenCheck,
  [TYPES_ENUM.MAY_HAVE]: WhiteCheck,
  [TYPES_ENUM.NOT_CONFIRMED]: YellowStop,
};

const SkillCheckWithToolTip = ({ typeString }) => {
  const intl = useIntl();
  const text = intl.formatMessage({ id: explanationIntlIds[typeString] });

  return (
    <Tooltip
      title={text}
      arrow
      placement="top-end"
      classes={{
        tooltip: candidatesStyles.tooltip,
        arrow: candidatesStyles.tooltipArrow,
      }}
      enterTouchDelay={0}
      leaveTouchDelay={3500}
    >
      {
        <div className={candidatesStyles.cardCheckImage}>
          <img src={SvgComponent[typeString]} alt={text} />
        </div>
      }
    </Tooltip>
  );
};

const Confirmed = () => (
  <SkillCheckWithToolTip typeString={TYPES_ENUM.CONFIRMED} />
);
const MayHave = () => (
  <SkillCheckWithToolTip typeString={TYPES_ENUM.MAY_HAVE} />
);
const NotConfirmed = () => (
  <SkillCheckWithToolTip typeString={TYPES_ENUM.NOT_CONFIRMED} />
);

const SkillCheck = { Confirmed, MayHave, NotConfirmed };
export default SkillCheck;

export const SkillCellContents = (matched_candidate) => {
  const { matched_skills, skillId } = matched_candidate;
  if (parseFloat(matched_skills[skillId]) === 1) {
    return <Confirmed />;
  } else if (parseFloat(matched_skills[skillId]) > 0.1) {
    return <MayHave />;
  }
  return <NotConfirmed />;
};

export const ExperienceCellContents = ({ years_experience_requirement }) => {
  if (years_experience_requirement === TYPES_ENUM.CONFIRMED) {
    return <Confirmed />;
  } else {
    return <NotConfirmed />;
  }
};

export const EducationCellContents = ({ minimum_education }) => {
  if (minimum_education === TYPES_ENUM.CONFIRMED) {
    return <Confirmed />;
  } else {
    return <NotConfirmed />;
  }
};
