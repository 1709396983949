import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

/* Styles */
import styles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Box, FormControl, Checkbox } from '@material-ui/core';
import { SkillCellContents } from './SkillCheck';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCandidates } from 'app/slices/candidateSlice';

const CandidateRow = (props) => {
  const {
    job,
    mc,
    candidatesSubmitted,
    submittedPage,
    Plane,
    archived,
    toggleCandidateSelected,
    urlPrefix,
    jobId,
    detailsPath,
    handleViewDetailsClick,
    selected_skills,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const { selected_candidates, submittingCandidates } = useSelector((state) => state.candidates);

  const handleRemoveId = (id) => {
    dispatch(setSelectedCandidates(selected_candidates.filter((item) => item !== id)));
  };

  return (
    <>
      <div
        key={mc.id}
        className={cn({
          [styles.tableRow]: true,
          [styles.tableRow__hidden]: candidatesSubmitted && selected_candidates.indexOf(mc.id) > -1,
        })}
      >
        <div className={cn(styles.tableCell, styles.matchCell)}>
          {submittedPage ||
          (activePath === 'saved' && submittingCandidates && selected_candidates.indexOf(mc.id) > -1) ? (
            <Plane
              className={styles.checkboxPlane}
              style={{ marginLeft: '-4px' }}
              onClick={() => handleRemoveId(mc.id)}
            />
          ) : (
            /* This is where check box is */
            <FormControl
              classes={{
                root: onboardingStyles.formControlRoot,
              }}
            >
              {!archived ? (
                <Checkbox
                  checked={selected_candidates.indexOf(mc.id) > -1}
                  color="primary"
                  disabled={submittingCandidates && selected_candidates.indexOf(mc.id) > -1}
                  onChange={() => toggleCandidateSelected(mc.id)}
                  name="part_time"
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'candidates.details.selectCandidate',
                    }),
                  }}
                />
              ) : null}
            </FormControl>
          )}

          {/* match rating number */}
          <span className={styles.matchPercentage}>{mc.match_score}</span>
        </div>

        {/* Last job title, industry, unique id */}
        <div className={cn(styles.tableCell, styles.titleCell)}>
          <Box display="flex" flexDirection="column" pt={1} pb={1}>
            <span className={styles.industryLabel}>{mc['candidate_id.soc_title']}</span>
            <span className={styles.lastJobLabel}>{mc.naics_title}</span>
            <span className={styles.candidateId}>{mc.id}</span>
          </Box>
          <div className={styles.candidateDetailsLinkContainer}>
            <Link
              to={`${urlPrefix}/jobs/${jobId}/${detailsPath}/${mc.id}`}
              underline="always"
              className={cn(layoutStyles.link, layoutStyles.linkSize, layoutStyles.noWrap)}
              aria-label={intl.formatMessage(
                { id: 'jobs.matches.ariaViewDetails' },
                { candidate: mc['candidate_id.soc_title'] }
              )}
              onClick={handleViewDetailsClick}
            >
              {intl.formatMessage({ id: 'jobs.matches.viewDetails' })}
            </Link>
            {mc.submitted ? (
              <span>
                {intl.formatMessage({ id: 'jobs.sent' })} {mc.date_submitted}
              </span>
            ) : null}
          </div>
        </div>

        {/* Skills checkboxes */}
        <div className={styles.tableSkillsGroup}>
          {selected_skills.map((ss) => (
            <div key={ss} className={styles.skillCell}>
              {job.categories[ss] ? <SkillCellContents skillId={ss} {...mc} /> : null}
            </div>
          ))}
          {/* {
                  job.min_years_experience > 0 ? (
                    <div className={styles.skillCell}>
                      <ExperienceCellContents {...mc} />
                    </div>
                  ) : null
                } */}
          {/* {
                  job.min_education_level > 0 ? (
                    <div className={styles.skillCell}>
                      <EducationCellContents {...mc} />
                    </div>
                  ) : null
                } */}
        </div>
      </div>
    </>
  );
};

export default CandidateRow;
