import { useState, useEffect, useMemo } from 'react';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { naicsMethods } from 'lib/api/index';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { TextField, CircularProgress, makeStyles } from "@material-ui/core";
import { Autocomplete } from '@mui/material';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

/* Misc */
import { debounce } from 'lib/performance';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
    color: `${cssVars.black} !important`
  },
  gray: {
    color: `${cssVars.semiDarkGray} !important`
  },
  spaceBehind: {
    marginRight: '.125em'
  },
  loadingSpinner: {
    position: 'absolute',
    right: '1em',
    top: 0,
    bottom: 0,
    margin: 'auto 0'
  }
}));

export const IndustrySelect = ({
  formikId, //the form field that will hold the actual naics code
  formikQueryId, //a placeholder form field for the inputValue query string
  formattedLabelText,
  formik = false
}) => {

  const intl = useIntl();
  const styles = useStyles();

  const queryFieldInputProps = formik ? formik.getFieldProps(formikQueryId) : {};
  const codeFieldInputProps = formik ? formik.getFieldProps(formikId) : {};
  const codeFieldMetaProps = formik ? formik.getFieldMeta(formikId) : {};
  const codeFieldHelperProps = formik ? formik.getFieldHelpers(formikId) : {};
  const hasError = codeFieldInputProps.touched && Boolean(codeFieldInputProps.error)

  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [industrySuggestions, setIndustrySuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null)

  //make sure form query field value, form code field value, and displayed input value 
  //don't get out of sync
  useEffect(async () => {
    if (!inputValue) {
      if (codeFieldInputProps.value) {
        setSuggestionsLoading(true);
        const { naics } = await naicsMethods.getTitle(codeFieldInputProps.value);
        setSelectedOption(naics);
        setInputValue('')
      }
    }
  }, [codeFieldInputProps.value, queryFieldInputProps.value, inputValue])

  const fetch = useMemo(() =>
    debounce(async (queryString, callback) => {
      setSuggestionsLoading(true);
      callback(await naicsMethods.stringSearch(queryString));
    }, 400), []);

  useEffect(() => {
    let active = true;

    //when input is cleared, clear suggestions list
    //unless one was selected from the dropdown
    if (inputValue === '') {
      setIndustrySuggestions(selectedOption ? [selectedOption] : []);
      setSuggestionsLoading(false);
      return undefined;
    }

    //send "inputValue" query string to /lookup & handle results
    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        //preserve previously selected option if there is one
        if (selectedOption) {
          newOptions = [selectedOption];
        }

        //add results to industry suggestions
        if (results && results.naics) {
          newOptions = [...newOptions, ...results.naics];
        }

        setIndustrySuggestions(newOptions);
        setSuggestionsLoading(false);
      }
    });

    return () => {
      active = false;
      setSuggestionsLoading(false);
    };
  }, [selectedOption, inputValue, fetch]);

  /**
 * This function always returns all of the suggestions so the filtering
 * can be made on the server
 */
  const filterOptions = (options) => options;

  const highlightLabel = (part, keyId) => {
    let spanStyle = part.highlight ? styles.bold : styles.gray;

    if (part.text.slice(-1) === " ") {
      spanStyle = `${spanStyle} ${styles.spaceBehind}`
    }

    return <span className={spanStyle} key={keyId}>{part.text}</span>
  }

  const handleRenderOption = (props, option, { inputValue }) => {
    const matches = match(option.name.trim(), inputValue);
    const parts = parse(option.name.trim(), matches);

    return <li {...props}>
      <div>{parts.map((part, index) => highlightLabel(part, index))}</div>
    </li>
  }

  const handleSelectIndustryOption = (event, newValue) => {
    setIndustrySuggestions(prevSuggestions => newValue ? [newValue, ...prevSuggestions] : prevSuggestions)
    setSelectedOption(newValue)
    if (newValue && newValue.hasOwnProperty("id")) {
      codeFieldHelperProps.setValue(parseInt(newValue.id))
    } else {
      codeFieldHelperProps.setTouched(false)
      codeFieldHelperProps.setValue('')
    }
  }

  if (!formikId) return null;

  return <>
    <Autocomplete
      id={`autocomplete_${formikId}`}
      aria-label={formattedLabelText}
      getOptionLabel={(option) => option.name || ''}
      filterOptions={filterOptions}
      options={industrySuggestions}
      includeInputInList
      filterSelectedOptions
      value={selectedOption}
      noOptionsText={suggestionsLoading ? "Searching..." : "No Industry Found"}
      onChange={handleSelectIndustryOption}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={handleRenderOption}
      renderInput={(params) => (
        <TextField
          {...queryFieldInputProps}
          {...params}
          onBlur={event => {
            queryFieldInputProps.onBlur(event)
            setSuggestionsLoading(false);
          }}
          label={formattedLabelText}
          placeholder={intl.formatMessage({ id: 'forms.onboarding.industryPlaceholder', description: 'string' })}
          variant="filled"
          error={hasError}
          helperText={hasError && codeFieldMetaProps.error}
          fullWidth
          FormHelperTextProps={{
            className: styles.helperText
          }}
          inputProps={{
            ...params.inputProps,
            title: formattedLabelText,
            autoComplete: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: suggestionsLoading
              ? <CircularProgress color="inherit" size={'1em'} className={styles.loadingSpinner} />
              : params.InputProps.endAdornment
          }}
        />
      )}
    />
  </>
}

export default IndustrySelect;