import cn from 'classnames';
import { useRef } from 'react';
import { Link, useLocation } from "react-router-dom";

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/app-header.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import { withWidth, Container } from '@material-ui/core';

const AppHeader =({locale, width}) => {

  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  /* Refs to get menu's scroll position */
  const itemsContainer = useRef(null);  
  
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const handleMenuItemClick = (page) => () => {
    const eventName = `MENU_${page}`;
    trackEvent(eventName);
  }

  return(
    <>
      <nav className={styles.mainNav}>
        <Container maxWidth="lg">
          <div ref={itemsContainer} className={styles.mainNav__itemsContainer}>
            <Link to={`${urlPrefix}/dashboard`} onClick={handleMenuItemClick('DASHBOARD')} className={cn({
              [styles.mainNav__item]: true,
              [styles.mainNav__item__active]: activePath === 'dashboard'
            })}>
              <span
                className={cn({
                  [styles.breakSpace]: (
                    intl.formatMessage({id: 'layout.menu.dashboard', description:'string'}).length > 12
                  )
                })}
              >
                {intl.formatMessage({id: 'layout.menu.dashboard', description:'string'})}
              </span>
            </Link>
            <Link to={`${urlPrefix}/jobs`} onClick={handleMenuItemClick('JOBS')} className={cn({
              [styles.mainNav__item]: true,
              [styles.mainNav__item__active]: ['jobs', 'description', 'matches', 'submitted', 'saved', 'removed', 'archived', 'new'].indexOf(activePath) > -1
            })}>
              <span
                className={cn({
                  [styles.breakSpace]: (
                    intl.formatMessage({id: 'layout.menu.jobs', description:'string'}).length > 12
                  )
                })}
              >
                {intl.formatMessage({id: 'layout.menu.jobs', description:'string'})}
              </span>
            </Link>
            <Link to={`${urlPrefix}/company-profile`} onClick={handleMenuItemClick('COMPANY_PROFILE')} className={cn({
              [styles.mainNav__item]: true,
              [styles.mainNav__item__active]: activePath === 'company-profile'
            })}>
              <span
                className={cn({
                  [styles.breakSpace]: (
                    intl.formatMessage({id: 'layout.menu.companyProfile', description:'string'}).length > 12
                  )
                })}
              >
                {intl.formatMessage({id: 'layout.menu.companyProfile', description:'string'})}
              </span>
            </Link>
          </div>
        </Container>
      </nav>
    </>
  )
}

export default withWidth()(AppHeader);