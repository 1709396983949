export function getEmployerDataByCategory(candidateList) {
	const questionsByCategory = {};
	candidateList.forEach((candidate) => {
		if (candidate['candidate_id.soc_title']) {
			if (!questionsByCategory.hasOwnProperty(candidate['candidate_id.soc_title'])) {
				questionsByCategory[candidate['candidate_id.soc_title']] = [];
			}
			questionsByCategory[candidate['candidate_id.soc_title']].push(candidate);
		}
	});

	return questionsByCategory;
}

export const getIdValuesFromCategory = (object) => {
	return [ object.map((item) => item.id) ];
};

export const containsSameIds = (arr1, arr2) => {
	return arr1.filter((value) => arr2.some((val) => val === value)).length > 0;
};

export const getAverage = (objects, key) => {
	let total = 0;
	let count = 0;

	for (const object of objects) {
		if (object.hasOwnProperty(key)) {
			total += object[key];
			count++;
		}
	}

	return Math.round(total / count);
};

//SORTING MATCHES
const sortByMatchRatingDescending = (a, b) => (a.match_score - b.match_score);
const sortByMatchRatingAscending = (a, b) => (b.match_score - a.match_score);

export const SORT_ORDERS = {
	SMALLEST: 'SMALLEST',
	LARGEST: 'LARGEST'
}
export const sortCandidatesByMatchRating = (candidateList, sortOrder) => {
	if (!candidateList.length) {
		return [];
	}

	if (sortOrder === SORT_ORDERS.SMALLEST) {
		return ([...candidateList]).sort(sortByMatchRatingDescending);
	}

	if (sortOrder === SORT_ORDERS.LARGEST) {
		return ([...candidateList]).sort(sortByMatchRatingAscending);
	}

	return candidateList
};

export const categorizeMatches = (allMatches) => (
	allMatches.reduce((acc, curr) => {
    const [actives, contacted, saved, archived] = [...acc];
    if (curr.status === 'ACTIVE') actives.push(curr);
    if (curr.status === 'CONTACTED') contacted.push(curr);
    if (curr.status === 'SAVED') saved.push(curr);
    if (curr.status === 'ARCHIVED') archived.push(curr);
    return [actives, contacted, saved, archived];
  }, [[], [], [], []])
)