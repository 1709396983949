import { useState, useEffect } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setEmployer } from 'app/slices/employerSlice';

import { employerMethods } from 'lib/api/index';

/* Material UI and other UI Dependencies */
import { TextField, Button, Box, Typography, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import ZipcodeInput from 'components/inputs/ZipcodeInput';

/* Misc */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';
import { phoneRegExp, emailRegExp } from 'lib/formHelpers';

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const employerInfo = useSelector((state) => state.employer);

  const validationSchema = yup.object({
    name: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.companyNameValidation' }))
      .min(2, intl.formatMessage({ id: 'forms.onboarding.companyNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'forms.onboarding.companyNameMaxLength' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.companyNameRequired' })),
    contact: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.contactNameValidation' }))
      .min(2, intl.formatMessage({ id: 'forms.onboarding.contactNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'forms.onboarding.contactNameMaxLength' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.contactNameRequired' })),
    phone_number: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.primaryPhone' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'forms.onboarding.primaryPhoneValidation' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.primaryPhoneRequired' })),
    zip_code: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.zipcode' }))
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'forms.onboarding.onlyDigits' }))
      .min(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 }))
      .max(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 }))
      .required(intl.formatMessage({ id: 'forms.onboarding.zipcodeRequired' })),
    email: yup
      .string()
      .matches(emailRegExp, intl.formatMessage({ id: 'forms.signIn.matchEmail' }))
      .required(intl.formatMessage({ id: 'company.details.emailRequired' })),
  });

  const formik = useFormik({
    initialValues: {
      soft_skills: employerInfo.soft_skills,
      description: employerInfo.description,
      name: employerInfo.name,
      contact: employerInfo.contact,
      phone_number: employerInfo.phone_number,
      zip_code: employerInfo.zip_code,
      email: employerInfo.email,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Tracking events
      if (values.name !== employerInfo.name) {
        trackEvent('COMPANY_PROFILE_MAIN_PAGE_COMPANY_DETAILS_COMPANY_NAME');
      }
      if (values.contact !== employerInfo.contact) {
        trackEvent('COMPANY_PROFILE_MAIN_PAGE_COMPANY_DETAILS_CONTACT_NAME');
      }
      if (values.phone_number !== employerInfo.phone_number) {
        trackEvent('COMPANY_PROFILE_MAIN_PAGE_COMPANY_DETAILS_PHONE');
      }
      if (values.zip_code !== employerInfo.zip_code) {
        trackEvent('COMPANY_PROFILE_MAIN_PAGE_COMPANY_DETAILS_ZIP');
      }
      if (values.email !== employerInfo.email) {
        trackEvent('COMPANY_PROFILE_MAIN_PAGE_COMPANY_EMAIL');
      }

      setSubmitting(true);
      employerMethods
        .update(values)
        .then(function (response) {
          dispatch(setEmployer(values));
          setSubmitting(false);
          enqueueSnackbar(intl.formatMessage({ id: 'company.updateSuccess' }), {
            variant: 'success',
          });
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('COMPANY_PROFILE');
    TimeMe.startTimer();
    return () => {
      // Leaving Company Profile
    };
  }, []);

  return (
    <div className={styles.jobsMatchesWrapper}>
      <div className={dashboardStyles.content}>
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="flex-start">
            <Typography variant="h1">{intl.formatMessage({ id: 'layout.menu.companyProfile' })}</Typography>
          </Box>
          <Box mb={1} className={styles.matchesHeaderContent} />
          <hr className={layoutStyles.separator} />
          <Box m={3} />
          <div className={styles.descriptionContent}>
            <form onSubmit={formik.handleSubmit} className={cn(styles.loginFlow__form, styles.signInFormNoStripe)}>
              <div className={styles.descriptionFormCard}>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'onboarding.skills.successful' }, { companyName: employerInfo.name })}
                  </Typography>
                </Box>
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.soft_skills && Boolean(formik.errors.soft_skills)}
                  name="soft_skills"
                  type="text"
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: 'company.whatMakesSuccessful.placeholder',
                  })}
                  multiline
                  helperText={formik.touched.soft_skills && formik.errors.soft_skills}
                  autoComplete="off"
                  value={formik.values.soft_skills}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={4}
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'onboarding.skills.successful',
                    }),
                  }}
                />
              </div>
              <div className={styles.descriptionFormCard}>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'company.about.title' }, { companyName: 'Acme Corporation' })}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Typography variant="h3" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'company.about.subtitle' }, { companyName: 'Acme Corporation' })}
                  </Typography>
                </Box>
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  name="description"
                  type="text"
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: 'company.about.placeholder',
                  })}
                  multiline
                  helperText={formik.touched.description && formik.errors.description}
                  autoComplete="off"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={4}
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'company.about.title',
                    }),
                    onFocus: () => trackEvent('COMPANY_PROFILE_MAIN PAGE_COMPANY_DESCRIPTION'),
                  }}
                />
              </div>
              <div className={styles.descriptionFormCard}>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'company.details.title' }, { companyName: 'Acme Corporation' })}
                  </Typography>
                </Box>
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label={`${intl.formatMessage({
                    id: 'forms.onboarding.companyName',
                  })}*`}
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                  variant="filled"
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'forms.onboarding.companyName',
                    }),
                  }}
                />
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.contact && Boolean(formik.errors.contact)}
                  fullWidth
                  helperText={formik.touched.contact && formik.errors.contact}
                  label={`${intl.formatMessage({
                    id: 'forms.onboarding.contactName',
                  })}*`}
                  name="contact"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.contact}
                  variant="filled"
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'forms.onboarding.contactName',
                    }),
                  }}
                />
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                  name="phone_number"
                  type="text"
                  variant="filled"
                  fullWidth
                  helperText={formik.touched.phone_number && formik.errors.phone_number}
                  autoComplete="off"
                  label={`${intl.formatMessage({
                    id: 'forms.onboarding.primaryPhone',
                  })}*`}
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                  }}
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'forms.onboarding.primaryPhone',
                    }),
                  }}
                />
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                  name="zip_code"
                  type="text"
                  variant="filled"
                  fullWidth
                  helperText={formik.touched.zip_code && formik.errors.zip_code}
                  autoComplete="off"
                  label={`${intl.formatMessage({
                    id: 'forms.onboarding.zipcode',
                  })}*`}
                  value={formik.values.zip_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    inputComponent: ZipcodeInput,
                  }}
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'forms.onboarding.zipcode',
                    }),
                  }}
                />
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  fullWidth
                  disabled={!!formik.initialValues.email.length}
                  helperText={formik.touched.email && formik.errors.email}
                  label={`${intl.formatMessage({
                    id: 'forms.signIn.username',
                  })}*`}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={intl.formatMessage({
                    id: 'forms.signIn.enterUsername',
                  })}
                  type="email"
                  value={formik.values.email}
                  variant="filled"
                  inputProps={{
                    'aria-label': intl.formatMessage({
                      id: 'forms.signIn.username',
                    }),
                  }}
                />
              </div>
              <Box className={styles.submitBtn__container} mt={4} display="flex" justifyContent="flex-end">
                <Button disabled={submitting} size="large" type="submit" variant="contained" color="primary">
                  {submitting ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                    </div>
                  ) : (
                    intl.formatMessage({ id: 'jobs.description.save' })
                  )}
                </Button>
              </Box>
            </form>
          </div>
          <Box m={5} />
        </Container>
      </div>
    </div>
  );
};

export default CompanyProfile;
