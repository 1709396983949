import { useFormikContext } from "formik";

/* Styles */
import onboardingStyles from "styles/onboarding.module.scss";

/* i18n */
import { useIntl } from "react-intl";

/* Material UI and other UI Dependencies */
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ZIPCodeInput, InputTextField } from "components/inputs";

const JobInfoSection = () => {
  const intl = useIntl();
  const formikContext = useFormikContext();

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h2" component="span" gutterBottom>
          {intl.formatMessage({ id: "forms.onboarding.sections.jobDetails" })}
        </Typography>
        <Typography variant="caption" component="span" gutterBottom>
          *{intl.formatMessage({ id: "forms.onboarding.required" })}
        </Typography>
      </Box>
      <InputTextField
        formikId={"title"}
        formattedLabelText={`${intl.formatMessage({
          id: "forms.onboarding.sections.jobTitle",
          description: "string",
        })}*`}
      />
      <InputTextField
        formikId={"zip_code"}
        formattedLabelText={intl.formatMessage({
          id: "forms.onboarding.jobZipcode",
        })}
        muiProps={{
          InputProps: {
            inputComponent: ZIPCodeInput,
          },
        }}
      />
      <Box>
        <FormControl
          classes={{
            root: onboardingStyles.formControlRoot,
          }}
        >
          <FormControlLabel
            classes={{
              root: onboardingStyles.formControlLabelRoot,
              label: onboardingStyles.formControlLabeLabel,
            }}
            label={intl.formatMessage({
              id: "forms.onboarding.remote",
              description: "string",
            })}
            control={
              <Checkbox
                value={formikContext.values.remote}
                checked={formikContext.values.remote}
                color="primary"
                onChange={(e) =>
                  formikContext.handleChange({
                    target: { name: "remote", value: e.currentTarget.checked },
                  })
                }
                name="remote"
              />
            }
            labelPlacement="end"
          />
        </FormControl>
      </Box>
      <Box mb={2}>
        <FormControl
          classes={{
            root: onboardingStyles.formControlRoot,
          }}
        >
          <FormControlLabel
            classes={{
              root: onboardingStyles.formControlLabelRoot,
              label: onboardingStyles.formControlLabeLabel,
            }}
            label={intl.formatMessage({
              id: "forms.onboarding.partTime",
              description: "string",
            })}
            control={
              <Checkbox
                value={formikContext.values.part_time}
                checked={formikContext.values.part_time}
                color="primary"
                onChange={(e) =>
                  formikContext.handleChange({
                    target: {
                      name: "part_time",
                      value: e.currentTarget.checked,
                    },
                  })
                }
                name="part_time"
              />
            }
            labelPlacement="end"
          />
        </FormControl>
      </Box>
      <InputTextField
        formikId={"job_posting_url"}
        formattedLabelText={`${intl.formatMessage({
          id: "forms.onboarding.sections.jobPostingURL",
        })}*`}
      />
    </>
  );
};

export default JobInfoSection;
