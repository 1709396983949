import * as yup from 'yup';

/* i18n */
import { useIntl } from 'react-intl';

const useJobValidation = (config) => {
  const intl = useIntl();

  const configValues = {
    withSelectedSkills: true,
    ...config,
  };

  const validationSchema = yup.object({
    title: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.jobTitleValidation' }))
      .min(2, intl.formatMessage({ id: 'forms.onboarding.jobTitleMinLength' }))
      .max(50, intl.formatMessage({ id: 'forms.onboarding.jobTitleMaxLength' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.jobTitleRequired' })),
    job_posting_url: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.sections.urlRequired' }))
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        intl.formatMessage({ id: 'forms.onboarding.sections.urlRequired' })
      )
      .required(intl.formatMessage({ id: 'forms.onboarding.sections.urlRequired' })),
    zip_code: yup
      .string(
        intl.formatMessage({
          id: 'forms.onboarding.zipcode',
          description: 'string',
        })
      )
      .matches(
        /^[0-9]+$/,
        intl.formatMessage({
          id: 'forms.onboarding.onlyDigits',
          description: 'string',
        })
      )
      .min(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits', description: 'string' }, { amount: 5 }))
      .max(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits', description: 'string' }, { amount: 5 })),
    remote: yup.boolean(),
    part_time: yup.boolean(),
    description: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.selectRange' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.jobDescriptionRequired' })),
    min_education_level: yup.number(intl.formatMessage({ id: 'forms.onboarding.educationLevelLabel' })),
    certifications: yup.array().of(yup.string(intl.formatMessage({ id: 'forms.onboarding.certificationsLabel' }))),
    min_years_experience: yup
      .number(intl.formatMessage({ id: 'forms.onboarding.yearsOfExperienceLabel' }))
      .min(0, intl.formatMessage({ id: 'forms.onboarding.yearsOfExperiencePositive' })),
    willing_to_train: yup.bool(),
    //hidden fields triggered by parsing uploaded document or typed description
    // skills: yup
    //   .array()
    //   .when('description', {
    //     is: (description_string) => description_string && description_string.length > 0,
    //     then: yup.array().min(1)
    //   })
    //   .required('no skills set'),
    soc_code: yup.string().when('description', {
      is: (description_string) => description_string && description_string.length > 0,
      then: yup.string().min(6),
    }),
  });

  const selected_skills_validationSchema = yup.object({
    selected_skills: yup
      .array()
      .min(1, intl.formatMessage({ id: 'onboarding.skills.minSkillsValidation' }))
      .max(5, intl.formatMessage({ id: 'onboarding.skills.maxSkillsValidation' })),
  });

  if (configValues.withSelectedSkills) {
    return validationSchema.concat(selected_skills_validationSchema);
  } else {
    return validationSchema;
  }
};

export default useJobValidation;
