import { useState } from 'react';
import { useField, Field, FieldArray } from 'formik';

/* Material UI and other UI Dependencies */
import { FormGroup, FormControlLabel, Checkbox, Box, Typography, Tooltip } from '@material-ui/core';
import { OptOutModal } from './CandidateConsentForm';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import onboardingStyles from 'styles/onboarding.module.scss';
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import { propOr } from 'ramda';
import cn from 'classnames';

const styles = {
  toolTipTrigger: css({
    position: 'absolute',
    right: '-1.5em',
    top: '0',
  }),
  fieldset: css({
    border: `1px solid ${cssVars.mediumGray}`,
  }),
  fieldsetError: css({
    border: `1px solid ${cssVars.errorColorMain}`,
    '& .MuiSvgIcon-root': {
      fill: cssVars.red,
    },
  }),
  fieldsetErrorText: css({
    marginBottom: 0,
    fontSize: '.8em',
    fontWeight: 'bold',
    lineHeight: '1em',
    color: cssVars.errorColorMain,
  }),
  optOut__container: css(
    {
      padding: '10px 0px',
      borderRadius: 4,
      position: 'relative',
      top: 3,
    },
    { label: 'opt-out__container' }
  ),
  bold: css({
    fontWeight: '700 !important',
  }),
};

const CandidateJobChecklist = ({ jobList, hasError, formSubmit, candidateData }) => {
  const intl = useIntl();
  const formikKey = 'jobsSelect';
  const [fieldInputProps, fieldMetaProps] = useField({ name: formikKey, type: 'checkbox' });
  const [modalOpen, setModalOpen] = useState(false);
  const [optOutSuccess, setOptOutSuccess] = useState(false);

  const legendText =
    jobList.length > 1
      ? intl.formatMessage({ id: 'consentForm.jobListTitlePlural' })
      : intl.formatMessage({ id: 'consentForm.jobListTitle' });

  const handleOptOut = async () => {
    setModalOpen(true);
    await formSubmit({ jobsSelect: [], experience: [], id: candidateData.id });
    setOptOutSuccess(true);
  };

  return (
    <Box
      component="fieldset"
      marginY="1em"
      paddingY="1em"
      paddingX="2em"
      className={hasError ? styles.fieldsetError : styles.fieldset}
      borderRadius={'.25em'}
    >
      <Typography variant="h2" component="legend">
        {legendText}
      </Typography>
      <Typography variant="caption">{intl.formatMessage({ id: 'consentForm.jobListSubHeader' })}</Typography>
      <Box mb={2} />
      <FieldArray
        name={formikKey}
        as={FormGroup}
        render={(arrayHelpers) =>
          jobList.map((job, idx) => {
            let jobUrl = propOr('', 'job_id.job_posting_url', job);
            jobUrl = jobUrl.startsWith('http') ? jobUrl : `https://${jobUrl}`;

            return (
              <div>
                <FormControlLabel
                  key={job.job_id}
                  classes={{
                    root: onboardingStyles.formControlLabelRoot,
                    label: onboardingStyles.formControlLabeLabel,
                  }}
                  label={
                    <div style={{ position: 'relative', marginRight: '1em' }}>
                      <Typography
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cn(layoutStyles.link, styles.bold)}
                        href={jobUrl}
                        component="a"
                        variant="body2"
                      >
                        {job['job_id.title']}, {job['job_id.employer_id.name']}
                      </Typography>
                    </div>
                  }
                  control={
                    <Field
                      name={`${formikKey}[${idx}]`}
                      as={Checkbox}
                      type="checkbox"
                      value={job.job_id}
                      checked={fieldInputProps.value.includes(job.job_id)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          arrayHelpers.push(job.job_id);
                        } else {
                          const valIndex = fieldInputProps.value.indexOf(job.job_id);
                          arrayHelpers.remove(valIndex);
                        }
                      }}
                    />
                  }
                />
              </div>
            );
          })
        }
      />
      <ListOptOut handleOptOut={handleOptOut} />
      <OptOutModal
        open={modalOpen}
        success={optOutSuccess}
        successMessage={intl.formatMessage({ id: 'consentForm.optOutSuccess' })}
      />
      {hasError && <p className={styles.fieldsetErrorText}>{fieldMetaProps.error}</p>}
    </Box>
  );
};

const ListOptOut = ({ handleOptOut }) => {
  const intl = useIntl();
  let rawText = intl.formatMessage({ id: 'consentForm.listOptOut' }).split('[');
  const preLink = rawText[0];
  rawText = rawText[1].split(']');
  const link = rawText[0];
  const afterLink = rawText[1];
  return (
    <div className={styles.optOut__container}>
      <Typography variant="caption">
        {preLink}{' '}
        <span className={layoutStyles.link} onClick={handleOptOut}>
          {link}
        </span>
        {afterLink}
      </Typography>
    </div>
  );
};

export default CandidateJobChecklist;
