import { useAuth0 } from '@auth0/auth0-react';

/* Styles */
import styles from 'styles/welcome.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Box, Typography, Button } from "@material-ui/core";

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Assets */
import ArrowRight from 'assets/images/Arrow_RightLight.svg';
import IllustrationGears from 'assets/images/Illustration_Gears.svg';
import IllustrationArchery from 'assets/images/Illustration_Archery.svg';
import IllustrationResume from 'assets/images/Illustration_Resume.svg';

export default function Welcome() {

  const intl = useIntl();
  const { loginWithRedirect } = useAuth0();

  const handleGetStartedClick = () => {
    trackEvent('WELCOME_GETSTARTED');
    // see https://community.auth0.com/t/request-for-federated-logout-support-for-the-auth0-oidc-connection-type/72574
    // prompt: login was needed as a workaround to logoff with OIDC
    // connection goes direct to social login: https://community.auth0.com/t/how-to-skip-aut0-login-page-and-directly-redirect-in-to-social-login-site/28211
    loginWithRedirect({
      prompt: "login",
    });
  }

  return (
    <>
      <div className={styles.blueBar} />
      <div className={styles.welcome__content}>
        <Box mt={1} />
        <div className={styles.titleContainer}>
          <Typography align="center" variant="h1" gutterBottom>
          {intl.formatMessage({id: 'welcome.title'})}
          </Typography>
        </div>
        <Typography align="center" variant="body2" gutterBottom>
        {intl.formatMessage({id: 'welcome.paragraph'})}
        </Typography>
        <Box align="center" mt={2} mb={3}>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={handleGetStartedClick}
          >
          {intl.formatMessage({id:'welcome.getStarted'})}
          </Button>
        </Box>
        <Box align="center" mb={2}>
        <Typography align="center" variant="h6"  gutterBottom>
          {intl.formatMessage({id:'welcome.about',description:'string'})}
          </Typography>
        </Box>
        <div className={styles.imagesContainer}>
          <div className={styles.imageContainer}>
            <img alt="Resume" className={cn(styles.imageResume)} src={IllustrationResume} />
            <div className={styles.imageTextContainer}>
            <Typography align="center" variant="caption"  gutterBottom>
              {intl.formatMessage({id:'welcome.weTake',description:'string'})}
              </Typography>
            </div>
          </div>
          <img alt="Arrow" className={cn(styles.imageArrow)} src={ArrowRight} />
          <div className={styles.imageContainer}>
            <img alt="Gears" className={cn(styles.imageGears)} src={IllustrationGears} />
            <div className={styles.imageTextContainer}>
            <Typography align="center" variant="caption"  gutterBottom>
              {intl.formatMessage({id:'welcome.weAnalyze',description:'string'})}
              </Typography>
            </div>
          </div>
          <img alt="Arrow" className={cn(styles.imageArrow)} src={ArrowRight} />
          <div className={styles.imageContainer}>
            <img alt="Archery" className={cn(styles.imageArchery)} src={IllustrationArchery} />
            <div className={styles.imageTextContainer}>
            <Typography align="center" variant="caption"  gutterBottom>
              {intl.formatMessage({id:'welcome.finally',description:'string'})}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.fullTextContainer}>
        <Typography align="center" variant="caption"  gutterBottom>
          {intl.formatMessage({id:'welcome.fullText'})}
          </Typography>
        </div>
      </div>
    </>
  );
}