import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { difference } from 'ramda';
/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/modal.module.scss';
import candidatesStyles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* API */
import { updateSkills, getProfile } from 'lib/API';

/* Redux */
import { useDispatch } from 'react-redux';
import { setJobs } from 'app/slices/jobsSlice';

/* Material UI and other UI Dependencies */
import { Button, Box, IconButton, Typography, Modal, Backdrop, Fade } from '@material-ui/core';
import SkillCard from 'components/SkillCard';

/* Assets */
import Close from 'assets/images/Close';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const SkillsModal = ({ open, jobId, skills, selectedSkills, setModalOpen }) => {
  const [submitting, setSubmitting] = useState(false);

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const dispatch = useDispatch();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const validationSchema = yup.object({
    selected_skills: yup
      .array()
      .of(yup.object())
      .max(5, intl.formatMessage({ id: 'forms.onboarding.jobTitleMaxLength' })),
  });

  const initialValues = {
    selected_skills: selectedSkills,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ selected_skills }) => {
      const menuItems = ['matches', 'submitted'];
      if (menuItems.indexOf(activePath) > -1) {
        const newSkills = difference(selected_skills, selectedSkills);
        const newSkillsIds = newSkills.map((sk) => sk.id);
        const newPositions = skills.reduce(function (prev, curr, index) {
          if (newSkillsIds.indexOf(curr.id) > -1) {
            return prev.concat(index);
          }
          return prev;
        }, []);
        newPositions.forEach(function (skillIndex) {
          trackEvent(`JOBS_${activePath.toUpperCase()}_ADD_REMOVE_SKILLS_SKILL_${skillIndex + 1}`, skills[skillIndex]);
        });
      }

      setSubmitting(true);
      updateSkills(jobId, selected_skills)
        .then(function (response) {
          getProfile()
            .then(function (response) {
              dispatch(setJobs(response.jobs));
              setSubmitting(false);
              setModalOpen(false);
            })
            .catch(function () {
              setSubmitting(false);
            });
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  const handleSkillClick = (skill, values, setFieldValue, setErrors) => {
    setErrors({ selected_skills: '' });
    const skillIndex = values.selected_skills.map((sk) => sk.id).indexOf(skill.id);
    const arrayCopy = values.selected_skills.concat([]);

    if (skillIndex > -1) {
      arrayCopy.splice(skillIndex, 1);
      setFieldValue('selected_skills', arrayCopy);
    } else {
      if (arrayCopy.length >= 5) {
        setErrors({ selected_skills: intl.formatMessage({ id: 'onboarding.skills.maxSkillsValidation' }) });
      } else {
        setFieldValue('selected_skills', values.selected_skills.concat(skill));
      }
    }
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={styles.modalPaper}>
          <IconButton
            onClick={() => setModalOpen(false)}
            classes={{
              root: styles.modalCloseContainer,
            }}
          >
            <Close className={styles.modalClose} />
          </IconButton>
          <h1 className={candidatesStyles.title}>{intl.formatMessage({ id: 'jobs.matches.addRemove' })}</h1>
          <Typography variant="h3" component="p" align="center">
            {intl.formatMessage({ id: 'onboarding.skills.subtitle2' })}
          </Typography>
          <Typography variant="h3" component="p" align="center" gutterBottom>
            {intl.formatMessage({ id: 'jobs.modal.subtitleText' })}{' '}
            <Link
              to={`${urlPrefix}/jobs/${jobId}/description`}
              className={cn(layoutStyles.link, layoutStyles.linkSize, layoutStyles.linkPadding)}
            >
              {intl.formatMessage({ id: 'jobs.modal.subtitleLink' })}
            </Link>
          </Typography>
          <Box m={1} />
          <form onSubmit={formik.handleSubmit}>
            <div className={onboardingStyles.cardsContainer}>
              {skills.map((s) => (
                <SkillCard
                  key={s}
                  title={s}
                  onClick={() => handleSkillClick(s, formik.values, formik.setFieldValue, formik.setErrors)}
                  active={
                    formik.values.selected_skills
                      ? formik.values.selected_skills.map((sk) => sk).indexOf(s) > -1
                      : false
                  }
                />
              ))}
            </div>
            {formik.errors.selected_skills && (
              <Box className={candidatesStyles.errorMessage__container} mt={1} style={{ width: '100%' }}>
                <label className={candidatesStyles.errorMessage}>
                  {intl.formatMessage({ id: 'onboarding.skills.maxSkillsValidation' })}
                </label>
              </Box>
            )}
            <Box
              className={candidatesStyles.submitBtn__container}
              mt={3}
              style={{ width: '100%' }}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                disabled={submitting}
                size="large"
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => setModalOpen(false)}
              >
                {intl.formatMessage({ id: 'jobs.modal.cancel' })}
              </Button>
              &nbsp;&nbsp;
              <Button disabled={submitting} size="large" type="submit" variant="contained" color="primary">
                {submitting ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">{intl.formatMessage({ id: 'jobs.modal.saving' })}</span>
                  </div>
                ) : (
                  intl.formatMessage({ id: 'jobs.description.save' })
                )}
              </Button>
            </Box>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default SkillsModal;
