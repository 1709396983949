import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setFromDetails } from 'app/slices/appSlice';
import { setMinYearsExperienceFilter, setMinEducationLevelFilter } from 'app/slices/jobsSlice';

/* Analytics */
import TimeMe from 'timeme.js';
import { trackEvent } from 'lib/analytics';

/* Date */
import { toJSONLocal } from 'lib/date';

/* Material UI and other UI Dependencies */
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const Careers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const jobs = useSelector((state) => state.jobs.jobs.filter((j) => !j.archived));

  useEffect(() => {
    dispatch(setFromDetails(false));
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS');
    TimeMe.startTimer();
    dispatch(setMinYearsExperienceFilter(false));
    dispatch(setMinEducationLevelFilter(false));
    return () => {
      // Leaving Jobs
    };
  }, []);

  const handleArchivedJobsClick = () => {
    const eventName = 'JOBS_ARCHIVED';
    trackEvent(eventName);
  };

  const handleNewJobClick = () => {
    const eventName = 'JOBS_ADD_JOB';
    trackEvent(eventName);
    history.push(`${urlPrefix}/jobs/new`);
  };

  const handleDescriptionClick = (job) => {
    const eventName = 'JOBS_DESCRIPTION';
    trackEvent(eventName, job);
  };

  const handleMatchesClick = (job) => {
    const eventName = 'JOBS_MATCHES';
    trackEvent(eventName, job);
  };

  const handleSubmittedClick = (job) => {
    const eventName = 'JOBS_SUBMITTED';
    trackEvent(eventName, job);
  };

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="space-between">
            <Typography variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'jobs.title' })}
            </Typography>
            <Button color="primary" size="large" type="submit" variant="contained" onClick={handleNewJobClick}>
              {intl.formatMessage({ id: 'jobs.addJob' })}
            </Button>
          </Box>
          <Box mb={3}>
            <span className={styles.currentLink}>{intl.formatMessage({ id: 'jobs.openJobs' })}</span>{' '}
            <Link
              onClick={handleArchivedJobsClick}
              to={`${urlPrefix}/jobs/archived`}
              className={cn(layoutStyles.link, layoutStyles.linkSize)}
            >
              {intl.formatMessage({ id: 'jobs.archivedJobs' })}
            </Link>
          </Box>
          <hr className={layoutStyles.separator} />
          <div className={styles.cardsContainer}>
            {jobs.map((job) => {
              return (
                <div className={styles.jobCard} key={job.id}>
                  <span className={styles.jobCardTitle}>{job.title}</span>
                  <span className={styles.jobCardSubitle}>{`${intl.formatMessage({
                    id: 'jobs.openedOn',
                  })} ${toJSONLocal(new Date(job.last_updated))}`}</span>
                  <Box m={1} />
                  <Box display="flex" justifyContent="center">
                    <Link
                      onClick={() => handleDescriptionClick(job)}
                      to={`${urlPrefix}/jobs/${job.id}/description`}
                      underline="always"
                      aria-label={intl.formatMessage({ id: 'jobs.ariaDescription' }, { job: job.title, id: job.id })}
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                    >
                      {intl.formatMessage({ id: 'jobs.description' })}
                    </Link>
                  </Box>
                  <Box m={1} />
                  <Box display="flex" justifyContent="space-between" style={{ maxWidth: '195px', margin: '0 auto' }}>
                    <Link
                      to={`${urlPrefix}/jobs/${job.id}/matches`}
                      onClick={() => handleMatchesClick(job)}
                      underline="always"
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                      aria-label={intl.formatMessage({ id: 'jobs.ariaMatches' }, { job: job.title, id: job.id })}
                    >
                      {intl.formatMessage({ id: 'jobs.matches' })}
                    </Link>
                    <Link
                      to={`${urlPrefix}/jobs/${job.id}/submitted`}
                      onClick={() => handleSubmittedClick(job)}
                      underline="always"
                      className={cn(layoutStyles.link, layoutStyles.linkSize)}
                      aria-label={intl.formatMessage({ id: 'jobs.ariaSubmitted' }, { job: job.title, id: job.id })}
                    >
                      {intl.formatMessage({ id: 'jobs.submitted' })}
                    </Link>
                  </Box>
                </div>
              );
            })}
          </div>
          <Box m={5} />
        </Container>
      </div>
    </>
  );
};

export default withWidth()(Careers);
