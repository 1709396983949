import { createTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import vars from './vars.module.scss';

const theme = createTheme({
  typography: {
		fontFamily: vars.fontFamily,
	},
	overrides: {
		MuiTypography: {
			h1: {
        fontFamily: 'Source Serif Pro',
				fontSize: 28,
        lineHeight: '32px',
				fontWeight: 700,
        color: vars.black,
        '@media (max-width:767px)': {
          fontSize: '24px',
        },
			},
			h2: {
        fontFamily: 'Source Serif Pro',
				fontSize: 16,
        lineHeight: '20px',
				fontWeight: 700,
        color: vars.black
			},
			h3: {
				fontSize: 14,
        lineHeight: '18px',
				fontWeight: 400,
        color: vars.darkGray,
			},
			h4: {
				fontFamily: 'Open Sans',
				fontSize: 14,
        lineHeight: '18px',
				fontWeight: 700,
        color: vars.darkGray,
			},
			h5: {
				fontSize: 16,
        lineHeight: '24px',
				fontWeight: 700,
        color: vars.darkGray
			},
			h6: {
				fontFamily: 'Source Serif Pro',
				fontSize: 16,
        lineHeight: '20px',
				fontWeight: 700
			},
			body1: {
				fontSize: 16,
				lineHeight: '18px',
				fontWeight: 400
			},
			body2: {
				fontSize: 16,
        lineHeight: '22px',
        color: vars.darkGray
			},
      caption: {
        fontSize: 12,
        color: `${vars.darkGray} !important`
      }
		},
		MuiFilledInput: {
      root: {
				borderTopLeftRadius: 3,
      	borderTopRightRadius: 3,
        backgroundColor: vars.lightGray,
      },
			input: {
				padding: '17px 11px 9px',
				fontSize: 16,
        '@media (min-width: 640px)': {
          fontSize: 12
        }
			},
      underline: {
        "&:before": {
          borderBottomColor: vars.mediumGray
        },
        "&:hover:not(.Mui-focused):before": {
          borderBottomColor: vars.mediumGray
        },
        "&:after": {
          // focused
          borderBottomColor: vars.actionBlue
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '3px'
      },
      input: {
				padding: '17px 11px 9px',
				fontSize: 16,
        '@media (min-width: 640px)': {
          fontSize: 12
        }
			},
    },
    MuiFormControl: {
      root: {
        marginTop: 0,
        marginBottom: 16,
        width: '100%'
      }
    },
		MuiInputLabel: {
			root: {
				fontSize: 12
			},
      formControl: {
        top: -5
      },
      filled: {
        color: vars.darkGray,
        "&$focused": {
          color: vars.actionBlue,
        },
        ".MuiFormControl-root:hover &:not($focused) &:not($disabled)": {
          color: vars.darkGray
        }
      }
    },
    MuiCheckbox: {
      root: {
        transform: 'scale(1.333)'
      },
      colorPrimary: {
        color: vars.mediumGray,
        '&$checked': {
          color: vars.black
        }
      },
      colorSecondary: {
        color: vars.mediumGray,
        '&$checked': {
          color: vars.white
        }
      },
    },
		MuiButton: {
			label: {
				fontSize: 12,
				fontWeight: 700,
			},
			root: {
				borderRadius: 0,
				boxShadow: 'none',
				height: 31,
        '@media (max-width: 479px)': {
          padding: '5px 22px !important'
        }
			},
			contained: {
				boxShadow: 'none',
			},
      containedPrimary: {
        color: vars.white
      },
      containedSecondary: {
        color: vars.darkGray
      }
		},
		MuiFormHelperText: {
			root: {
				color: colors.grey["900"],
				fontSize: 9,
				margin: 10,
				padding: '0',
        textAlign: 'right',
        position: 'absolute',
        top: '100%',
        right: 0
			},
			contained: {
				margin:'0 10px',
			}
		},
		MuiSvgIcon: {
			root: {
				fontSize: '1.125rem',
			},
		},
		MuiStepLabel: {
			iconContainer: {
				paddingRight: '0',
			},
		},
		MuiStepIcon: {
			root: {
				fontSize: '1.75rem',
			},
		},
    MuiInputBase: {
      root: {
        paddingTop: 0,
        paddingRight: 0,
      }
    },
    MuiSelect: {
      selectMenu: {
        '@media (max-width: 479px)': {
          maxWidth: '200px !important'
        }        
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '10px !important;',
      },
      option: {
        fontSize: 14
      },
      input: {
        fontSize: 16,
        '@media (min-width: 640px)': {
          fontSize: 12
        }
      }
    }
	},

  props: {
    MuiCheckbox: {
      color: 'primary'
    }
  },

	palette: {
		primary: {
			main: vars.primaryColorMain,
			dark: vars.primaryColorDark,
		},
		secondary: {
			main: vars.secondaryColorMain,
			dark: vars.secondaryColorDark,
		},
		accent: {
			main: vars.accentColorMain,
		},
		error: {
			main: vars.errorColorMain,
		},
		background: {
			default: vars.backgroundColorDefault,
			light: vars.backgroundColorLight
		},
	},
});

export default theme;