import { useDispatch } from 'react-redux';
import { setCurrentJob, setParsedSocResults } from 'app/slices/currentJobSlice';
import * as uploadMethods from '../uploadMethods';

export const useParseString = (callbacks = {}) => {

  const dispatch = useDispatch();

  const parseString = (formValues) => {
    console.log('parse string', formValues)
    uploadMethods.parseString({
      description_string: formValues.description,
      job_title: formValues.title
    })
      .then(({ skills, soc, ...res }) => {
        if (Object.keys(res).includes("status_code")) {
          if (res.status_code === 400 && res.detail === "Unable to parse.") {
            if ('onError' in callbacks) {
              callbacks.onError(res)
            }
          }
        } else {
          dispatch(setParsedSocResults(soc));
          const updatedJob = {
            ...formValues,
            skills: skills.value,
            soc_code: soc.code
          }
          dispatch(setCurrentJob(updatedJob))
          if ('onSuccess' in callbacks) {
            callbacks.onSuccess(updatedJob)
          }
        }
      }).catch(error => {
        if ('onError' in callbacks) {
          callbacks.onError(error)
        }
      })
  }

  return parseString;
}

export const useParseFile = (callbacks) => {

  const dispatch = useDispatch();

  const parseFile = (fileContents, fileExtension, formValues) => {
    uploadMethods.parse({
      contents: fileContents,
      ext: fileExtension,
      job_title: formValues.title
    })
      .then(({ resume_text, soc, skills, ...res }) => {
        if (Object.keys(res).includes("status_code")) {
          if (res.status_code === 400 && res.detail === "Unable to parse.") {
            if ('onError' in callbacks) {
              callbacks.onError(res)
            }
            return;
          }
        }

        if (resume_text.trim() === formValues.title.trim()) {
          console.log('file body was not parsed: placeholder')
        }

        dispatch(setParsedSocResults(soc));
        const updatedJob = {
          ...formValues,
          description: resume_text,
          skills: skills.value,
          soc_code: soc.code
        }
        dispatch(setCurrentJob(updatedJob))
        if ('onSuccess' in callbacks) {
          callbacks.onSuccess(updatedJob)
        }

      }).catch(error => {
        if ('onError' in callbacks) {
          callbacks.onError(error)
        }
      })
  }

  return parseFile;
}