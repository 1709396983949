import * as employerMethods from './employerMethods';
import * as jobMethods from './jobMethods';
import * as profileMethods from './profileMethods';
import * as matchMethods from './matchMethods';
import * as uploadMethods from './uploadMethods';
import * as naicsMethods from './naics';

import * as employer from './hooks/employerHooks';
import * as job from './hooks/jobHooks';
import * as match from './hooks/matchHooks';
import * as upload from './hooks/uploadHooks';

const apiHooks = {
  employer,
  job,
  match,
  upload,
}

export {
  employerMethods,
  jobMethods,
  profileMethods,
  matchMethods,
  uploadMethods,
  naicsMethods,
  apiHooks
}