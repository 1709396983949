import { encodeString } from "lib/file";
import { apiRequest } from "./config";

const UPLOAD_ENDPOINT = '/upload/'

export const parse = ({ contents, ext, job_title }) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", UPLOAD_ENDPOINT, {
      data: {
        contents,
        ext,
        job_title
      }
    }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const parseString = ({ description_string, job_title }) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", UPLOAD_ENDPOINT, {
      data: {
        contents: encodeString(description_string),
        ext: ".txt",
        job_title
      }
    }
    // apiRequest("post", `${UPLOAD_ENDPOINT}string/`, {
    //   data: {
    //     contents: description_string,
    //     // ext: ".txt",
    //     job_title
    //   }
    // }
    ).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

//example response:
// {
//   skills: {
//     value: [
//       Active Directory",
//       Agile Methodologies",
//       Banking",
//       Blogging",
//       Budgeting",
//       Business Development",
//       Business Intelligence",
//       Business Process Improvement",
//       Cardiology"
//     ],
//     timestamp: "20230110144227"
//   },
//   soc: {
//     code: "192011",
//     salary: {
//       Pct10: 59420,
//       Pct25: 79010,
//       Median: 114590,
//       Pct75: 160650,
//       Pct90: 185780
//     },
//     url: "https://www.careeronestop.org/Toolkit/Careers/Occupations/occupation-profile.aspx?keyword=Astronomers&location=UNITED%20STATES&onetcode=19201100"
//   },
//   resume_text: `data engineer about you you are experienced in building data pipelines end to end 
                  // utilizing engineering best practices you have been hands on building modern data pipelines
                  // from data in through to data out in the cloud and are able to articulate tradeoffs in your
                  // architectural decisions on solutions you have worked on  you know its better to deliver something
                  // real today over something maybe better later you believe in the power of a projects mvp and
                  // are able to appropriately scope out projects into sizeable chunks that provide value  you
                  // believe in the test and learn framework to get feedback from end users quickly  you are a
                  // skilled written communicator zapier is a 100 remote team and writing is our primary means of
                  // communication you can communicate about technical topics without unnecessary jargon you are
                  // able to express your ideas in a non threatening and open way your documentation is thorough easy
                  // to follow and well organized  you value testing your work properly before sharing with end users
                  // the quality of your work and making sure its properly tested is important to you  you are a
                  // strong proponent of automated testing and ci cd  you understand the power unlocked by building
                  // observable systems  tracking data flow through systems helps to shorten downtime  when building
                  // new capabilities for stakeholders you consider how the data flow can be observed  you appreciate
                  // our teams values of eagerness to collaborate with diverse teammates from any function of the
                  // organization or with any level of data knowledge iterating over your deliverables and being curious
                  // you strive to learn about our stakeholders needs to accelerate the use of data so that we can
                  // improve our suite of solutions for zapiens to use all stakeholders have different needs that
                  // evolve over time and you enjoy spending time learning about those needs and bringing those
                  // learnings to the team  things you might do develop capabilities that are efficient and reliable
                  // when ingesting and transforming data from product databases and apis into the data warehouse our
                  // stack includes aws tooling redshift glue s3 terraform gitlab dbt astronomer airflow kafka
                  // kubernetes and python work with our stakeholders in data insights and other product teams and
                  // help them surface interesting data back in the product for our users and partners to see
                  // implement new capabilities from scratch or improve upon existing ones to ensure they meet the
                  // needs of stakeholders as a part of zapiers all hands philosophy help customers via support or
                  // other mechanisms to ensure they have the best experience possible`
// }
