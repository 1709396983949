import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { propOr } from 'ramda';
import { useLocation } from 'react-router-dom';

/* Material UI and other UI Dependencies */
import { Container, CircularProgress, Box } from '@material-ui/core';
import OnboardingHeader from 'components/OnboardingHeader';
import { placeholderData } from 'components/CandidateConsent';
import CandidateConsentForm, { OptOutModal } from 'components/CandidateConsent/CandidateConsentForm';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { publicQueryFn } from 'app/queries';
import { optOutContact } from 'lib/API';

/* Styles */
import { css } from '@emotion/css';

const styles = {
  dontShareMessage: css({
    position: 'relative',
    top: '-22px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    span: {
      backgroundColor: 'rgb(220 224 237)',
      padding: '6px 12px',
      borderRadius: '4px',
      fontSize: 14,
    },
  }),
};

const CandidateConsent = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [optOutSuccess, setOptOutSuccess] = useState(false);
  const { profileId } = useParams();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const handleOptOut = async () => {
    setModalOpen(true);
    await optOutContact(profileId);
    setOptOutSuccess(true);
  };

  useEffect(() => {
    if (activePath === 'unsubscribe') {
      handleOptOut();
    }
  }, [activePath]);

  const notRefetch = {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  };
  const profileQuery = useQuery({
    queryKey: [`/profiles/${profileId}`],
    ...notRefetch,
    queryFn: publicQueryFn,
  });
  useEffect(() => {
    if (profileQuery.isSuccess) {
      setLoading(false);
    }
  }, [profileQuery.isSuccess, profileQuery.data]);

  const candidateDataAfterGetRequestUsingId = profileQuery.isSuccess
    ? {
        id: profileId,
        first_name: profileQuery.data?.contact_information?.first_name,
        last_name: profileQuery.data?.contact_information?.last_name,
        phone_number: profileQuery.data?.contact_information?.phone_number,
        email: profileQuery.data?.contact_information?.email,
        school_name: profileQuery.data?.contact_information?.school_name,
        field: profileQuery.data?.contact_information?.field_of_study,
        degree: profileQuery.data?.contact_information?.education_level,
        experience: profileQuery.data?.work_experiences,
      }
    : {
        ...placeholderData.placeholderCandidateObject_new,
        id: profileId,
      };

  if (activePath === 'unsubscribe') {
    return (
      <Container maxWidth="lg">
        <OnboardingHeader />
        <OptOutModal
          open={modalOpen}
          success={optOutSuccess}
          successMessage={intl.formatMessage({ id: 'consentForm.optOutSuccessGlobal' })}
        />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <OnboardingHeader />
      {loading ? (
        <Box display="flex" justifyContent="center" mb={10} mt={10}>
          <CircularProgress size={110} thickness={4} />
        </Box>
      ) : (
        <>
          <CandidateConsentForm
            candidateData={candidateDataAfterGetRequestUsingId}
            jobList={profileQuery.data?.matches}
            data={profileQuery.data}
          />
          <div className={styles.dontShareMessage}>
            <span>{intl.formatMessage({ id: 'consentForm.endMessage' })}</span>
          </div>
        </>
      )}
    </Container>
  );
};

export default CandidateConsent;
