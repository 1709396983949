import { useState } from 'react';
import { useFormikContext, useField } from 'formik';

import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { InputTextField } from 'components/inputs';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

/* Utils */
import { preventSubmitOnEnter } from 'lib/formHelpers';
import { educationLevelsIntlDict } from 'lib/education';

const styles = {
  textFieldWhite: css(
    {
      backgroundColor: `${cssVars.white} !important`,
      borderRadius: '3px 3px 0 0 !important',
      input: {
        backgroundColor: cssVars.white,
        borderRadius: '4px 4px 0 0',
      },
    },
    {
      label: 'text-field-white',
    }
  ),
  submitBtn__container: css(
    {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    {
      label: 'submit-button__container',
    }
  ),
  formContainer: css(
    {
      maxWidth: '568px',
      margin: '0 auto',
    },
    {
      label: 'form-container',
    }
  ),
  formBorder: css(
    {
      border: `solid 1px ${cssVars.midLightGray}`,
      padding: '8px',
      position: 'relative',
      boxSizing: 'border-box',
      minHeight: '301px',
      '@media screen and (min-width: 672px)': {
        padding: '1.15em',
        paddingBottom: '1em',
      },
    },
    {
      label: 'form-border',
    }
  ),
};

const DegreeDropdown = ({ sectionDisabled }) => {
  const intl = useIntl();
  const [educationLevelOpen, setEducationLevelOpen] = useState(false);
  const [fieldInputProps, fieldMetaProps] = useField('degree');
  const hasError = fieldMetaProps.touched && Boolean(fieldMetaProps.error);

  return (
    <FormControl variant="filled" error={hasError} disabled={sectionDisabled}>
      <InputLabel id="education-level-label">
        {intl.formatMessage({ id: 'forms.onboarding.educationLevel.titlecase' })}
      </InputLabel>
      <Select
        labelId="education-level-label"
        id="education-level"
        classes={{ root: styles.textFieldWhite }}
        onKeyDown={preventSubmitOnEnter}
        disabled={sectionDisabled}
        onOpen={() => setEducationLevelOpen(true)}
        onClose={() => setEducationLevelOpen(false)}
        open={educationLevelOpen}
        MenuProps={{
          PaperProps: {
            variant: 'outlined',
          },
        }}
        {...fieldInputProps}
      >
        {Object.entries(educationLevelsIntlDict).map(([level, intlId]) => (
          <MenuItem key={level} value={level} style={{ fontSize: 14 }}>
            {intlId ? intl.formatMessage({ id: intlId, description: 'string' }) : ''}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText>{intl.formatMessage({ id: fieldMetaProps.error })}</FormHelperText>}
    </FormControl>
  );
};

export default function CandidateEducation({ sectionDisabled }) {
  const intl = useIntl();
  const formikContext = useFormikContext();

  return (
    <Box mt={4}>
      <Typography variant="h2" gutterBottom>
        {intl.formatMessage({ id: 'consentForm.education' })}
      </Typography>
      <Box mt={1} mb={2}>
        <Typography variant="caption">{intl.formatMessage({ id: 'consentForm.educationSubHeader' })}</Typography>
      </Box>
      <div>
        <DegreeDropdown sectionDisabled={sectionDisabled} />
        {formikContext.values.degree > 12 && (
          <InputTextField formikId={'school_name'} formattedLabelText="School Name" />
        )}
        {formikContext.values.degree > 12 && <InputTextField formikId={'field'} formattedLabelText="Field of Study" />}
        {formikContext.touched.degree && (
          <>
            <Box mb={2} />
            <Typography variant="h2">{intl.formatMessage({ id: 'consentForm.additionalEducation' })}</Typography>
            <InputTextField formikId={'additional_education'} formattedLabelText="Additional Education" />
          </>
        )}
      </div>
    </Box>
  );
}
