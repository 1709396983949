import { Box, CircularProgress } from '@material-ui/core';

export default function LoadingIcon() {
  return (
    <>
      <Box display="flex" justifyContent="center" alignContent={"center"} my={14}>
        <CircularProgress size={110} thickness={4} />
      </Box>
    </>
  );
}
