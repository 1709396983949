import { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

import { Box, FormControl, FormControlLabel, Checkbox, Button, Typography } from '@material-ui/core';
import { InputTextField, DateInput } from 'components/inputs';
import CloseIcon from '@material-ui/icons/Close';

/* Utils */
import { useIntl } from 'react-intl';

const styles = {
  helperText: css({
    padding: '0',
    position: 'absolute',
    top: '100%',
    right: '0',
    lineHeight: '1.25',
    '@media screen and (max-width: 671px)': {
      margin: '0 5px',
    },
  }),
  textFieldWhite: css({
    backgroundColor: 'white !important',
    borderRadius: '3px 3px 0 0 !important',
    input: {
      backgroundColor: 'white',
      borderRadius: '4px 4px 0 0',
    },
  }),
  formControlRoot: css({
    display: 'inline-block !important',
    width: 'auto !important', //overriding style defined in ui-theme.js
    marginBottom: '0 !important', //overriding style defined in ui-theme.js
  }),
  formControlLabelRoot: css({
    display: 'inline-block',
    marginRight: '6px !important',
    '@media screen and (max-width: 767px)': {
      marginRight: '3px !important',
    },
  }),
  jobContainer: css({
    border: 'solid 1px #DBDBDB',
    padding: '8px',
    position: 'relative',
    '@media screen and (min-width: 672px)': {
      padding: '1.15em',
      paddingBottom: '1em',
    },
  }),
  jobContainerRed: css({
    border: `solid 1px ${cssVars.errorColorMain}`,
  }),
  jobExperienceRemoveButton: css({
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    border: `solid 2px ${cssVars.actionBlue}`,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    fontSize: '10px',
    padding: 0,
    backgroundColor: cssVars.white,
    svg: {
      fontSize: '20.3px',
      color: cssVars.actionBlue,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: cssVars.lightGray,
    },
  }),
  formControlLabeLabel: css({
    fontSize: '0.8rem !important',
    color: cssVars.darkGray,
    '@media screen and (max-width: 767px)': {
      fontSize: '0.68rem !important',
    },
  }),
  formError: css({
    color: cssVars.errorColorMain,
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    position: 'relative',
    top: '-10px',
  }),
  jobExperienceSection: css({
    display: 'grid',
    padding: '10px 0 0 12px',
    gridTemplateColumns: '1fr 1fr',
    gap: '34px',
    '@media screen and (max-width: 671px)': {
      gridTemplateColumns: '1fr',
      padding: 0,
      gap: 0,
    },
  }),
};

export const jobShape = {
  title: '',
  start_date: '',
  end_date: '',
  // type: '',
  // branch_of_service: '',
  // rank: '',
  // full_time: '',
};

export const jobKeys = Object.keys(jobShape).reduce((keyDir, k) => ({ ...keyDir, [k]: k }), {});

const CurrentJobCheckbox = ({ isCurrentJob, changeHandler, ...otherProps }) => {
  const intl = useIntl();
  return (
    <Box marginTop="-18px" marginLeft={'1em'} display="flex" justifyContent="flex-end" alignItems={'center'}>
      <FormControl
        classes={{
          root: styles.formControlRoot,
        }}
      >
        <FormControlLabel
          classes={{
            root: styles.formControlLabelRoot,
            label: 'MuiTypography-caption',
          }}
          label={intl.formatMessage({ id: 'consentForm.currentJob' })}
          control={
            <Checkbox
              checked={isCurrentJob}
              onChange={changeHandler}
              inputProps={{
                value: 'is-current-job',
              }}
            />
          }
          labelPlacement="end"
          {...otherProps}
        />
      </FormControl>
    </Box>
  );
};

const JobDateRange = ({ formikRoot }) => {
  const intl = useIntl();
  const [isCurrentJob, setIsCurrentJob] = useState(false);
  const formikContext = useFormikContext();

  const handleChange = (event) => {
    event.preventDefault();
    if (isCurrentJob) {
      formikContext.setFieldValue(`${formikRoot}.${jobKeys.end_date}`, '');
      setIsCurrentJob(false);
    } else {
      formikContext.setFieldValue(`${formikRoot}.${jobKeys.end_date}`, 'currentJob');
      setIsCurrentJob(true);
    }
  };

  return (
    <>
      <InputTextField
        formikId={`${formikRoot}.${jobKeys.title}`}
        formattedLabelText={intl.formatMessage({ id: 'consentForm.jobTitle' })}
      />
      <Box display="flex">
        <InputTextField
          formikId={`${formikRoot}.${jobKeys.start_date}`}
          formattedLabelText={intl.formatMessage({ id: 'consentForm.startDate' })}
          muiProps={{
            InputProps: {
              inputComponent: DateInput,
            },
          }}
          inputProps={{
            date_placeholder: 'MM/YY',
            date_mask: 'MMYYYY',
          }}
        />
        {isCurrentJob ? (
          <CurrentJobCheckbox isCurrentJob={isCurrentJob} changeHandler={handleChange} />
        ) : (
          <>
            <Box px={{ xs: 1, sm: 2 }} style={{ position: 'relative', top: 10 }}>
              {'to'}
            </Box>
            <InputTextField
              formikId={`${formikRoot}.${jobKeys.end_date}`}
              formattedLabelText={intl.formatMessage({ id: 'consentForm.endDate' })}
              muiProps={{
                InputProps: {
                  inputComponent: DateInput,
                },
              }}
              inputProps={{
                date_placeholder: 'MM/YY',
                date_mask: 'MMYYYY',
              }}
            />
          </>
        )}
      </Box>
      {!isCurrentJob && <CurrentJobCheckbox isCurrentJob={isCurrentJob} changeHandler={handleChange} />}
    </>
  );
};

export const CandidateExperience = ({ sectionDisabled = false }) => {
  const intl = useIntl();
  const { values: formikValues } = useFormikContext();

  return (
    <Box mt={4}>
      <Typography variant="h2">{intl.formatMessage({ id: 'consentForm.workExperience' })}</Typography>
      <Box mt={1} mb={2}>
        <Typography variant="caption">{intl.formatMessage({ id: 'consentForm.workExperienceSubHeader' })}</Typography>
      </Box>
      <FieldArray
        name={'experience'}
        render={(arrayHelpers) => (
          <Box display="flex" flexDirection={'column'} alignItems={'center'}>
            {!sectionDisabled &&
              formikValues.experience.map((job, index) => {
                const formikRoot = `experience[${index}]`;

                return (
                  <Box
                    key={index}
                    position={'relative'}
                    padding="1em"
                    border="1px solid silver"
                    borderRadius={'.25em'}
                    marginBottom={'1em'}
                    bgcolor={'#F8F9FD'}
                    width={'100%'}
                  >
                    <JobDateRange formikRoot={formikRoot} />
                    <button
                      type="button"
                      className={styles.jobExperienceRemoveButton}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <CloseIcon />
                    </button>
                  </Box>
                );
              })}
            {formikValues.experience.length < 3 && (
              <Button
                color="primary"
                variant="outlined"
                disabled={sectionDisabled}
                onClick={() => arrayHelpers.push({ ...jobShape })}
              >
                + {intl.formatMessage({ id: 'consentForm.addExperience' })}
              </Button>
            )}
          </Box>
        )}
      />
    </Box>
  );
};
