import cn from 'classnames';

/* Styles */
import styles from 'styles/candidates.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* Material UI and other UI Dependencies */
import {
  FormControl,
  Checkbox
} from "@material-ui/core";
import { SkillCellContents, ExperienceCellContents, EducationCellContents } from './SkillCheck';

/* utils */
import { getAverage, getIdValuesFromCategory, containsSameIds } from './helper';

/* REDUX */
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCandidates } from 'app/slices/candidateSlice';

/* i18n */
import { useIntl } from 'react-intl';

const DropdownRow = (props) => {

  const {
    category,
    groupedByCategory,
    ArrowDropDownIcon,
    clicked,
    candidatesSubmitted,
    userSelectedCandidates,
    submittedPage,
    Plane,
    mc,
    archived,
    toggleCandidateSelected,
    urlPrefix,
    jobId,
    detailsPath,
    handleViewDetailsClick,
    selected_skills,
    job,
    handleUnfold
  } = props

  const dispatch = useDispatch()
  const intl = useIntl()
  const { selected_candidates, submittingCandidates } = useSelector((state) => state.candidates);

  const handleSelectAll = () => {
    const groupIds = groupedByCategory.map(c => c.id);
    if (!selected_candidates.some((value) => groupIds.includes(value))) {
      dispatch(setSelectedCandidates(Array.from(new Set([...selected_candidates, ...groupIds]))))
    } else {
      const updatedCandidates = selected_candidates.filter((value) => !groupIds.includes(value));
      dispatch(setSelectedCandidates(updatedCandidates));
    }
  }

  const averagePercent = getAverage(groupedByCategory, "match_score")
  const categIds = getIdValuesFromCategory(groupedByCategory)

  
  return (
    <div>
      <div key={mc.id} className={cn({
        [styles.tableRow]: true,
        [styles.tableRow__hidden]: candidatesSubmitted && selected_candidates
      })}>
          <div className={cn(styles.tableCell, styles.matchCell)}  onClick={() => handleSelectAll(category)}>
                <FormControl
                classes={{
                  root: onboardingStyles.formControlRoot
                }}
                >
                {
                  !archived && !submittedPage ? (
                    <Checkbox
                      disabled={submittingCandidates}
                      style={{ color: "#2E6AD1" }}
                      checked={containsSameIds(selected_candidates, categIds[0])} color="primary"
                      onChange={() => handleSelectAll(category)} name="part_time"
                      inputProps={{
                        "aria-label": intl.formatMessage({id: 'candidates.details.selectCandidate'})
                    }}
                    />
                    ) : null
                  }                    
              </FormControl>
            <span className={styles.matchPercentage}>{averagePercent}</span>
        </div>

        <div className={styles.dropdownrow__wrapper} onClick={handleUnfold}>
              <div>
                  <span className={styles.industryLabel}>{category}</span>
                  <span className={styles.industryLabel} style={{marginLeft: "10px"}}>({groupedByCategory.length})</span>
              </div>
                <div>
                  <span><ArrowDropDownIcon style={clicked ? {transform: "rotate(180deg)"} : {}}/></span>
                </div>
          </div> 
        
          {/* Skills checkboxes */}
          <div className={styles.tableSkillsGroup}>
            {
              selected_skills.map(ss => (
                <div key={ss} className={styles.skillCell}>
                  {job.categories[ss] ? (
                    <SkillCellContents skillId={ss} {...mc} />
                  ) : null}
                </div>
              ))
            }
            {/* {
              job.min_years_experience > 0 ? (
                <div className={styles.skillCell}>
                  <ExperienceCellContents {...mc} />
                </div>
              ) : null
            } */}
            {/* {
              job.min_education_level > 0 ? (
                <div className={styles.skillCell}>
                  <EducationCellContents {...mc} />
                </div>
              ) : null
            } */}
          </div>
      </div>
      </div>
         
  )
}


export default DropdownRow

  
  