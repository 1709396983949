/* Styles */
import styles from 'styles/skill-card.module.scss';
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import Typography from "@material-ui/core/Typography";

const ExperienceCard =({
  title,
  active = false,
  onClick = ()=>{}
}) => {

  return (
    <div className={cn({
      [styles.skillCard]: true,
      [styles.active]: active
    })} onClick={onClick}>
      <Typography variant="h5" component="span" align="center">
        {title}
      </Typography>
    </div>
  )
}

export default ExperienceCard;