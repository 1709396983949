import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { pathOr } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { clearCurrentJob } from 'app/slices/currentJobSlice';
import { clearNewJobId } from 'app/slices/onboardingSlice';

/* Form */
// import { withFormikDevtools } from "formik-devtools-extension";
import { Form, Formik } from 'formik';
import { useJobValidation } from 'components/forms/validation';
import { JobFormSections } from './sections';
import JobSkillsSection from './sections/JobSkillsSection';
import JobRequirementsSection from 'components/forms/job/sections/JobRequirementsSection';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* API */
import { apiHooks } from 'lib/api/index';

/* Material UI and other UI Dependencies */
import { Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Loading from 'components/Loading';

const AddJobForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const { job: formInitialValues } = useSelector((state) => state.currentJob);
  const { zip_code: employerZip } = useSelector((state) => state.employer);

  const [submittingForm, setSubmittingForm] = useState(false);

  const validationSchema = useJobValidation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = () => {
    history.goBack();
  };

  const createJob = apiHooks.job.useCreateJob({
    onSuccess: (response) => {
      const newJobObject = pathOr(response, [0], response);
      setSubmittingForm(false);
      history.push(`${urlPrefix}/jobs/${newJobObject.id}/match`);
    },
    onError: (error) => {
      console.log(error);
      setSubmittingForm(false);
      enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
    },
  });

  useEffect(() => {
    dispatch(clearCurrentJob());
    dispatch(clearNewJobId());

    return () => {
      dispatch(clearCurrentJob());
    };
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      initialValues={{
        ...formInitialValues,
        zip_code: employerZip,
      }}
      validationSchema={validationSchema}
      onSubmit={(formValues) => {
        trackEvent('NEW_JOB_SAVE');
        setSubmittingForm(true);
        createJob({
          ...formValues,
          selected_skills: formValues.selected_skills.map((sk) => (sk[intl.locale] ? sk[intl.locale] : sk.en)),
        });
      }}
    >
      {(formikProps) => {
        // withFormikDevtools(formikProps);

        if (submittingForm) {
          return <Loading.Icon />;
        }

        return (
          <div className={styles.descriptionContent}>
            <Form onSubmit={formikProps.handleSubmit} className={cn(styles.loginFlow__form, styles.signInFormNoStripe)}>
              <div className={styles.descriptionFormCard}>
                <JobFormSections.Info />
              </div>
              <div className={styles.descriptionFormCard}>
                <JobFormSections.Description />
              </div>
              {/* temporarily disabled until the requirements are related to the match score */}
              {/* <div className={styles.descriptionFormCard}>
                      <JobFormSections.Requirements />
                    </div> */}
              <div className={styles.descriptionFormCard}>
                <JobSkillsSection />
              </div>
              {formikProps.values.title && formikProps.values.description ? (
                <div className={styles.descriptionFormCard}>
                  <JobRequirementsSection />
                </div>
              ) : null}
              <Box className={styles.submitBtn__container} mt={4} display="flex" justifyContent="flex-end">
                <Box mr={1}>
                  <Button
                    disabled={formikProps.isSubmitting || submittingForm}
                    size="large"
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={handleCancel}
                  >
                    {intl.formatMessage({ id: 'jobs.modal.cancel' })}
                  </Button>
                </Box>
                <Button
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {formikProps.isSubmitting ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                    </div>
                  ) : (
                    intl.formatMessage({ id: 'jobs.description.save' })
                  )}
                </Button>
              </Box>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddJobForm;
