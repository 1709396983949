import React from 'react'
import NumberFormat from 'react-number-format'

export default function DateInput(props) {

  const { inputRef, onChange, date_placeholder = 'MM/YYYY', date_mask = ['M', 'M', 'Y', 'Y', 'Y', 'Y'], ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      displayType={"input"}
      type={"text"}
      format="##/####"
      placeholder={date_placeholder}
      mask={date_mask.split('')}
      disabled={props.disabled}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
    />
  );
}