export const welcomeText = [
	`Congratulations!  An employer using Ready Hire has indicated that they would like to contact you about a job opening based on your skills and experience.`,
	`If you would like to be contacting about this job opening, please provide your contact information and detailed work history using the form below.`
];

export const placeholderCandidateObject = {
	id: '123',
	first_name: 'Wilhelmina',
	last_name: 'George',
	email: 'wgeorge@domain.com',
	phone_number: '8550000000',
	education_level: 12.0,
	skills: [],
	soc_code: '',
	soc_title: '',
	years_experience: 0,
	last_updated: Date('2022-01-01')
}

export const placeholderCandidateObject_new = {
	id: '',
	first_name: '',
	last_name: '',
	email: '',
	phone_number: '',
	min_education_level: 0,
	skills: [],
	soc_code: '',
	soc_title: '',
	years_experience: 0,
}

export const placeholderJobObject = {
	id: '456',
	employer_id: '789',
	title: 'Front Desk Attendant',
	description: 'Attend the front desk',
	certifications: [],
	min_education_level: 0,
	zip_code: 'str',
	part_time: false,
	remote: false,
	willing_to_train: false,
	min_years_experience: 0,
	skills: [],
	selected_skills: [],
	archived: false,
	last_updated: Date('2022-01-01')
}

export const placeholderJobList = Array(4).fill(placeholderJobObject).map((job, idx) => ({ ...job, id: `${job.id}-${idx}` }))