import JobInfoSection from "./JobInfoSection";
import JobDescriptionSection from "./JobDescriptionSection/JobDescriptionSection";
import JobDescriptionSuccessSection from "./JobDescriptionSection/JobDescriptionSuccessSection";
import JobRequirementsSection from "./JobRequirementsSection";
import JobSkillsSection from "./JobSkillsSection";

const JobFormSections = {
  Info: JobInfoSection,
  Description: JobDescriptionSection,
  DescriptionSuccess: JobDescriptionSuccessSection,
  Requirements: JobRequirementsSection,
  Skills: JobSkillsSection
}

export {
  JobFormSections,
  JobInfoSection,
  JobDescriptionSection,
  JobDescriptionSuccessSection,
  JobRequirementsSection,
  JobSkillsSection
};