import { useState } from "react"
import cn from 'classnames';
import { Link, useParams } from "react-router-dom";

/* Styles */
import styles from 'styles/candidates.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* Material UI and other UI Dependencies */
import {
  Box,
  FormControl,
  Checkbox
} from "@material-ui/core";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SkillCellContents, ExperienceCellContents, EducationCellContents } from './SkillCheck';

/* Redux */
import { useSelector } from 'react-redux';

/* i18n */
import { useIntl } from 'react-intl';

const CategoryMobile = (props) => {

  const [clicked, setClicked] = useState(false)
  const { selected_candidates, submittingCandidates } = useSelector((state) => state.candidates);

  const intl = useIntl()

  const {
    category,
    groupedByCategory,
    job,
    submittedPage,
    Plane,
    toggleCandidateSelected,
    urlPrefix,
    jobId,
    detailsPath, 
    selected_skills,
    matchesTypes,
    activeTab,
    handlePotentialLinkClick,
    handleTopLinkClick,
    otherTabs,
    handleTrainableLinkClick,
    handleEditSkillsClick,
    matchesToShow
  } = props

  const handleUnfold = () => {
    setClicked(!clicked)
  }

  return (
    <div style={{display: "flex", flexDirection: 'column', backgroundColor: "white"}} onClick={handleUnfold}>
      <div style={{ display: "flex", padding: "20px 20px", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #e2e2e2"}}>
        <div>
            <span className={styles.industryLabel}>{category}</span>
            <span className={styles.industryLabel} style={{marginLeft: "10px"}}>({groupedByCategory.length})</span>
        </div>
          <div>
            <span ><ArrowDropDownIcon style={clicked ? {transform: "rotate(180deg)"} : {}}/></span>
          </div>
      </div>
      {clicked ? (
        <div style={{ display: "flex", flexDirection: 'column' }}>
         <div className={styles.mobileTable}>
        {matchesToShow.length > 0 ? matchesToShow.map(mc => {
          return (
            <div className={styles.mobileRow} key={mc.id}>
                  <div className={styles.mobileRow__top}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <span className={styles.matchPercentage}>{mc.match_score}</span>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <span className={styles.industryLabel}>{mc.soc_title}</span>
                      <span className={styles.lastJobLabel}>{mc.naics_title}</span>
                      <span className={styles.candidateId}>{mc.id}</span>
                    </Box>
                    <Box>
                    {(submittingCandidates && selected_candidates.indexOf(mc.id) > -1) || submittedPage ? (
                      <Plane className={styles.checkboxPlane} />
                    ) : (
                      <FormControl
                        classes={{
                          root: onboardingStyles.formControlRoot
                        }}
                      >
                        <Checkbox
                          checked={selected_candidates.indexOf(mc.id) > -1}
                          color="primary"
                          onChange={() => toggleCandidateSelected(mc.id)}
                          name="part_time"
                        />
                      </FormControl>
                    )}                  
                    </Box>
                  </div>
                  <div className={styles.mobileRow__bottom}>
                    <div>
                      {
                        selected_skills.map(ss => (
                          job.categories[ss] ? (
                            <div className={styles.mobileCheckContainer} key={ss}>
                              <SkillCellContents skillId={ss} {...mc} />
                            </div>
                          ) : null)
                        )
                      }
                      {
                        job.min_years_experience > 0 ? (
                          <div className={styles.mobileCheckContainer}>
                            <ExperienceCellContents {...mc} />
                          </div>
                        ) : null
                      }
                      {
                        job.min_education_level > 0 ? (
                          <EducationCellContents {...mc} />
                        ) : null
                      }
                    </div>
                    <Box>
                      <Link
                        to={`${urlPrefix}/jobs/${jobId}/${detailsPath}/${mc.id}`}
                        underline="always"
                        className={cn(layoutStyles.link, layoutStyles.linkSize, layoutStyles.noWrap)}
                      >{intl.formatMessage({id: 'jobs.matches.viewDetails'})}</Link>
                    </Box>                
                  </div>
                </div>
              )
            }) : (
              <>
                <div className={styles.tableNotMatchesRow}>
                  <span className={styles.noMatchesTitle}>{intl.formatMessage({id: 'jobs.matches.noCandidates'}, {type: intl.formatMessage({id: `jobs.matches.type.${matchesTypes[activeTab]}`})})}</span>
                  <div className={styles.noMatchesText}>
                    {intl.formatMessage({id: 'jobs.matches.toView'})} <span className={layoutStyles.link} onClick={activeTab === 0 ? handlePotentialLinkClick : handleTopLinkClick}>{intl.formatMessage({id: `jobs.matches.type.${otherTabs[0]}`})}</span> <span>{intl.formatMessage({id: 'jobs.matches.or'})}</span> <span className={layoutStyles.link} onClick={activeTab === 2 ? handlePotentialLinkClick : handleTrainableLinkClick}>{intl.formatMessage({id: `jobs.matches.type.${otherTabs[1]}`})}</span> <span>{intl.formatMessage({id: 'jobs.matches.candidatesOr'})}</span> <span className={layoutStyles.link} onClick={handleEditSkillsClick}>{intl.formatMessage({id: 'jobs.matches.addRemoveSkills'})}</span> <span>{intl.formatMessage({id: 'jobs.matches.changeSearchCriteria'})}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CategoryMobile

