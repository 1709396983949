import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { updateJobArchived, updateJobReactivate } from 'app/slices/jobsSlice';
import { setCurrentJob } from 'app/slices/currentJobSlice';

/* API */
// import { archiveJob } from 'lib/API';
import { apiHooks, jobMethods } from 'lib/api/index';

/* Forms */
import { Formik, Form } from 'formik';
// import { withFormikDevtools } from "formik-devtools-extension";
import { useJobValidation } from 'components/forms/validation';
import JobDescriptionSuccessSection from './sections/JobDescriptionSection/JobDescriptionSuccessSection';
import JobInfoSection from './sections/JobInfoSection';
import JobSkillsSection from './sections/JobSkillsSection';
import JobRequirementsSection from 'components/forms/job/sections/JobRequirementsSection';

/* Material UI and other UI Dependencies */
import { Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Loading from 'components/Loading';

const UpdateJobForm = ({ jobId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const { job } = useSelector((state) => state.currentJob);
  const { jobs } = useSelector((state) => state.jobs);
  const { selected_skills, ...formInitialValues } = job;
  const isArchived = jobs.reduce((acc, curr) => (curr.id === job.id && curr.archived ? true : acc), false);
  const jobReady = formInitialValues.hasOwnProperty('id') && formInitialValues.id === jobId;

  const [jobLoading, setJobLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useJobValidation({ withSelectedSkills: true });

  const updateJob = apiHooks.job.useUpdateJob(jobId, {
    onSuccess: (response) => {
      setSubmittingForm(false);
      enqueueSnackbar(intl.formatMessage({ id: 'jobs.description.changesSaved' }), { variant: 'success' });
      history.push(`${urlPrefix}/jobs`);
    },
    onError: (error) => {
      console.log(error);
      setSubmittingForm(false);
      enqueueSnackbar(intl.formatMessage({ id: 'jobs.description.updateJobError' }), { variant: 'error' });
    },
  });
  const parseDescriptionString = apiHooks.upload.useParseString();

  const handleArchiveJob = () => {
    setSubmittingForm(true);
    jobMethods
      .archive(job.id)
      .then(function () {
        dispatch(updateJobArchived(job.id));
        setSubmittingForm(false);
        history.push(`${urlPrefix}/jobs/archived`);
      })
      .catch(function (error) {
        enqueueSnackbar(intl.formatMessage({ id: 'jobs.description.archiveJobError' }), { variant: 'error' });
        setSubmittingForm(false);
      });
  };

  const handleReactivateJob = () => {
    setSubmittingForm(true);
    jobMethods
      .update(job.id, { archived: false })
      .then(function ({ id }) {
        dispatch(updateJobReactivate(job.id));
        setSubmittingForm(false);
        history.push(`${urlPrefix}/jobs`);
      })
      .catch(function () {
        enqueueSnackbar(intl.formatMessage({ id: 'jobs.description.reactivateJobError' }), { variant: 'error' });
        setSubmittingForm(false);
      });
  };

  useEffect(() => {
    if (!jobReady && !jobLoading) {
      setJobLoading(true);
      jobMethods
        .get(jobId)
        .then((response) => {
          dispatch(setCurrentJob(response));
          setJobLoading(false);
          if (response.description.length > 0 && response.skills.length === 0) {
            parseDescriptionString(response);
          }
        })
        .catch((error) => {
          console.log('job error', error);
          setJobLoading(false);
        });
    }
  }, [jobId, jobReady, jobLoading, parseDescriptionString, dispatch]);

  if (jobLoading) return <Loading.Icon />;

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={false}
      initialValues={{
        ...formInitialValues,
        selected_skills: selected_skills.map((skill) =>
          typeof skill === 'string' ? { ...{ en: skill }, ...{ [intl.locale]: skill } } : skill
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={(formValues) => {
        setSubmittingForm(true);
        updateJob({
          ...formValues,
          selected_skills: formValues.selected_skills.map((sk) => (sk[intl.locale] ? sk[intl.locale] : sk.en)),
        });
      }}
    >
      {(formikProps) => {
        if (submittingForm) {
          console.log('submitting form');
          return <Loading.Icon />;
        }
        return (
          <div className={styles.descriptionContent}>
            <Form>
              <div className={styles.descriptionFormCard}>
                <JobInfoSection />
              </div>
              <div className={styles.descriptionFormCard}>
                <JobDescriptionSuccessSection />
              </div>
              <div className={styles.descriptionFormCard}>
                <JobSkillsSection />
              </div>
              <div className={styles.descriptionFormCard}>
                <JobRequirementsSection />
              </div>
              <Box className={styles.submitBtn__container} mt={4} display="flex" justifyContent="flex-end">
                <Box mr={1}>
                  <Button
                    disabled={submittingForm}
                    size="large"
                    type="button"
                    variant="outlined"
                    color="error"
                    onClick={isArchived ? handleReactivateJob : handleArchiveJob}
                  >
                    {isArchived
                      ? intl.formatMessage({
                          id: 'jobs.description.reactivate',
                        })
                      : intl.formatMessage({ id: 'jobs.description.archive' })}
                  </Button>
                </Box>
                {!isArchived && (
                  <Button
                    disabled={submittingForm || !formikProps.isValid}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {formikProps.isSubmitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobs.description.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default UpdateJobForm;
