/* Styles */
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Box, Button } from '@material-ui/core';

const JobDescriptionFooter = ({
  hasTitle,
  hideUploaderHandler,
  showUploaderHandler,
  resetUploaderHandler,
  showFileUploader = false,
  uploaderError = false,
  uploaderSuccess = false,
  loading = false,
  showManualParseButton = false,
}) => {
  const intl = useIntl();

  const boxProps = {
    className: onboardingStyles.linkSize,
    mt: showFileUploader ? 2 : 0,
    mx: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  //this should really be a button or something for accessibility
  const fakeLinkClasses = cn({
    [layoutStyles.link]: true,
    [layoutStyles.link__inactive]: !hasTitle,
    [onboardingStyles.linkSize]: true,
  });

  if (loading) {
    return <Box {...boxProps}>{` Parsing...`}</Box>;
  }

  if (!hasTitle) {
    return (
      <Box {...boxProps}>
        <div className={onboardingStyles.linkSize} onClick={showUploaderHandler}>
          {`Please add a job title before submitting the description.`}
        </div>
      </Box>
    );
  }

  if (uploaderError) {
    return (
      <Box {...boxProps}>
        <div className={onboardingStyles.linkSize} style={{ marginRight: '.5em' }}>
          {'There was an error parsing your file. Please paste the text contents into the box above instead.'}
        </div>
        <Button color="primary" variant="outlined" onClick={resetUploaderHandler}>
          RETRY
        </Button>
      </Box>
    );
  }

  if (uploaderSuccess) {
    return (
      <Box {...boxProps}>
        <div className={onboardingStyles.linkSize} style={{ marginRight: '.5em' }}>
          {`${intl.formatMessage({
            id: showFileUploader ? 'jobs.description.document' : 'jobs.description.description',
          })} ${intl.formatMessage({ id: 'jobs.description.beenUploaded' })}`}
        </div>
        {!showFileUploader && (
          <Button color="primary" variant="outlined" onClick={resetUploaderHandler}>
            RESET
          </Button>
        )}
      </Box>
    );
  }

  if (showFileUploader) {
    return (
      <Box {...boxProps}>
        <div className={fakeLinkClasses} onClick={hideUploaderHandler}>
          {intl.formatMessage({ id: 'forms.onboarding.copyAndPaste' })}
        </div>
      </Box>
    );
  }

  if (showManualParseButton) {
    return <Box {...boxProps} />;
  }

  return (
    <Box {...boxProps}>
      <div className={fakeLinkClasses} onClick={showUploaderHandler}>
        {intl.formatMessage({ id: 'forms.onboarding.uploadDocument' })}
      </div>
    </Box>
  );
};

export default JobDescriptionFooter;
