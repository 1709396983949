import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/layout.module.scss';

/* Assets */
import { dltLogoUrl, consentLogoUrl } from 'styles/vars.module.scss';
import info from 'assets/images/Info_MainNav.svg';

/* Material UI and other UI Dependencies */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import withWidth from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLanguage } from 'app/slices/appSlice';

const Header = ({ locale, handleDrawerOpen, width, loggedIn, consentForm = false }) => {
  const smallScreen = ['xs', 'sm'].indexOf(width) > -1;
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const resetLanguage = () => {
    if (typeof window !== 'undefined') {
      const language = locale === 'en' ? 'es' : 'en';
      localStorage.setItem('lng', language);
      dispatch(setLanguage(language));
      const eventName = `MENU_${locale === 'en' ? 'ESPANOL' : 'ENGLISH'}`;
      trackEvent(eventName);
      window.location.href = locale === 'en' ? '/#/es/' : '/#/';
    }
    const menuItems = ['matches', 'submitted', 'saved', 'removed'];
    if (menuItems.indexOf(activePath) > -1) {
      trackEvent(`JOBS_${activePath.toUpperCase()}_HEADER_${locale === 'en' ? 'ESPANOL' : 'ENGLISH'}`);
    } else if (activePath === 'company-profile') {
      trackEvent(`COMPANY_PROFILE_MAIN_PAGE_HEADER_${locale === 'en' ? 'ESPANOL' : 'ENGLISH'}`);
    }
  };

  const handleInfoClick = (e) => {
    //e.preventDefault();
    handleDrawerOpen();
    const menuItems = ['matches', 'submitted', 'removed', 'saved'];
    if (menuItems.indexOf(activePath) > -1) {
      trackEvent(`JOBS_${activePath.toUpperCase()}_HEADER_INFO`);
    } else if (activePath === 'company-profile') {
      trackEvent('COMPANY_PROFILE_MAIN_PAGE_HEADER_INFO');
    }
  };

  return (
    <div className={styles.header}>
      <Container maxWidth="lg">
        <Grid container className={styles.headerContent}>
          <Grid item xs={5}>
            <Box display="flex">
              <Link to={`${urlPrefix}/`}>
                <img
                  className={cn({
                    [styles.header__dltLogo]: true,
                  })}
                  src={dltLogoUrl.slice(1, -1)}
                  alt="DLT Logo"
                />
              </Link>
              {consentForm ? (
                <img
                  className={cn({
                    [styles.header__consentLogo]: true,
                    [styles.hidden]: !smallScreen,
                  })}
                  src={consentLogoUrl.slice(1, -1)}
                  alt="DLT Logo"
                />
              ) : (
                <div
                  className={cn({
                    [styles.header__readyHireLogo]: true,
                    [styles.hidden]: !smallScreen,
                  })}
                >
                  <span>{intl.formatMessage({ id: 'layout.header.projectName' })}</span>
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={2} justifyContent="center" container>
            {consentForm ? (
              <img
                className={cn({
                  [styles.header__consentLogo]: true,
                  [styles.hidden]: smallScreen,
                })}
                src={consentLogoUrl.slice(1, -1)}
                alt="DLT Logo"
              />
            ) : (
              <div
                className={cn({
                  [styles.header__readyHireLogo]: true,
                  [styles.hidden]: smallScreen,
                })}
              >
                <span>{intl.formatMessage({ id: 'layout.header.projectName' })}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={5} justifyContent="flex-end" alignItems="center" container wrap="nowrap">
            <span
              className={cn({
                [styles.link]: true,
              })}
              onClick={resetLanguage}
            >
              <FormattedMessage id="layout.language" default="Español" />
            </span>
            {loggedIn ? (
              <>
                <span
                  className={cn({
                    [styles.link]: true,
                    [styles.infoIcon__container]: true,
                  })}
                  onClick={handleInfoClick}
                >
                  <img className={styles.infoIcon} alt="Information" src={info} />
                </span>
              </>
            ) : (
              <Box px={1} display="inline-block" />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(Header);
