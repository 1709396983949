import { useState } from "react"

/* Material UI and other UI Dependencies */
import CandidateRow from "./CandidateRow"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DropdownRow from "./DropdownRow";

const Category = (props) => {

  const [clicked, setClicked] = useState(false)

  const {
    category,
    groupedByCategory,
    job,
    mc,
    candidatesSubmitted,
    userSelectedCandidates,
    submittedPage,
    Plane,
    archived,
    toggleCandidateSelected,
    urlPrefix,
    jobId,
    detailsPath,
    handleViewDetailsClick,
    selected_skills,
    showMobile
  } = props

  const handleUnfold = (e) => {
    e.stopPropagation()
    setClicked(!clicked)
  }

  // const doUseDropdownRow = groupedByCategory.length > 1;

  return (
    <div style={{ display: "flex", flexDirection: 'column', cursor: "pointer" }} >
      {/* {(doUseDropdownRow) && */}
        <DropdownRow
          category={category}
          groupedByCategory={groupedByCategory}
          ArrowDropDownIcon={ArrowDropDownIcon}
          clicked={clicked}
          candidatesSubmitted={candidatesSubmitted}
          userSelectedCandidates={userSelectedCandidates}
          submittedPage={submittedPage}
          Plane={Plane}
          mc={groupedByCategory[0]}
          archived={archived}
          toggleCandidateSelected={toggleCandidateSelected}
          urlPrefix={urlPrefix}
          jobId={jobId}
          detailsPath={detailsPath}
          handleViewDetailsClick={handleViewDetailsClick}
          selected_skills={selected_skills}
          job={job}
          handleUnfold={handleUnfold}
        />
        {/* } */}
      {(clicked /*|| !doUseDropdownRow*/) ? (
        <div style={{ display: "flex", flexDirection: 'column' }}>
          {
            groupedByCategory.map(groupedData => (
              <CandidateRow
                key={groupedData.id}
                job={job}
                mc={groupedData}
                candidatesSubmitted={candidatesSubmitted}
                userSelectedCandidates={userSelectedCandidates}
                submittedPage={submittedPage}
                Plane={Plane}
                archived={archived}
                toggleCandidateSelected={toggleCandidateSelected}
                urlPrefix={urlPrefix}
                jobId={jobId}
                detailsPath={detailsPath}
                handleViewDetailsClick={handleViewDetailsClick}
                selected_skills={selected_skills}
              />
            ))
          }
        </div>
      ) : null}
    </div >
  )
}

export default Category