import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import vars from 'styles/vars.module.scss';
import SkillsAutocomplete from 'components/skillsAutocomplete';

/* API */
import { jobMethods, employerMethods } from 'lib/api/index';
import { apiHooks } from 'lib/api/index';

/* Styles */
import styles from 'styles/login.module.scss';
import cssVars from 'styles/vars.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';
import { darken } from 'polished';

/* i18n */
import { useIntl, FormattedNumber } from 'react-intl';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setUnrecoverableError } from 'app/slices/appSlice';
import { setEmployer } from 'app/slices/employerSlice';
import { setLoadingStepMessage, setCombinedSkills, setNeedsJobsOnboarding } from 'app/slices/onboardingSlice';
import { clearCurrentJob, setCurrentJob, setOnboardingSkills } from 'app/slices/currentJobSlice';

/* Material UI and other UI Dependencies */
import Typography from '@material-ui/core/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import { TextField, Button, Box, FormControl, FormControlLabel, Checkbox, Chip, Divider } from '@material-ui/core';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const stylesCustom = {
  chipRoot: css(
    {
      borderRadius: '0px !important',
      backgroundColor: `${cssVars.tagSkill} !important`,
      marginRight: '5px !important',
      marginBottom: '5px !important',
      '&:hover': {
        backgroundColor: `${darken(0.04, cssVars.tagSkill)} !important`,
      },
      height: '22px !important',
      '& .MuiChip-deleteIcon': {
        width: 14,
        height: 14,
      },
      '& .MuiChip-label': {
        fontWeight: 700,
        fontSize: '10px',
        color: cssVars.darkGray,
        textTransform: 'uppercase',
      },
    },
    {
      label: 'chip-root',
    }
  ),
  cardsContainer: css(
    {
      display: 'grid',
      gap: '16px',
      gridTemplateColumns: '1fr',
      paddingTop: '31px',
      '@media screen and (min-width: 672px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    { label: 'cards-container' }
  ),
};

export default function OnboardingSkills() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const { combinedSkills: skillsCombined, newJobId } = useSelector((state) => state.onboarding);
  const { job: persistedJobValues } = useSelector((state) => state.currentJob);
  const employerInfo = useSelector((state) => state.employer);
  const combinedTitles = skillsCombined.reduce((acc, curr) => {
    const newArr = [...acc];
    if (curr.en && !newArr.includes(curr.en)) newArr.push(curr.en);
    if (curr[intl.locale] && !newArr.includes(curr[intl.locale])) newArr.push(curr[intl.locale]);
    return newArr;
  }, []);
  const persistedFiltered = persistedJobValues.skills.filter((sk) => !combinedTitles.includes(sk));

  const validationSchema = yup.object({
    selected_skills: yup
      .array()
      .min(1, intl.formatMessage({ id: 'onboarding.skills.minSkillsValidation' }))
      .max(5, intl.formatMessage({ id: 'onboarding.skills.maxSkillsValidation' })),
  });

  const handleContinueToFinalStep = () => {
    dispatch(setNeedsJobsOnboarding(false));
    dispatch(setLoadingStepMessage(''));
    dispatch(clearCurrentJob());
    history.push(`${urlPrefix}/jobs/${newJobId}/match`);
  };

  const refreshJobs = apiHooks.job.useRefreshAllJobs({
    onSuccess: handleContinueToFinalStep,
  });

  const handleGoBack = () => {
    history.push(`${urlPrefix}/onboarding/job`);
  };

  const determineAOrAn = (word) => {
    if (!word || word.toLowerCase() === ('a' || 'an')) return;
    const firstLetter = word[0].toLowerCase();
    const isVowel = ['a', 'e', 'i', 'o', 'u'].includes(firstLetter);
    return isVowel ? 'an' : 'a';
  };

  const aOrAn = determineAOrAn(persistedJobValues.title);

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <OnboardingSteps activeStep={2} />
        <Box px={3} mt={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.skills.title' })}
          </Typography>
        </Box>
        <Box px={3} mt={1}>
          <Typography align="center" variant="h3" component="p">
            {/* logic for when to use a or an */}
            {intl.formatMessage({ id: 'onboarding.skills.subtitle1' }, { job: `${aOrAn} ${persistedJobValues.title}` })}
          </Typography>
          <Typography align="center" variant="h3" component="p">
            {intl.formatMessage({ id: 'onboarding.skills.subtitle2' })}
          </Typography>
        </Box>
      </div>
      <Divider />
      <div className={styles.loadingScreen__cardsContainer}>
        <Formik
          initialValues={{
            willing_to_train: false,
            selected_skills: skillsCombined,
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={(formValues) => {
            dispatch(setLoadingStepMessage('onboarding.skills.submit'));
            const jobValues = {
              ...persistedJobValues,
              selected_skills: formValues.selected_skills.map((sk) => (sk[intl.locale] ? sk[intl.locale] : sk.en)),
              willing_to_train: formValues.willing_to_train,
            };
            dispatch(setCurrentJob(jobValues));
            jobMethods
              .update(newJobId, jobValues)
              .then((jobResponse) => {
                console.log('response', jobResponse);
                refreshJobs();
              })
              .catch(function (jobError) {
                console.error(jobError);
                dispatch(setUnrecoverableError(true));
              });
            employerMethods
              .update({ soft_skills: formValues.soft_skills })
              .then(() => {
                dispatch(setEmployer({ soft_skills: formValues.soft_skills }));
              })
              .catch(function (jobError) {
                console.error(jobError);
              });
          }}
        >
          {({
            // handleSubmit,
            isSubmitting,
            isValid,
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <Form
                // onSubmit={handleSubmit}
                className={cn(
                  styles.loginFlow__form,
                  styles.signInFormNoStripe,
                  onboardingStyles.formContainer,
                  layoutStyles.relative
                )}
              >
                <Box display="flex" justifyContent="flex-start" mb={1}>
                  <Typography variant="h2" component="span">
                    {intl.formatMessage(
                      { id: 'forms.onboarding.education.recommendedSkillsSection' },
                      { companyName: employerInfo.name }
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="center" variant="caption" gutterBottom>
                    {intl.formatMessage({ id: 'forms.onboarding.skills.recommendedSkills' })}
                  </Typography>
                </Box>
                {persistedFiltered.length > 0 ? (
                  <div style={{ marginTop: '10px' }}>
                    {/* <Box m={3} /> */}
                    <div>
                      {/* suggested skills */}
                      {persistedFiltered.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          onClick={() => {
                            if (
                              skillsCombined.filter((sk) => sk[intl.locale] === skill || sk.en === skill).length === 0
                            ) {
                              const newCombinedSkills = [
                                ...skillsCombined,
                                { ...{ en: skill }, ...{ [intl.locale]: skill } },
                              ];
                              setFieldValue('selected_skills', newCombinedSkills);
                              dispatch(setCombinedSkills(newCombinedSkills));

                              // Remove the added skill from the suggestions
                              const skillIndex = persistedJobValues.skills.indexOf(skill);
                              const arrayCopy = persistedJobValues.skills.concat([]);
                              arrayCopy.splice(skillIndex, 1);
                              dispatch(setOnboardingSkills(arrayCopy));
                            }
                          }}
                          onDelete={() => {
                            const skillIndex = persistedJobValues.skills.indexOf(skill);
                            const arrayCopy = persistedJobValues.skills.concat([]);
                            arrayCopy.splice(skillIndex, 1);
                            dispatch(setOnboardingSkills(arrayCopy));
                          }}
                          classes={{ root: stylesCustom.chipRoot }}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {skillsCombined && (
                  <Box className={styles.errorMessage__container} mt={1}>
                    <label className={styles.errorMessage}>{errors.selected_skills}</label>
                  </Box>
                )}
                <SalaryWidget />
                <Box mt={3} />
                <SkillsAutocomplete
                  activeSkills={skillsCombined}
                  addSkillHandler={(newSkill) => {
                    if (skillsCombined.filter((sk) => sk === newSkill).length === 0) {
                      setFieldValue('selected_skills', [...skillsCombined, newSkill]);
                      dispatch(setCombinedSkills([...skillsCombined, newSkill]));
                    }
                  }}
                  removeSkillHandler={(newSkill) => {
                    const newCombinedSkills = skillsCombined.filter(
                      (sk) => sk[intl.locale] !== newSkill.en && sk.en !== newSkill.en
                    );
                    setFieldValue('selected_skills', newCombinedSkills);
                    dispatch(setCombinedSkills(newCombinedSkills));
                  }}
                  onboarding={true}
                />
                <Box mt={2} />

                {false && (
                  <>
                    <Box>
                      <FormControl
                        classes={{
                          root: onboardingStyles.formControlRoot,
                        }}
                      >
                        <FormControlLabel
                          classes={{
                            root: onboardingStyles.formControlLabelRoot,
                            label: onboardingStyles.formControlLabeLabel,
                          }}
                          label={intl.formatMessage({ id: 'onboarding.skills.willingToTrain' })}
                          control={
                            <Checkbox
                              value={values.willing_to_train}
                              checked={values.willing_to_train}
                              color="primary"
                              onChange={(e) =>
                                handleChange({ target: { name: 'willing_to_train', value: e.currentTarget.checked } })
                              }
                              name="willing_to_train"
                            />
                          }
                          labelPlacement="end"
                        />
                      </FormControl>
                    </Box>
                    <Box mt={3} />
                  </>
                )}

                <Box display="flex" justifyContent="flex-start" mt={4} mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'onboarding.skills.successful' }, { companyName: employerInfo.name })}
                  </Typography>
                </Box>
                <TextField
                  FormHelperTextProps={{
                    className: styles.helperText,
                  }}
                  error={touched.soft_skills && Boolean(errors.soft_skills)}
                  name="soft_skills"
                  type="text"
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: 'onboarding.skills.softSkills' })}
                  multiline
                  helperText={touched.soft_skills && errors.soft_skills}
                  autoComplete="off"
                  value={values.soft_skills}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  minRows={4}
                  inputProps={{
                    'aria-label': intl.formatMessage({ id: 'onboarding.skills.softSkills' }),
                  }}
                />
                <Box className={styles.submitBtn__container} mt={4}>
                  <Button size="large" variant="contained" onClick={handleGoBack}>
                    {intl.formatMessage({ id: 'forms.onboarding.back' })}
                  </Button>

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                  >
                    {intl.formatMessage({ id: 'forms.onboarding.next', description: 'string' })}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const SalaryWidget = () => {
  const intl = useIntl();
  const { careerUrl, job, salaryRange } = useSelector((state) => state.currentJob);

  if (!salaryRange || !Object.keys(salaryRange).includes('Pct10' || 'Pct90')) {
    return null;
  }

  const handleMoreClick = () => {
    trackEvent('ONBOARDING_SKILLS_MORE_ONESTOP');
  };

  return (
    <Box className={onboardingStyles.salaryWidget} mt={3}>
      <Box fontWeight="fontWeightBold" fontSize={12} style={{ color: vars.darkGray }}>
        {intl.formatMessage({ id: 'onboarding.skills.widget.salaryRange' })}
      </Box>
      <Typography align="center" variant="caption">
        {job.title}
      </Typography>
      <Typography align="center" variant="caption" gutterBottom>
        $<FormattedNumber value={salaryRange.Pct10} currency="USD" /> - $
        <FormattedNumber value={salaryRange.Pct90} currency="USD" />
      </Typography>
      <a
        target="_blank"
        className={layoutStyles.link}
        rel="noreferrer"
        href={careerUrl}
        align="center"
        onClick={handleMoreClick}
      >
        <Box fontSize={12}>{intl.formatMessage({ id: 'onboarding.skills.widget.more' })}</Box>
      </a>
    </Box>
  );
};
