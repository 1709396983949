import { useState, useMemo } from 'react';

/* Form */
import { FieldArray, useFormikContext } from 'formik';

/* Redux */
import { useSelector } from 'react-redux';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Typography, Box, Chip, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import SkillsAutocomplete from 'components/skillsAutocomplete';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const stylesCustom = {
  chipRoot: css(
    {
      borderRadius: '0px !important',
      backgroundColor: `${cssVars.midLightGray} !important`,
      marginRight: '5px !important',
      marginBottom: '5px !important',
      '&:hover': {
        backgroundColor: `${cssVars.tagSkill} !important`,
      },
      height: '22px !important',
      '& .MuiChip-deleteIcon': {
        width: 14,
        height: 14,
      },
      '& .MuiChip-label': {
        fontWeight: 700,
        fontSize: '10px',
        color: cssVars.darkGray,
        textTransform: 'uppercase',
      },
      '&.selectedChip': {
        backgroundColor: `${cssVars.tagSkill} !important`,
      },
    },
    {
      label: 'chip-root',
    }
  ),
  cardsContainer: css(
    {
      display: 'grid',
      gap: '16px',
      gridTemplateColumns: '1fr',
      paddingTop: '31px',
      '@media screen and (min-width: 672px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    { label: 'cards-container' }
  ),
  errorMessage__container: css({
    marginBottom: '1rem',
  }),
  errorMessage: css({
    fontSize: '0.8rem',
    color: cssVars.errorColorMain,
  }),
};

const JobSkillsSection = () => {
  const intl = useIntl();
  const employerInfo = useSelector((state) => state.employer);
  const formikContext = useFormikContext();
  const { values: formikValues } = formikContext;
  const [activeSkills, setActiveSkills] = useState(formikValues.selected_skills);

  const combinedTitles = activeSkills.reduce((acc, curr) => {
    const newArr = [...acc];
    if (curr.en && !newArr.includes(curr.en)) newArr.push(curr.en);
    if (curr[intl.locale] && !newArr.includes(curr[intl.locale])) newArr.push(curr[intl.locale]);
    return newArr;
  }, []);
  const recommendedSkills = formikValues.skills.filter((sk) => !combinedTitles.includes(sk));

  return (
    <>
      <FieldArray
        name="selected_skills"
        render={(arrayHelpers) => {
          return (
            <>
              {/* <Box mb={1}>
          <Typography align="center" variant="caption" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.skills.addSkillLabel' })}
          </Typography>
        </Box> */}
              <Box>
                <Typography variant="h2" component="header">
                  {intl.formatMessage(
                    { id: 'forms.onboarding.education.recommendedSkillsSection' },
                    { companyName: employerInfo.name }
                  )}
                </Typography>
              </Box>
              <Box mt={1} mb={2}>
                {recommendedSkills.length > 0 ? (
                  <div style={{ marginTop: '10px' }}>
                    {/* <Box m={3} /> */}
                    {activeSkills && (
                      <Box className={stylesCustom.errorMessage__container} mt={1}>
                        <label className={stylesCustom.errorMessage}>{formikContext.errors.selected_skills}</label>
                      </Box>
                    )}
                    <div>
                      {/* suggested skills */}
                      {recommendedSkills.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          onClick={() => {
                            if (
                              activeSkills.filter((sk) => sk[intl.locale] === skill || sk.en === skill).length === 0
                            ) {
                              const newActiveSkills = [
                                ...activeSkills,
                                { ...{ en: skill }, ...{ [intl.locale]: skill } },
                              ];
                              formikContext.setFieldValue('selected_skills', newActiveSkills);
                              setActiveSkills(newActiveSkills);
                            }
                          }}
                          onDelete={() => {
                            const skillIndex = formikValues.skills.indexOf(skill);
                            const arrayCopy = formikValues.skills.concat([]);
                            arrayCopy.splice(skillIndex, 1);
                            formikContext.setFieldValue('skills', arrayCopy);
                          }}
                          classes={{ root: stylesCustom.chipRoot }}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </Box>
              <Box>
                <SkillsAutocomplete
                  activeSkills={activeSkills}
                  addSkillHandler={(newSkill) => {
                    if (activeSkills.filter((sk) => sk === newSkill).length === 0) {
                      formikContext.setFieldValue('selected_skills', [...activeSkills, newSkill]);
                      setActiveSkills([...activeSkills, newSkill]);
                    }
                  }}
                  removeSkillHandler={(newSkill) => {
                    const newCombinedSkills = activeSkills.filter(
                      (sk) => sk[intl.locale] !== newSkill.en && sk.en !== newSkill.en
                    );
                    formikContext.setFieldValue('selected_skills', newCombinedSkills);
                    setActiveSkills(newCombinedSkills);
                  }}
                  onboarding={true}
                />
              </Box>
              {false && (
                <FormControl
                  classes={{
                    root: onboardingStyles.formControlRoot,
                  }}
                >
                  <FormControlLabel
                    classes={{
                      root: onboardingStyles.formControlLabelRoot,
                      label: onboardingStyles.formControlLabeLabel,
                    }}
                    label={intl.formatMessage({ id: 'onboarding.skills.willingToTrain' })}
                    control={
                      <Checkbox
                        value={formikContext.values.willing_to_train}
                        checked={formikContext.values.willing_to_train}
                        color="primary"
                        onChange={(e) =>
                          formikContext.handleChange({
                            target: { name: 'willing_to_train', value: e.currentTarget.checked },
                          })
                        }
                        name="willing_to_train"
                      />
                    }
                    labelPlacement="end"
                  />
                </FormControl>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default JobSkillsSection;
