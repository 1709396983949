import { apiRequest } from 'lib/API';

export const defaultQueryFn = async ({ queryKey }) => {
  const data = await apiRequest("get", queryKey[0]);
  return data;
};

export const publicQueryFn = async ({ queryKey }) => {
  const data = await apiRequest("get", queryKey[0], {}, true);
  return data;
};

export const queryWithParams = async ({ queryKey }) => {
  const [url, params] = queryKey;
  const data = await apiRequest("get", url, { params });
  return data;
};
