import { apiRequest } from "./config";

const JOBS_ENDPOINT = '/users/me/jobs';

export const getAll = (jobId) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${JOBS_ENDPOINT}/${jobId}/matches`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const getAlternateMatchScore = (jobId, skillsList = []) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${JOBS_ENDPOINT}/${jobId}/matches`, {
      params: {
        skills: skillsList.join(",")
      }
    })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * This function generates the matches for a specific job
 **/
export const create = (jobId) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", `${JOBS_ENDPOINT}/${jobId}/matches`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const get = (jobId, matchId) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${JOBS_ENDPOINT}/${jobId}/matches/${matchId}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const update = (jobId, matchId, data) => {

  return new Promise((resolve, reject) => {
    apiRequest("put", `${JOBS_ENDPOINT}/${jobId}/matches/${matchId}`, {
      data
    }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const updateNotes = (jobId, matchId, newNotes) => {

  return new Promise((resolve, reject) => {
    apiRequest("put", `${JOBS_ENDPOINT}/${jobId}/matches/${matchId}`, {
      data: { notes: newNotes }
    }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
}

export const archive = (jobId, matchId) => {
  return new Promise((resolve, reject) => {
    apiRequest("delete", `${JOBS_ENDPOINT}/${jobId}/matches/${matchId}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const contact = (jobId, matchId) => {
  return new Promise((resolve, reject) => {
    apiRequest("post", `${JOBS_ENDPOINT}/${jobId}/matches/${matchId}/contact`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}