import { useState } from 'react';
import { useFormikContext } from 'formik';

import { useIntl } from 'react-intl';

/* Redux */
import { useDispatch } from 'react-redux';
import { setCurrentJob, clearParsedFileResults } from 'app/slices/currentJobSlice';

/* Material UI and other UI Dependencies */
import { Box, Typography } from '@material-ui/core';
import JobDescriptionSection from './JobDescriptionSection';
import { FileUploadedView } from './JobDescriptionFileUpload';
import JobDescriptionFooter from './JobDescriptionFooter';

//we only keep the parsed skills, description, and soc code on the job object
//the user doesn't technically need to interact with the original description
//use this component in place of JobDescriptionSection
//i.e. updating an existing job
//if they want to load or type in a new description, they click the reset button
//and a blank JobDescriptionSection is returned instead of this one
const JobDescriptionSuccessSection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const formikContext = useFormikContext();

  const [resetParser, setResetParser] = useState(false);

  const clearUploadResults = () => {
    formikContext.setFieldValue('description', '');
    formikContext.setFieldValue('skills', []);
    formikContext.setFieldValue('soc_code', '');
    dispatch(clearParsedFileResults());
    dispatch(
      setCurrentJob({
        ...formikContext.values,
        description: '',
        skills: [],
        soc_code: '',
      })
    );
  };

  const handleParserReset = () => {
    clearUploadResults();
    setResetParser(true);
  };

  if (resetParser) {
    return <JobDescriptionSection />;
  }
  console.log(JSON.stringify(formikContext.initialValues));
  return (
    <>
      <Box display="flex" justifyContent="flex-start" mt={2} mb={2} className="job-form-section">
        <Typography variant="h2" gutterBottom>
          {intl.formatMessage({ id: 'forms.onboarding.jobDescription' })}
        </Typography>
      </Box>
      <FileUploadedView
        fileName={intl.formatMessage({ id: 'jobs.description.clickToRemove' })}
        resetHandler={handleParserReset}
        apiError={false}
        loading={false}
      />
      <JobDescriptionFooter
        hasTitle={true}
        uploaderSuccess={true}
        showFileUploader={true}
        uploaderError={false}
        loading={false}
        hideUploaderHandler={null}
        showUploaderHandler={null}
        resetUploaderHandler={null}
      />
    </>
  );
};

export default JobDescriptionSuccessSection;
