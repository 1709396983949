import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { phoneRegExp } from 'lib/formHelpers';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { employerMethods } from 'lib/api/index';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setUnrecoverableError } from 'app/slices/appSlice';
import {
  setOnboardingMaxStep,
  setLoadingStepMessage,
  setNeedsUserOnboarding,
  setNeedsJobsOnboarding,
  setContactUpdate,
} from 'app/slices/onboardingSlice';
import { setEmployer } from 'app/slices/employerSlice';

/* Material UI and other UI Dependencies */
import OnboardingSteps from 'components/OnboardingSteps';
import {
  CircularProgress,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { InputTextField, PhoneNumberInput, ZIPCodeInput } from 'components/inputs';
import { EmployerFormSections } from './employer/sections';

/* Analytics */
import { trackEvent } from 'lib/analytics';

export default function OnboardingSignUp() {
  const signUpFormInitialValues = useSelector((state) => state.employer);
  const { contactUpdate } = useSelector((state) => state.onboarding);

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const validationSchema = yup.object({
    name: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.companyNameValidation' }))
      .min(2, intl.formatMessage({ id: 'forms.onboarding.companyNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'forms.onboarding.companyNameMaxLength' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.companyNameRequired' })),
    contact: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.contactNameValidation' }))
      .min(2, intl.formatMessage({ id: 'forms.onboarding.contactNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'forms.onboarding.contactNameMaxLength' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.contactNameRequired' })),
    phone_number: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.primaryPhone' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'forms.onboarding.primaryPhoneValidation' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.primaryPhoneRequired' })),
    zip_code: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.zipcode' }))
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'forms.onboarding.onlyDigits' }))
      .min(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 }))
      .max(5, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits' }, { amount: 5 }))
      .required(intl.formatMessage({ id: 'forms.onboarding.zipcodeRequired' })),
    tos: yup.bool().oneOf([true], intl.formatMessage({ id: 'forms.onboarding.tosError' })),
  });

  const handlePostSuccess = (values) => {
    if (values) {
      dispatch(setEmployer(values));
    }
    dispatch(setContactUpdate(false));
    dispatch(setOnboardingMaxStep(1));
    dispatch(setNeedsJobsOnboarding(true));
    dispatch(setNeedsUserOnboarding(false));
    history.push(`${urlPrefix}/onboarding/job`);
    dispatch(setLoadingStepMessage(''));
  };

  const handleTosClick = () => {
    trackEvent('ONBOARDING_SIGNUP_TERMS_CONDITIONS');
  };

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <OnboardingSteps activeStep={0} />
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.signUp.title' })}
          </Typography>
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{
          ...signUpFormInitialValues,
          tos: contactUpdate ? true : false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitting(true);
          dispatch(setLoadingStepMessage('onboarding.signUp.submit'));
          trackEvent('ONBOARDING_SIGNUP_NEXT');
          const { tos, profileCompletion, ...postBody } = values;

          let actionFunc = 'id' in signUpFormInitialValues ? employerMethods.update : employerMethods.create;

          actionFunc(postBody)
            .then(function (response) {
              console.log('created employer', response);
              handlePostSuccess(values);
            })
            .catch(function (error) {
              console.log(error);
              if (error.response?.data?.detail === 'The user with this sub already exists in the system.') {
                handlePostSuccess(values);
              } else {
                dispatch(setUnrecoverableError(true));
              }
            });
        }}
      >
        {({ values, touched, errors, isValid, handleBlur, handleChange }) => {
          return (
            <div className={styles.loadingScreen__cardsContainer}>
              <Form
                style={{ paddingBottom: '0px' }}
                className={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
              >
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'forms.onboarding.sections.company' })}
                  </Typography>
                  <Typography variant="caption" component="span" gutterBottom>
                    *{intl.formatMessage({ id: 'forms.onboarding.required' })}
                  </Typography>
                </Box>
                <InputTextField
                  formikId={'name'}
                  formattedLabelText={`${intl.formatMessage({ id: 'forms.onboarding.companyName' })}*`}
                />
                <InputTextField
                  formikId={'contact'}
                  formattedLabelText={`${intl.formatMessage({ id: 'forms.onboarding.contactName' })}*`}
                />
                <InputTextField
                  formikId={'phone_number'}
                  formattedLabelText={`${intl.formatMessage({ id: 'forms.onboarding.primaryPhone' })}*`}
                  muiProps={{
                    InputProps: {
                      inputComponent: PhoneNumberInput,
                    },
                  }}
                />
                <InputTextField
                  formikId={'zip_code'}
                  formattedLabelText={`${intl.formatMessage({ id: 'forms.onboarding.zipcode' })}*`}
                  muiProps={{
                    InputProps: {
                      inputComponent: ZIPCodeInput,
                    },
                  }}
                />
                <Box display="flex" justifyContent="flex-start" mt={2} mb={2}>
                  <Typography variant="h2" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'company.about.title' }, { companyName: 'Acme Corporation' })}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" mb={2}>
                  <Typography variant="h3" component="span" gutterBottom>
                    {intl.formatMessage({ id: 'company.about.subtitle' }, { companyName: 'Acme Corporation' })}
                  </Typography>
                </Box>
                <InputTextField
                  formikId={'description'}
                  formattedLabelText={intl.formatMessage({ id: 'company.about.placeholder' })}
                  muiProps={{
                    variant: 'outlined',
                    multiline: true,
                    minRows: 4,
                    maxRows: 8,
                    placeholder: intl.formatMessage({ id: 'company.about.placeholder' }),
                  }}
                  inputProps={{
                    'aria-label': intl.formatMessage({ id: 'company.about.title' }),
                    onFocus: () => trackEvent('COMPANY_PROFILE_MAIN PAGE_COMPANY_DESCRIPTION'),
                  }}
                />
                <div className={onboardingStyles.tosContainer}>
                  <FormControl
                    classes={{
                      root: onboardingStyles.formControlRoot,
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        root: onboardingStyles.formControlLabelRoot,
                        label: onboardingStyles.formControlLabeLabel,
                      }}
                      label={intl.formatMessage({ id: 'forms.onboarding.tos' })}
                      control={
                        <Checkbox
                          value={values.tos}
                          checked={values.tos}
                          color="primary"
                          onChange={(e) => handleChange({ target: { name: 'tos', value: e.currentTarget.checked } })}
                          name="tos"
                        />
                      }
                      labelPlacement="end"
                    />
                    <a
                      href="/#/terms-of-use"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={handleTosClick}
                      className={onboardingStyles.tosLink}
                    >
                      {intl.formatMessage({ id: 'forms.onboarding.tosLink' })}
                    </a>
                    {touched.tos && errors.tos && (
                      <FormHelperText classes={{ root: onboardingStyles.tosHelperTextRoot }}>
                        {errors.tos}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className={styles.submitBtnSecond__container}>
                  <Button disabled={submitting} size="large" type="submit" variant="contained" color="primary">
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobs.modal.saving' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'forms.onboarding.next' })
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}
