import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { useIntl } from "react-intl";

/* Redux */
import { useDispatch } from "react-redux";
import {
  setCurrentJob,
  clearParsedFileResults,
} from "app/slices/currentJobSlice";

/* Material UI and other UI Dependencies */
import { Box, Typography } from "@material-ui/core";
import JobDescriptionFileUpload from "./JobDescriptionFileUpload";
import JobDescriptionTextInput from "./JobDescriptionTextInput";
import JobDescriptionFooter from "./JobDescriptionFooter";

/* Analytics */
import { trackEvent } from "lib/analytics";

const JobDescriptionSection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const formikContext = useFormikContext();

  const [showManualParseButton, setShowManualParseButton] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [parsingSkills, setParsingSkills] = useState(false);
  const [uploaderError, setUploaderError] = useState(false);
  const [uploaderSuccess, setUploaderSuccess] = useState(false);

  const hasTitle =
    formikContext.values.title && formikContext.values.title.length > 0;
  const hasDescription = formikContext.values.description.length > 0;
  const hasSkills = formikContext.values.skills.length > 0;

  useEffect(() => {
    if (
      (showFileUploader || hasSkills || !hasDescription || uploaderError) &&
      showManualParseButton
    ) {
      setShowManualParseButton(false);
    }

    if (!hasSkills && hasDescription && !uploaderError && !uploaderSuccess) {
      if (!showManualParseButton && !showFileUploader) {
        setShowManualParseButton(true);
      }
    }
  }, [
    showFileUploader,
    hasDescription,
    showManualParseButton,
    hasSkills,
    uploaderError,
    uploaderSuccess,
  ]);

  const clearUploadResults = () => {
    formikContext.setFieldValue("description", "");
    formikContext.setFieldValue("skills", []);
    formikContext.setFieldValue("soc_code", "");
    dispatch(clearParsedFileResults());
    dispatch(
      setCurrentJob({
        ...formikContext.values,
        description: "",
        skills: [],
        soc_code: "",
      })
    );
  };

  const handleShowUploader = () => {
    trackEvent("ONBOARDING_JOB_UPLOAD");
    clearUploadResults();
    setShowFileUploader(true);
  };

  const handleHideUploader = () => {
    trackEvent("ONBOARDING_JOB_PASTE_DESC");
    clearUploadResults();
    setShowFileUploader(false);
  };

  const handleParserReset = () => {
    console.log("parser reset from description section");
    setUploaderError(false);
    setUploaderSuccess(false);
    handleShowUploader();
  };

  const handleParserError = (errorObject = {}) => {
    console.error("parser error", errorObject);
    setParsingSkills(false);
    setUploaderError(true);
    formikContext.setFieldValue("skills", []);
    formikContext.setFieldValue("soc_code", "");
  };

  const handleParserResults = (updatedFormValues) => {
    setUploaderSuccess(true);
    setParsingSkills(false);
    formikContext.resetForm({
      values: {
        ...updatedFormValues,
      },
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        mt={2}
        mb={2}
        className="job-form-section"
      >
        <Typography variant="h2" gutterBottom>
          {intl.formatMessage({ id: "forms.onboarding.jobDescription" })}
        </Typography>
      </Box>
      {showFileUploader && !uploaderError ? (
        <JobDescriptionFileUpload
          resultHandler={handleParserResults}
          errorHandler={handleParserError}
          resetHandler={handleParserReset}
          loading={parsingSkills}
          loadingSetter={setParsingSkills}
        />
      ) : (
        <JobDescriptionTextInput
          resultHandler={handleParserResults}
          errorHandler={handleParserError}
          loading={parsingSkills}
          loadingSetter={setParsingSkills}
          showButton={showManualParseButton}
        />
      )}
      <JobDescriptionFooter
        hasTitle={hasTitle}
        hideUploaderHandler={handleHideUploader}
        showUploaderHandler={handleShowUploader}
        uploaderError={uploaderError}
        uploaderSuccess={uploaderSuccess}
        showFileUploader={showFileUploader}
        showManualParseButton={showManualParseButton}
        loading={parsingSkills}
        resetUploaderHandler={handleParserReset}
      />
    </>
  );
};

export default JobDescriptionSection;
