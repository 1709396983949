import * as yup from 'yup';

/* i18n */
import { useIntl } from 'react-intl';

/* Utils */
import * as utils from 'lib/formHelpers';
import * as dateUtils from 'lib/date';

const useFormValidation = () => {
  const intl = useIntl();

  return {
    firstNameValidation: yup
      .string(intl.formatMessage({ id: 'consentForm.firstNameRequired' }))
      .min(2, intl.formatMessage({ id: 'consentForm.firstNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'consentForm.firstNameMaxLength' }))
      .required(intl.formatMessage({ id: 'consentForm.firstNameRequired' })),
    lastNameValidation: yup
      .string(intl.formatMessage({ id: 'consentForm.lastNameRequired' }))
      .min(2, intl.formatMessage({ id: 'consentForm.lastNameMinLength' }))
      .max(50, intl.formatMessage({ id: 'consentForm.lastNameMaxLength' }))
      .required(intl.formatMessage({ id: 'consentForm.lastNameRequired' })),
    phoneNumberValidation: yup
      .string(intl.formatMessage({ id: 'company.details.Phone' }))
      .matches(utils.phoneRegExp, intl.formatMessage({ id: 'company.details.PhoneValidation' }))
      .required(intl.formatMessage({ id: 'company.details.PhoneRequired' })),
    emailValidation: yup
      .string()
      .matches(utils.emailRegExp, intl.formatMessage({ id: 'forms.signIn.matchEmail' }))
      .required(intl.formatMessage({ id: 'company.details.emailRequired' })),
  };
};

export default useFormValidation;

export const useWorkHistoryValidation = (currentJob, secondCurrentJob, thirdCurrentJob, jobsCount) => {
  // const intl = useIntl();
  const errors = {};

  const makeValidate = (values) => {
    if (!values.title) {
      errors.title = 'jobSearchActivity.form.fieldRequired';
    } else if (values.title.length < 3) {
      errors.title = 'forms.onboarding.minLenght';
    }

    if (!values.recent_job_from) {
      errors.recent_job_from = 'jobSearchActivity.form.fieldRequired';
    } else if (!utils.dateRegExp.test(values.recent_job_from)) {
      errors.recent_job_from = 'onboarding.experience.mmyyFormat';
    } else if (dateUtils.isFutureDate(values.recent_job_from)) {
      errors.recent_job_from = 'onboarding.experience.dateInFuture';
    }

    if (!currentJob) {
      if (!values.recent_job_to) {
        errors.recent_job_to = 'jobSearchActivity.form.fieldRequired';
      } else if (!utils.dateRegExp.test(values.recent_job_to)) {
        errors.recent_job_to = 'onboarding.experience.mmyyFormat';
      } else if (dateUtils.isFutureDate(values.recent_job_to)) {
        errors.recent_job_to = 'onboarding.experience.dateInFuture';
      } else {
        const start = new Date(values.recent_job_from.split('/').join('/01/'));
        const end = new Date(values.recent_job_to.split('/').join('/01/'));
        if (start > end) {
          errors.recent_job_to = 'onboarding.experience.endDatePrior';
        }
      }
    }

    if (jobsCount > 1) {
      if (!values.other_job1) {
        errors.other_job1 = 'jobSearchActivity.form.fieldRequired';
      } else if (values.other_job1.length < 3) {
        errors.other_job1 = 'forms.onboarding.minLenght';
      }

      if (!values.other_job1_from) {
        errors.other_job1_from = 'jobSearchActivity.form.fieldRequired';
      } else if (!utils.dateRegExp.test(values.other_job1_from)) {
        errors.other_job1_from = 'onboarding.experience.mmyyFormat';
      } else if (dateUtils.isFutureDate(values.other_job1_from)) {
        errors.other_job1_from = 'onboarding.experience.dateInFuture';
      }
    }

    if (!secondCurrentJob && jobsCount > 1) {
      if (!values.other_job1_to) {
        errors.other_job1_to = 'jobSearchActivity.form.fieldRequired';
      } else if (!utils.dateRegExp.test(values.other_job1_to)) {
        errors.other_job1_to = 'onboarding.experience.mmyyFormat';
      } else if (dateUtils.isFutureDate(values.other_job1_to)) {
        errors.other_job1_to = 'onboarding.experience.dateInFuture';
      }

      if (!errors.other_job1_from && !errors.other_job1_to) {
        const start = new Date(values.other_job1_from.split('/').join('/01/'));
        const end = new Date(values.other_job1_to.split('/').join('/01/'));
        if (start > end) {
          errors.other_job1_to = 'onboarding.experience.endDatePrior';
        }
      }
    }

    if (jobsCount > 2) {
      if (!values.other_job2) {
        errors.other_job2 = 'jobSearchActivity.form.fieldRequired';
      } else if (values.other_job2.length < 3) {
        errors.other_job2 = 'forms.onboarding.minLenght';
      }

      if (!values.other_job2_from) {
        errors.other_job2_from = 'jobSearchActivity.form.fieldRequired';
      } else if (!utils.dateRegExp.test(values.other_job2_from)) {
        errors.other_job2_from = 'onboarding.experience.mmyyFormat';
      } else if (dateUtils.isFutureDate(values.other_job2_from)) {
        errors.other_job2_from = 'onboarding.experience.dateInFuture';
      }
    }

    if (!thirdCurrentJob && jobsCount > 2) {
      if (!values.other_job2_to) {
        errors.other_job2_to = 'jobSearchActivity.form.fieldRequired';
      } else if (!utils.dateRegExp.test(values.other_job2_to)) {
        errors.other_job2_to = 'onboarding.experience.mmyyFormat';
      } else if (dateUtils.isFutureDate(values.other_job2_to)) {
        errors.other_job2_to = 'onboarding.experience.dateInFuture';
      }

      if (!errors.other_job2_from && !errors.other_job2_to) {
        const start = new Date(values.other_job2_from.split('/').join('/01/'));
        const end = new Date(values.other_job2_to.split('/').join('/01/'));
        if (start > end) {
          errors.other_job2_to = 'onboarding.experience.endDatePrior';
        }
      }
    }

    return errors;
  };

  return makeValidate;
};
