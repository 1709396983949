import { useFormikContext } from "formik";

/* i18n */
import { useIntl } from "react-intl";

/* Material UI and other UI Dependencies */
import { Box, Typography } from "@material-ui/core";
import {
  InputTextField,
  EducationLevelSelect,
  JobCertifications,
} from "components/inputs";

const JobRequirementsSection = ({ displayOnLoad = false }) => {
  const intl = useIntl();
  const formikContext = useFormikContext();
  const hasTitle =
    formikContext.values.title && formikContext.values.title.length;
  const hasDescription =
    formikContext.values.description && formikContext.values.description.length;

  if ((!hasTitle || !hasDescription) && !displayOnLoad) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        mt={4}
        mb={1}
        className="job-form-section"
      >
        <Typography variant="h2" gutterBottom>
          {intl.formatMessage({
            id: "forms.onboarding.educationAndExperience",
          })}
        </Typography>
      </Box>
      <Box mt={1}>
        <EducationLevelSelect />
      </Box>
      {false && (
        <Box mt={1}>
          <JobCertifications />
        </Box>
      )}
      <Box mt={1}>
        <InputTextField
          formikId={"min_years_experience"}
          formattedLabelText={intl.formatMessage({
            id: "forms.onboarding.yearsOfExperienceLabel",
            description: "string",
          })}
          inputProps={{
            "aria-label": intl.formatMessage({
              id: "forms.onboarding.yearsOfExperienceLabel",
            }),
          }}
          muiProps={{
            type: "number",
          }}
        />
      </Box>
    </>
  );
};

export default JobRequirementsSection;
