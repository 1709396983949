import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { pathOr } from "ramda";

/* Styles */
import styles from "styles/candidates.module.scss";

/* Redux */
import { useDispatch } from "react-redux";
import { setFromDetails } from "app/slices/appSlice";

/* Forms */
import MatchForm from "components/forms/match";

/* Material UI and other UI Dependencies */
import { Box, Container, Divider } from "@material-ui/core";
import Loading from "components/Loading";
import CandidateHeader from "./CandidateHeader";
import CandidateInfoSection from "./CandidateInfoSection";
import CandidateSkillsSections from "./CandidateSkillsSections";

/* Misc */
import * as utils from "./utils";

const CandidateDetails = () => {
  const { pathname } = useLocation();
  const active_route = utils.getActiveRoute(pathname);

  const { id: jobId, matchId } = useParams();
  const dispatch = useDispatch();

  const notRefetch = {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  };
  const matchQuery = useQuery({
    queryKey: [`/users/me/jobs/${jobId}/matches/${matchId}`],
    ...notRefetch,
  });

  useEffect(function () {
    dispatch(setFromDetails(true));
  });

  return (
    <div className={styles.jobsMatchesWrapper}>
      <Container maxWidth="md">
        <CandidateHeader />
        <Divider />
        {matchQuery.isFetched && !matchQuery.isFetching ? (
          <>
            <CandidateInfoSection
              {...pathOr({}, ["data", "match"], matchQuery)}
            />
            <CandidateSkillsSections
              {...pathOr({}, ["data", "match"], matchQuery)}
              jobId={jobId}
            />
            <Box paddingY={2}>
              <div className={styles.candidateDetailsCard}>
                <MatchForm.UpdateNotes
                  jobId={jobId}
                  matchId={matchId}
                  active_route={active_route}
                />
              </div>
            </Box>
          </>
        ) : (
          <Loading.Icon />
        )}
      </Container>
      {active_route !== "submitted" &&
      matchQuery.isFetched &&
      !matchQuery.isFetching ? (
        <MatchForm.UpdateStatus
          jobId={jobId}
          matchId={matchId}
          active_route={active_route}
          currentMatch={pathOr({}, ["data", "match"], matchQuery)}
        />
      ) : null}
    </div>
  );
};

export default CandidateDetails;
