import { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { pathOr } from 'ramda';
import { contact } from 'lib/api/matchMethods';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import candidatesStyles from 'styles/candidates.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setJobMatches } from 'app/slices/jobsSlice';
import { setSelectedCandidates, setSubmittingCandidates } from 'app/slices/candidateSlice';

/* API */
import { queryWithParams } from 'app/queries';

/* Material UI and other UI Dependencies */
import { Button, Box, Tooltip, Typography, Container } from '@material-ui/core';
import CandidatesTable from 'components/CandidatesTable/CandidatesTable';
import { useSnackbar } from 'notistack';
import JobNav from 'components/JobNav';
import SkillsModal from 'components/SkillsModal';

/* Assets */
import Question from 'assets/images/Question.svg';
import Plane from 'assets/images/Plane';

/* Analytics */
import TimeMe from 'timeme.js';
import { trackEvent } from 'lib/analytics';

import { categorizeMatches } from './CandidatesTable/helper';

const JobSaved = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  let { id } = useParams();
  const { jobs, min_years_experience_filter, min_education_level_filter } = useSelector((state) => state.jobs);
  const jobIndex = useSelector((state) => state.jobs.jobIndex);
  const { selected_candidates, submittingCandidates } = useSelector((state) => state.candidates);
  const job = jobIndex.hasOwnProperty(id) ? jobs[jobIndex[id]] : {};
  const jobDoesExist = Object.keys(job).length > 0;

  const [candidatesSubmitted, setCandidatesSubmitted] = useState(false); //Used to make the 'row disappear' effect
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { enqueueSnackbar } = useSnackbar();

  const activeSkills = Object.keys(job.categories)
    .filter((k) => job.categories[k])
    .join(',');
  const skillsFilter = activeSkills.length ? { skills: activeSkills } : {};
  const minEducationFilter = min_education_level_filter ? { min_education_level: true } : {};
  const experienceFilter = min_years_experience_filter ? { min_years_experience: true } : {};
  const matchesQueryKey = [
    `/users/me/jobs/${id}/matches`,
    { ...skillsFilter, ...minEducationFilter, ...experienceFilter },
  ];
  const matchesQuery = useQuery({ queryKey: matchesQueryKey, queryFn: queryWithParams });
  const allCandidates = pathOr([], ['data', 'matches'], matchesQuery);

  useEffect(() => {
    if (!jobDoesExist) {
      history.push(`${urlPrefix}/jobs`);
    }
  }, [jobDoesExist, history, urlPrefix]);

  useEffect(() => {
    if (matchesQuery.isSuccess && !matchesQuery.isFetching) {
      dispatch(
        setJobMatches({
          job_id: job.id,
          matches: pathOr([], ['data', 'matches'], matchesQuery),
        })
      );
    }
  }, [matchesQuery.isSuccess, matchesQuery.isFetching]);

  const [matchingCandidates, submittedCandidates, savedCandidates, archivedCandidates] =
    categorizeMatches(allCandidates);

  const submitCandidatesMutation = useMutation({
    mutationFn: () => {
      dispatch(setSubmittingCandidates(true));
      const contactPromises = selected_candidates.map((candidateId) => contact(id, candidateId));
      return Promise.all(contactPromises);
    },
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: matchesQueryKey });

      // Return a context object with a snapshot of the previous value
      return { previousMatches: queryClient.getQueryData(matchesQueryKey) };
    },
    onError: () => {
      enqueueSnackbar(intl.formatMessage({ id: 'jobs.matches.submitCandidatesError' }), { variant: 'error' });
      dispatch(setSubmittingCandidates(false));
    },
    onSuccess: (_, __, context) => {
      setCandidatesSubmitted(true);
      setTimeout(function () {
        dispatch(setSelectedCandidates([]));
        const newMatches = pathOr([], ['previousMatches', 'matches'], context).map((match) =>
          selected_candidates.includes(match.id) ? { ...match, status: 'CONTACTED' } : match
        );
        dispatch(
          setJobMatches({
            job_id: id,
            matches: newMatches,
          })
        );
        queryClient.setQueryData(matchesQueryKey, (old) => {
          return { ...old, matches: newMatches };
        });
        setCandidatesSubmitted(false);
      }, 400);
      dispatch(setSubmittingCandidates(false));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: matchesQueryKey });
    },
  });

  const handleOpenPayRangeTooltip = () => {
    const eventName = 'JOBS_SAVED_HR_PAY_RANGE_Q';
    trackEvent(eventName);
  };

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS_SAVED');
    TimeMe.startTimer();
    return () => {
      dispatch(setSelectedCandidates([]));
    };
  }, [dispatch]);

  return (
    <div className={styles.jobsMatchesWrapper}>
      <div className={dashboardStyles.content}>
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="flex-start">
            <Link
              to={`${urlPrefix}/jobs`}
              gutterBottom
              underline="always"
              className={cn(layoutStyles.link, layoutStyles.headingSize, layoutStyles.sourceSerif)}
            >
              {intl.formatMessage({ id: 'jobs.jobs' })}
            </Link>
            <Typography variant="h1">&nbsp; &gt; {job.title}</Typography>
          </Box>
          <Box mb={3} className={styles.matchesHeaderContent}>
            <JobNav
              active="saved"
              matchesLength={matchingCandidates.length}
              submittedLength={submittedCandidates.length}
              savedLength={savedCandidates.length}
              archivedLength={archivedCandidates.length}
              jobId={id}
            />
            <Box display="flex" alignItems="flex-start">
              <Box display="flex" flexDirection="column">
                <span className={styles.hourlyRateTitle}>
                  {intl.formatMessage({ id: 'jobs.matches.hourlyRateTitle' }, { job: 'machinists' })}
                </span>
                <span className={styles.hourlyRateText}>
                  {intl.formatMessage(
                    { id: 'jobs.matches.hourlyRateText' },
                    { low: '15.97', median: '21.90', high: '32.63' }
                  )}
                </span>
              </Box>
              <Tooltip
                title={intl.formatMessage({ id: 'jobs.matches.tooltipTop' })}
                arrow
                placement="top-end"
                classes={{ tooltip: candidatesStyles.tooltip, arrow: candidatesStyles.tooltipArrow }}
                onOpen={handleOpenPayRangeTooltip}
                enterTouchDelay={0}
                leaveTouchDelay={3500}
              >
                <img src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
              </Tooltip>
            </Box>
          </Box>
          <hr className={layoutStyles.separator} />
          <Box m={3} />
          <div className={dashboardStyles.content}>
            <div className={styles.header}>
              <div className={styles.header__text}>
                <h2 className={styles.title}>{intl.formatMessage({ id: 'jobs.saved.title' })}</h2>
                <span className={styles.subtitle}>
                  {intl.formatMessage({ id: 'jobs.matches.subtitle' })}
                  <br />
                  {intl.formatMessage({ id: 'jobs.submitted.subtitle2' })}&nbsp;
                  <Tooltip
                    title={intl.formatMessage({ id: 'jobs.submitted.whyDelay' })}
                    arrow
                    placement="top-end"
                    classes={{ tooltip: candidatesStyles.tooltip, arrow: candidatesStyles.tooltipArrow }}
                  >
                    <img src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
                  </Tooltip>
                </span>
              </div>
              <Box display="flex" justifyContent="flex-end">
                {false ? (
                  <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
                    {intl.formatMessage({ id: 'jobs.matches.addRemove' })}
                  </Button>
                ) : null}
              </Box>
            </div>
          </div>
          <Box m={5} />
          <CandidatesTable
            job={job}
            tabs={false}
            savedCandidates={savedCandidates}
            savedPage={true}
            candidatesSubmitted={candidatesSubmitted}
            submittingCandidates={submittingCandidates}
            fetching={matchesQuery.isFetching && !matchesQuery.isFetched}
          />
        </Container>
      </div>
      {!job.archived ? (
        <div className={styles.submitCandidatesBar}>
          <Container maxWidth="md" className={styles.submitCandidatesBarContainer}>
            {selected_candidates.length === 0 ? (
              <span className={styles.submitCandidatesText}>
                {intl.formatMessage({ id: 'jobs.matches.selectCandidates' })}
              </span>
            ) : (
              <span className={styles.submitCandidatesText}>
                <b>
                  {selected_candidates.length === 1
                    ? `1 ${intl.formatMessage({ id: 'jobs.matches.candidate' })} `
                    : `${selected_candidates.length} ${intl.formatMessage({ id: 'jobs.matches.candidates' })} `}
                </b>{' '}
                {intl.formatMessage({ id: 'jobs.matches.toBeReviewed' })}
              </span>
            )}
            <Button
              variant="contained"
              onClick={selected_candidates.length > 0 && !submittingCandidates ? submitCandidatesMutation.mutate : null}
              color={selected_candidates.length > 0 && !submittingCandidates ? 'primary' : 'default'}
            >
              <Plane
                className={cn({
                  [styles.plane]: true,
                  [styles.planePrimary]: selected_candidates.length > 0 && !submittingCandidates,
                })}
              />
              {intl.formatMessage({ id: 'jobs.matches.submitCandidates' })}
            </Button>
          </Container>
        </div>
      ) : null}
      <SkillsModal
        open={modalOpen}
        jobId={job.job_id}
        skills={job.available_skills ? job.available_skills : []}
        selectedSkills={job.selected_skills ? job.selected_skills : []}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

export default JobSaved;
