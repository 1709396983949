import React from 'react'
import NumberFormat from 'react-number-format'

export default function PhoneNumberInput(props) {

  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      displayType={"input"}
      type={"tel"}
      format="(###) ###-####"
      placeholder="(###) ###-####"
      mask="_"
      onValueChange={(values) => {
          onChange({
              target: {
                  name: props.name,
                  value: values.value,
              },
          });
      }}
    />
  );
}