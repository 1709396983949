import { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* API */
import { contact, archive, update } from 'lib/api/matchMethods';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import { Button, Box, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Loading from 'components/Loading';

/* Styles */
import cn from 'classnames';
import styles from 'styles/candidates.module.scss';

/* Assets */
import Plane from 'assets/images/Plane';

const STATUS_OPTIONS = {
  ACTIVE: 'ACTIVE', // ACTIVE = the candidate has neither been contacted nor archived (default)
  ARCHIVED: 'ARCHIVED', // ARCHIVED = the candidate has been removed from recommendations
  CONTACTED: 'CONTACTED', // CONTACTED = the employer has attempted to send an SMS or email to this candidate
  SAVED: 'SAVED', // SAVED = the employer has decided to save the candidate, but not contact the candidate
};

const UpdateMatchStatus = ({ jobId, matchId, currentMatch }) => {
  const intl = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { urlPrefix } = useSelector((state) => state.app);

  const [updatingStatus, setUpdatingStatus] = useState(false);

  const updateStatusError = (err) => {
    setUpdatingStatus(false);
    enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
  };

  const handleContactMatch = () => {
    setUpdatingStatus(true);
    contact(jobId, matchId)
      .then(() => {
        setUpdatingStatus(false);
        history.push(`${urlPrefix}/jobs/${jobId}/matches`);
      })
      .catch(updateStatusError);
  };

  const handleRemoveCandidate = () => {
    setUpdatingStatus(true);
    archive(jobId, matchId)
      .then(() => {
        setUpdatingStatus(false);
        history.push(`${urlPrefix}/jobs/${jobId}/removed`);
      })
      .catch(updateStatusError);
  };

  const handleSaveCandidate = () => {
    setUpdatingStatus(true);
    update(jobId, matchId, { status: 'SAVED' })
      .then(() => {
        setUpdatingStatus(false);
        history.push(`${urlPrefix}/jobs/${jobId}/saved`);
      })
      .catch(updateStatusError);
  };

  return (
    <div className={styles.submitCandidatesBar}>
      <Container maxWidth="md" className={styles.submitCandidatesBarContainer}>
        <Box display="flex" alignItems="center">
          {currentMatch.status !== STATUS_OPTIONS.SAVED && currentMatch.status !== STATUS_OPTIONS.REMOVED ? (
            <span
              className={cn({
                [styles.submitBarLink]: true,
                [styles.submitBarLink__Disabled]: updatingStatus,
              })}
              onClick={!updatingStatus ? handleRemoveCandidate : null}
            >
              {intl.formatMessage({ id: 'candidates.details.remove' })}
            </span>
          ) : null}
          {currentMatch.status !== STATUS_OPTIONS.SAVED && currentMatch.status !== STATUS_OPTIONS.REMOVED ? (
            <span
              className={cn({
                [styles.submitBarLink]: true,
                [styles.submitBarLink__Disabled]: updatingStatus,
              })}
              onClick={!updatingStatus ? handleSaveCandidate : null}
            >
              {intl.formatMessage({ id: 'candidates.details.save' })}
            </span>
          ) : null}
        </Box>
        <Button
          variant="contained"
          onClick={handleContactMatch}
          disabled={currentMatch.status === STATUS_OPTIONS.CONTACTED || updatingStatus}
          color={'primary'}
        >
          {updatingStatus ? (
            <Loading.Inline />
          ) : (
            <Plane
              className={cn({
                [styles.plane]: true,
                [styles.planePrimary]: currentMatch.status !== STATUS_OPTIONS.CONTACTED && !updatingStatus,
              })}
            />
          )}
          {intl.formatMessage({ id: 'candidates.details.submitCandidate' })}
        </Button>
      </Container>
    </div>
  );
};

export default UpdateMatchStatus;
