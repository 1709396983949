import { useState } from 'react';
import { useFormikContext } from 'formik';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { IndustrySelect } from 'components/inputs';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Styles */
import cn from 'classnames';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';


const IndustrySection = () => {

  const intl = useIntl();
  const formikContext = useFormikContext();

  const primaryIndustryValue = formikContext.values.primary_industry_code;
  const secondaryIndustryValue = formikContext.values.secondary_industry_code;

  const [showSecondaryIndustry, setShowSecondaryIndustry] = useState(
    Boolean(secondaryIndustryValue) || formikContext.touched.secondary_industry_code
  );

  const handleSecondaryIndustryLink = () => {
    trackEvent('ONBOARDING_SIGNUP_SECONDARYINDUSTRY');
    setShowSecondaryIndustry(true);
  }

  return (<>
    <Box display="flex" justifyContent="flex-start" mt={2} mb={2}>
      <Typography variant="h2" component="span" gutterBottom>
        {intl.formatMessage({ id: 'forms.onboarding.sections.industry' })}
      </Typography>
    </Box>
    <IndustrySelect
      formikId={"primary_industry_code"}
      formikQueryId={"primary_industry_search_query"}
      formattedLabelText={intl.formatMessage({ id: 'forms.onboarding.industryLabel' })}
      formik={formikContext}
    />
    {
      Boolean(primaryIndustryValue) && !showSecondaryIndustry ? (
        <span className={cn(layoutStyles.link, onboardingStyles.industryLink)} onClick={handleSecondaryIndustryLink}>
          {intl.formatMessage({ id: 'forms.onboarding.selectSecondIndustry' })}
        </span>
      ) : null
    }
    {
      showSecondaryIndustry ? (
        <>
          <Box display="flex" justifyContent="flex-start" mt={2} mb={2}>
            <Typography variant="h2" component="span" gutterBottom>
              {intl.formatMessage({ id: 'forms.onboarding.industry2' })}
            </Typography>
          </Box>
          <IndustrySelect
            formikId={"secondary_industry_code"}
            formikQueryId={"secondary_industry_search_query"}
            formattedLabelText={intl.formatMessage({ id: 'forms.onboarding.industry2Label' })}
            formik={formikContext}
          />
        </>
      ) : null
    }
  </>)
}

export default IndustrySection;