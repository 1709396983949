/* Styles */
import styles from 'styles/toggle-switch.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

const ToggleSwitch = ({ checked, onClick, label, disabled = false }) => {

  const intl = useIntl();

  return (
    <label className={styles.switch}>
      <input
        className={styles.toggleInput}
        type="checkbox"
        defaultChecked={checked}
        onClick={onClick}
        aria-label={`${intl.formatMessage({ id: 'jobs.ariaToggleSkill' }, { skill: label })}`}
        disabled={disabled}
      />
      <span className={styles.slider}></span>
    </label>
  )
}

export default ToggleSwitch;