import { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { pathOr } from 'ramda';
import { useQuery } from '@tanstack/react-query';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/candidates.module.scss';
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import candidatesStyles from 'styles/candidates.module.scss';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setJobMatches } from 'app/slices/jobsSlice';

/* Material UI and other UI Dependencies */
import { Button, Box, Tooltip, Typography, Container } from '@material-ui/core';
import CandidatesTable from 'components/CandidatesTable/CandidatesTable';
import JobNav from 'components/JobNav';
import SkillsModal from 'components/SkillsModal';

/* Assets */
import Question from 'assets/images/Question.svg';

/* API */
import { queryWithParams } from 'app/queries';

/* Analytics */
import TimeMe from 'timeme.js';
import { trackEvent } from 'lib/analytics';

import { categorizeMatches } from './CandidatesTable/helper';

const JobSubmitted = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const { jobs, min_years_experience_filter, min_education_level_filter } = useSelector((state) => state.jobs);
  const jobIndex = useSelector((state) => state.jobs.jobIndex);
  const job = jobIndex.hasOwnProperty(id) ? jobs[jobIndex[id]] : {};
  const jobDoesExist = Object.keys(job).length > 0;
  const [modalOpen, setModalOpen] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const handleOpenDelayTooltip = () => {
    const eventName = 'JOBS_SUBMITTED_HEAR_BACK_Q';
    trackEvent(eventName);
  };

  const handleOpenPayRangeTooltip = () => {
    const eventName = 'JOBS_SUBMITTED_HR_PAY_RANGE_Q';
    trackEvent(eventName);
  };

  const handleSkillsButtonClick = () => {
    const eventName = 'JOBS_SUBMITTED_ADD_REMOVE_SKILLS';
    trackEvent(eventName);
    setModalOpen(true);
  };

  const activeSkills = Object.keys(job.categories)
    .filter((k) => job.categories[k])
    .join(',');
  const skillsFilter = activeSkills.length ? { skills: activeSkills } : {};
  const minEducationFilter = min_education_level_filter ? { min_education_level: true } : {};
  const experienceFilter = min_years_experience_filter ? { min_years_experience: true } : {};
  const matchesQueryKey = [
    `/users/me/jobs/${id}/matches`,
    { ...skillsFilter, ...minEducationFilter, ...experienceFilter },
  ];
  const matchesQuery = useQuery({
    queryKey: matchesQueryKey,
    queryFn: queryWithParams,
  });
  const allCandidates = pathOr([], ['data', 'matches'], matchesQuery);
  //TODO: add active to the query to only fetch if not coming from match details
  useEffect(() => {
    if (!jobDoesExist) {
      history.push(`${urlPrefix}/jobs`);
    }
  }, [jobDoesExist, history, urlPrefix]);

  useEffect(() => {
    if (matchesQuery.isSuccess && !matchesQuery.isFetching) {
      dispatch(
        setJobMatches({
          job_id: job.id,
          matches: pathOr([], ['data', 'matches'], matchesQuery),
        })
      );
    }
  }, [matchesQuery.isSuccess, matchesQuery.isFetching]);

  const [matchingCandidates, submittedCandidates, savedCandidates, archivedCandidates] =
    categorizeMatches(allCandidates);

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS_SUBMITTED');
    TimeMe.startTimer();
    return () => {
      // Leaving Jobs Submitted
    };
  }, []);

  return (
    <div className={styles.jobsMatchesWrapper}>
      <div className={dashboardStyles.content}>
        <Container maxWidth="md">
          <Box mb={1} mt={4} display="flex" justifyContent="flex-start">
            <Link
              to={`${urlPrefix}/jobs`}
              gutterBottom
              underline="always"
              className={cn(layoutStyles.link, layoutStyles.headingSize, layoutStyles.sourceSerif)}
            >
              {intl.formatMessage({ id: 'jobs.jobs' })}
            </Link>
            <Typography variant="h1">&nbsp; &gt; {job.title}</Typography>
          </Box>
          <Box mb={3} className={styles.matchesHeaderContent}>
            <JobNav
              active="submitted"
              matchesLength={matchingCandidates.length}
              submittedLength={submittedCandidates.length}
              savedLength={savedCandidates.length}
              archivedLength={archivedCandidates.length}
              jobId={id}
            />
            <Box display="flex" alignItems="flex-start">
              <Box display="flex" flexDirection="column">
                <span className={styles.hourlyRateTitle}>
                  {intl.formatMessage({ id: 'jobs.matches.hourlyRateTitle' }, { job: 'machinists' })}
                </span>
                <span className={styles.hourlyRateText}>
                  {intl.formatMessage(
                    { id: 'jobs.matches.hourlyRateText' },
                    { low: '15.97', median: '21.90', high: '32.63' }
                  )}
                </span>
              </Box>
              <Tooltip
                title={intl.formatMessage({ id: 'jobs.matches.tooltipTop' })}
                arrow
                placement="top-end"
                enterTouchDelay={0}
                leaveTouchDelay={3500}
                onOpen={handleOpenPayRangeTooltip}
                classes={{ tooltip: candidatesStyles.tooltip, arrow: candidatesStyles.tooltipArrow }}
              >
                <img src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
              </Tooltip>
            </Box>
          </Box>
          <hr className={layoutStyles.separator} />
          <Box m={3} />
          <div className={dashboardStyles.content}>
            <div className={styles.header}>
              <div className={styles.header__text}>
                <h2 className={styles.title}>{intl.formatMessage({ id: 'jobs.submitted.title' })}</h2>
                <span className={styles.subtitle}>
                  {intl.formatMessage({ id: 'jobs.matches.subtitle' })}
                  <br />
                  {intl.formatMessage({ id: 'jobs.submitted.subtitle2' })}&nbsp;
                  <Tooltip
                    title={intl.formatMessage({ id: 'jobs.submitted.whyDelay' })}
                    arrow
                    placement="top-end"
                    enterTouchDelay={0}
                    leaveTouchDelay={3500}
                    onOpen={handleOpenDelayTooltip}
                    classes={{ tooltip: candidatesStyles.tooltip, arrow: candidatesStyles.tooltipArrow }}
                  >
                    <img src={Question} alt={intl.formatMessage({ id: 'layout.alt.questionmark' })} />
                  </Tooltip>
                </span>
              </div>
              <Box display="flex" justifyContent="flex-end">
                {false ? (
                  <Button variant="contained" color="primary" onClick={handleSkillsButtonClick}>
                    {intl.formatMessage({ id: 'jobs.matches.addRemove' })}
                  </Button>
                ) : null}
              </Box>
            </div>
          </div>
          <Box m={5} />
          <CandidatesTable
            job={job}
            tabs={false}
            submittedCandidates={submittedCandidates}
            submittedPage
            fetching={matchesQuery.isFetching && !matchesQuery.isFetched}
          />
        </Container>
      </div>
      <SkillsModal
        open={modalOpen}
        jobId={job.job_id}
        skills={job.skills ? job.skills : []}
        selectedSkills={job.selected_skills ? job.selected_skills : []}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

export default JobSubmitted;
