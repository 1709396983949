import { useIntl } from 'react-intl';
import { useField, useFormikContext } from 'formik';

/* Material UI and other UI Dependencies */
import { Button, Box, CircularProgress } from '@material-ui/core';
import { InputTextField } from 'components/inputs';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* API */
import { apiHooks } from 'lib/api/index';

const JobDescriptionTextInput = ({
  resultHandler = () => {},
  errorHandler = () => {},
  loading,
  loadingSetter = () => {},
  showButton,
}) => {
  const intl = useIntl();
  const { values: formValues } = useFormikContext();
  const parseDescription = apiHooks.upload.useParseString({
    onSuccess: resultHandler,
    onError: errorHandler,
  });
  const [_, fieldMetaProps] = useField('description');
  const hasTitle = formValues.title && formValues.title.length > 0;

  const handleManualParseClick = () => {
    if (!fieldMetaProps.error) {
      loadingSetter(true);
      parseDescription(formValues);
    }
  };

  if (loading) {
    return (
      <Box paddingY={'2em'} marginBottom={'.5em'} display={'flex'} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <InputTextField
        formikId={'description'}
        inputProps={{
          'aria-label': intl.formatMessage({
            id: 'forms.onboarding.jobDescription',
          }),
        }}
        muiProps={{
          variant: 'outlined',
          placeholder: intl.formatMessage({
            id: 'forms.onboarding.jobDescriptionPlaceholder',
          }),
          multiline: true,
          value: fieldMetaProps.value,
          minRows: 4,
          maxRows: 12,
          disabled: !hasTitle,
        }}
      />
      {showButton ? (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button color="primary" variant="contained" onClick={handleManualParseClick}>
            SUBMIT
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default JobDescriptionTextInput;
