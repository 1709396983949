import { createSlice } from '@reduxjs/toolkit';

export const candidateSlice = createSlice({
	name: 'candidates',
	initialState: {
		selected_candidates: [],
		submittingCandidates: false
	},
	reducers: {
		setSelectedCandidates: (state, action) => {
			state.selected_candidates = action.payload;
		},
		setSubmittingCandidates: (state, action) => {
			state.submittingCandidates = action.payload;
		},
	}
});

export const { setSelectedCandidates, setSubmittingCandidates } = candidateSlice.actions;

export default candidateSlice.reducer;
