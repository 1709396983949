import axios from 'axios';

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = async (method, path, params = {}, publicUrl = false) => {
  const config = {
    method: method,
    url: `${publicUrl ? 'api/vp1' : process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Auth not required for these:
const publicAPIs = [ '/api/v1/profiles' ];

export const configureAuth = (getAccessToken) => {
	return new Promise((resolve, reject) => {
		axios.interceptors.request.use(
			async (req) => {
				if (req.url.startsWith(process.env.REACT_APP_API_ENDPOINT) && !publicAPIs.some(url => url.startsWith(req.url))) {
					const accessToken = await getAccessToken();
					if (accessToken) {
						// use custom header as work around for Safari bug
						// see https://stackoverflow.com/questions/61411520/safari-http-request-header-is-not-reflecting-on-axios-interceptors-change
						req.headers['x-access-token'] = `Bearer ${accessToken}`;
					} else {
						// No token available, so cancel API request
						return {
							...req,
							cancelToken: new axios.CancelToken((cancel) => cancel('cancel unauthorized request'))
						};
					}
				}
				return req;
			},
			(error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					const { status, data } = error.response;
					let errorMessage;

					if (status === 401) {
						// handle unauthorized error
						errorMessage = 'configureAuth: You are not authorized to perform this action';
					} else if (status === 404) {
						// handle not found error
						errorMessage = 'configureAuth: The resource you are trying to access was not found';
					} else if (status >= 500) {
						// handle server error
						errorMessage = 'configureAuth: An error occurred on the server';
					} else {
						errorMessage = data.message;
					}

					console.error(errorMessage);
					reject(new Error(errorMessage));
				} else if (error.request) {
					// The request was made but no response was received
					console.error('configureAuth: No response was received from the server');
					reject(new Error('configureAuth: No response was received from the server'));
				} else {
					// Something happened in setting up the request that triggered an Error
					console.error('configureAuth', error.message);
					reject(error);
				}
			}
		);
	});
};