import { apiRequest } from "./config";

const NAICS_ENDPOINT = '/naics'

export const stringSearch = (queryStr) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${NAICS_ENDPOINT}/`,{
      params : {
    	query: queryStr
      }
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export const getTitle = (id) => {
  return new Promise((resolve, reject) => {
    apiRequest("get", `${NAICS_ENDPOINT}/${id}`)
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}