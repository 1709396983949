export const educationLevelsIntlDict = {
  0: '',
  '11.5': 'education.level.11.5', //'forms.onboarding.education.someHighSchool'
  12: 'education.level.12', //'forms.onboarding.education.diploma'
  13: 'education.level.13', //'forms.onboarding.education.someCollege'
  14: 'education.level.14', //'forms.onboarding.education.assocDegree'
  16: 'education.level.16', //'forms.onboarding.education.bachDegree'
  '16.5': 'education.level.16.5', //'forms.onboarding.education.someGraduate'
  17: 'education.level.17', //'forms.onboarding.education.mastersDegree'
  20: 'education.level.20', //'forms.onboarding.education.advancedDegree'
}

export const educationLevelsIntlArr = [
  { key: '0', value: ''},
  { key: '11.5', value: 'education.level.11.5'}, //'forms.onboarding.education.someHighSchool'
  { key: '12', value: 'education.level.12'}, //'forms.onboarding.education.diploma'
  { key: '13', value: 'education.level.13'}, //'forms.onboarding.education.someCollege'
  { key: '14', value: 'education.level.14'}, //'forms.onboarding.education.assocDegree'
  { key: '16', value: 'education.level.16'}, //'forms.onboarding.education.bachDegree'
  { key: '16.5', value: 'education.level.16.5'}, //'forms.onboarding.education.someGraduate'
  { key: '17', value: 'education.level.17'}, //'forms.onboarding.education.mastersDegree'
  { key: '20', value: 'education.level.20'}, //'forms.onboarding.education.advancedDegree'
];
