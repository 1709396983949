import { Link, useLocation } from "react-router-dom";

/* Styles */
import dashboardStyles from 'styles/dashboard.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Box } from "@material-ui/core";

/* Analytics */
import { trackEvent } from 'lib/analytics';

export default function JobNav({
  jobId,
  matchesLength,
  submittedLength,
  savedLength,
  archivedLength,
  active
}) {

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const items = ['description', 'matches', 'saved', 'submitted', 'removed'];
  const lengthDict = {
    description: '',
    matches: matchesLength,
    submitted: submittedLength,
    saved: savedLength,
    removed: archivedLength
  }

  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  return (
    <Box display="flex" flexWrap="wrap">
      {
        items.map(item => (
          active === item ? (
            <span
              key={item}
              className={dashboardStyles.currentLink}
            >{lengthDict[item]}{item === 'description' ? '' : <>&nbsp;</>}{intl.formatMessage({id: `jobs.${item}`})}</span>
          ) : (
            <Link
              to={`${urlPrefix}/jobs/${jobId}/${item}`}
              onClick={() => {
                trackEvent(`JOB_MENU_${item.toUpperCase()}`);
                const menuItems = ['submitted'];
                if (menuItems.indexOf(activePath) > -1) {
                  trackEvent(`JOB_MENU_${activePath.toUpperCase()}_${item.toUpperCase()}`)
                }
              }}
              underline="always"
              key={item}
              className={cn(layoutStyles.link, layoutStyles.linkSize, dashboardStyles.breadCrumbsLink)}
            >{lengthDict[item]}{item === 'description' ? '' : <>&nbsp;</>}{intl.formatMessage({id: `jobs.${item}`})}</Link>
          )
        ))
      }
    </Box>
  );
}