import { createSlice } from '@reduxjs/toolkit';

const addCategoriesToJob = (job) => {
  const categories = [];
  if (job.selected_skills?.length > 0) {
    job.selected_skills.forEach(skill => {
      categories.push(skill.id || skill)
    })
  }

  return {
    ...job,
    categories: Object.fromEntries(categories.map(catName => [catName, true]))
  };
}

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    summary: {
      jobsCreated: 0,
      candidatesSubmitted: 0,
    },
    jobs: [],
    jobsById: {},
    jobIndex: {},
    matches: {},
    filteredMatches: {
      active: {},
      contacted: {},
      saved: {},
      archived: {}
    },
    min_years_experience_filter: false,
    min_education_level_filter: false
  },
  reducers: {
    setJobSummary: (state, action) => {
      state.summary = action.payload;
    },
    setMinYearsExperienceFilter: (state, action) => {
      state.min_years_experience_filter = action.payload;
    },
    setMinEducationLevelFilter: (state, action) => {
      state.min_education_level_filter = action.payload;
    },
    setJobs: (state, { payload }) => {
      const jobIdDirectory = {};

      const updatedJobs = payload.map((job, i) => {
        jobIdDirectory[job.id] = i;

        return { ...addCategoriesToJob(job) };
      });

      state.jobs = updatedJobs;
      const jobsById = updatedJobs.reduce((newObject, job) => ({ ...newObject, [job.id]: { ...job } }), {})
      state.jobsById = jobsById;
      state.jobIndex = jobIdDirectory;
      state.summary.jobsCreated = payload.length;
    },
    setJobMatches: (state, { payload: { job_id, matches } }) => {
      state.matches[job_id] = matches;
      
      const [matchingCandidates, submittedCandidates, savedCandidates, archivedCandidates] = matches.reduce((acc, curr) => {
        const [actives, contacted, saved, archived] = [...acc];
        if (curr.status === 'ACTIVE') actives.push(curr);
        if (curr.status === 'CONTACTED') contacted.push(curr);
        if (curr.status === 'SAVED') saved.push(curr);
        if (curr.status === 'ARCHIVED') archived.push(curr);
        return [actives, contacted, saved, archived];
      }, [[], [], [], []]);
      state.filteredMatches.active[job_id] = matchingCandidates;
      state.filteredMatches.contacted[job_id] = submittedCandidates;
      state.filteredMatches.saved[job_id] = savedCandidates;
      state.filteredMatches.archived[job_id] = archivedCandidates;
    },
    toggleJobCategory: (state, { payload: { job_id, category_id } }) => {
      const jobIdx = state.jobIndex[job_id];
      state.jobs[jobIdx].categories[category_id] = !state.jobs[jobIdx].categories[category_id];
    },
    updateJob: (state, { payload: { job_id, job } }) => {
      const updatedJob = addCategoriesToJob(job);
      if (state.jobIndex.hasOwnProperty(job_id)) {
        const i = state.jobIndex[job_id];
        state.jobs[i] = { ...state.jobs[i], ...updatedJob };
      } else {
        state.jobs.push(updatedJob);
        state.jobIndex[job_id] = state.jobs.length - 1;
        state.summary.jobsCreated = state.jobs.length + 1;
      }
    },
    updateJobArchived: (state, { payload }) => {
      const jobIndex = state.jobs.reduce((prev, curr, i) => curr.id === payload ? i : prev, -1);
      if (jobIndex > -1) {
        state.jobs[jobIndex].archived = true;
        state.summary = {
          ...state.summary,
          jobs: state.summary.jobsCreated - 1
        }
      }
    },
    updateJobReactivate: (state, { payload }) => {
      const jobIndex = state.jobs.reduce((prev, curr, i) => curr.id === payload ? i : prev, -1);
      if (jobIndex > -1) {
        state.jobs[jobIndex].archived = false;
        state.summary = {
          ...state.summary,
          jobs: state.summary.jobsCreated + 1
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setJobSummary,
  setJobs,
  setJobMatches,
  toggleJobCategory,
  updateJob,
  updateJobArchived,
  updateJobReactivate,
  setMinYearsExperienceFilter,
  setMinEducationLevelFilter

} = jobsSlice.actions;

export default jobsSlice.reducer;